<template>
  <div class="orderListComponent">
    <el-menu
      :default-active="currentMenuIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">{{ $t("kline.openOrders") }}</el-menu-item>
      <el-menu-item index="2">{{ $t("kline.orderHistory") }}</el-menu-item>
    </el-menu>
    <div
      class="noDataText"
      v-if="(!loading && orderListData.length == 0) || !login"
    >
      {{ $t("kline.noDataText") }}
    </div>
    <div v-else>
      <el-collapse class="orderList" v-loading="loading">
        <el-collapse-item
          class="order"
          v-for="(current, index) in orderListData"
          :key="index"
          :name="index"
        >
          <template slot="title">
            <div class="title" :class="current.kind == 'bid' ? 'green' : 'red'">
              {{ $t("kline.orderPrice") }}
              {{ current.price }}
              {{ current.market.split("/")[1] }}
            </div>
            <div class="title last">
              {{ $t("kline.orderVolume") }}

              {{
                Math.floor(parseFloat(current.origin_volume) * 10000) / 10000
              }}
              {{ current.market.split("/")[0] }}
            </div>
          </template>
          <div class="datarow">
            <div class="row">
              {{ $t("kline.marketName") }}
              {{ current.market }}
            </div>
            <div class="row">
              {{ $t("kline.fundsReceived") }}
              {{ current.funds_received }}
              {{ current.market.split("/")[1] }}
            </div>
            <div class="row">
              {{ $t("kline.orderKind") }}
              {{
                current.kind == "bid"
                  ? $t("kline.orderBid")
                  : $t("kline.orderAsk")
              }}
            </div>
            <div class="row">{{ $t("kline.orderId") }}{{ current.id }}</div>
            <div class="row">
              {{ $t("kline.orderTime2") }}
              {{ handleDateTime(current.created_at) }}
            </div>
          </div>
          <div class="operationBar">
            <font-awesome-icon
              icon="fa-solid fa-circle-xmark"
              class="icon"
              v-if="state != 'done'"
              @click="cancelOrderFun(current.id, current.market_id)"
            />
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-pagination
        layout="prev, pager, next"
        :page-count="totalPage"
        @current-change="changePage"
        :pager-count="5"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getCurrentOrderList } from "@/services/Api.js";
import { cancelOrder } from "@/services/Api.js";
import moment from "moment";
import Cookie from "js-cookie";

export default {
  name: "OrderList",
  inject: ["message"],
  data() {
    return {
      login: Cookie.get("sn") != null,
      currentMenuIndex: "1",
      state: "wait",
      orderListData: [],
      loading: false,
      currentPage: 1,
      totalPage: 1,
    };
  },
  watch: {
    "$route.params.marketName"() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleSelect(key) {
      this.currentMenuIndex = key;
      if (key == 1) {
        this.state = "wait";
      } else {
        this.state = "done";
      }
      this.getData();
    },
    getData() {
      this.loading = true;
      var senddata = {
        market: this.$route.params.marketName,
        state: this.state,
        page: this.currentPage,
        count: 10,
      };
      const queryString = Object.keys(senddata)
        .map((key) => key + "=" + senddata[key])
        .join("&");
      getCurrentOrderList(queryString)
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.orderListData = res.data.data.orders;
          this.totalPage = res.data.data.total_pages;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleDateTime(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm:ss");
    },
    changePage(value) {
      this.currentPage = value;
      this.getData();
    },
    cancelOrderFun(id, marketId) {
      this.$confirm(this.$t("kline.confirmCancelOrder"), "", {
        confirmButtonText: this.$t("kline.confirmYes"),
        cancelButtonText: this.$t("kline.confirmNo"),
        customClass: "alert",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          cancelOrder(id, marketId)
            .then((res) => {
              if (res.data.success == true) {
                this.message("kline.cancelOrderSuccess", "success");
              } else {
                this.message("kline.cancelOrderError", "error");
              }
              this.getData();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.orderListComponent {
  margin-top: 15px;
  .noDataText {
    margin-top: 15px;
  }
  .orderList {
    width: 100%;
    margin-top: 10px;
    border-bottom: unset;
    border-top: unset;
    .order {
      overflow: hidden;
      border-radius: 15px;
      margin-top: 5px;
      white-space: nowrap;
      ::v-deep .el-collapse-item__header {
        @include flex-set(unset, space-between);
        padding: 0 10px;
        position: relative;
        @include background_color("background_color4");
        background-color: unset;
        border-bottom: unset;
        .title {
          @include font_color("font_color3");
          font-size: 14px;
          overflow: hidden;
        }
        .red {
          @include font_color("font_color2");
        }
        .green {
          @include font_color("font_color1");
        }
        .last {
          margin-right: 40px;
        }
        .el-collapse-item__arrow {
          @include font_color("font_color3");
          font-size: 16px;
          margin: 0 8px;
          /* position: relative; */
          /* top: 18px; */
          position: absolute;
          right: 10px;
        }
      }
      ::v-deep .el-collapse-item__wrap {
        border-bottom: unset;
      }
      ::v-deep .el-collapse-item__content {
        @include background_color("background_color4");
        @include flex-set(wrap, center, end);
        padding-bottom: 15px;
        @include font_color("font_color3");
        .datarow {
          width: 80%;
          .row {
            text-align: left;
            margin-left: 10px;
            width: 100%;
          }
        }
        .operationBar {
          width: 20%;
          @include flex-set(wrap, end, end);
          .icon {
            margin-right: 10px;
            margin-bottom: 5px;
            font-size: 18px;
          }
        }
      }
    }
  }
  ::v-deep .el-pagination {
    .btn-prev {
      @include font_color("font_color3");
    }
    .btn-next {
      @include font_color("font_color3");
    }
  }
  ::v-deep .el-loading-mask {
    @include background_color("background_color10");
  }
}
</style>
