export default {
  title: "デポジット",
  copySuccess: "コピー成功",
  copyFail: "コピー失敗",
  chargeUSDTIntoThisWallet:
    "入金したいUSDTを以下のアドレスに送信してください,",
  chargeIntoThisWallet1: "以下を送信 ",
  chargeIntoThisWallet2: " 次のアドレスに入金したい,",
  contactUsToHandle:
    "カスタマーサービスにご連絡ください。できるだけ早く対応いたします。",
  coinWalletAddress: "コインウォレットアドレス",
  copyCoinWalletAddress: "アドレスをコピー",
  gettVerifyFailTitle: "メッセージ",
  gettVerifyFail:
    "入金機能は一時的にご利用いただけません。しばらくしてからもう一度お試しください",
  showQRcode: "QRコードを見る",
  charge: "デポジット",
  withdraw: "出金",
  msg1: "この機能は一時的にサポートされていません",
  msg2: "この機能を操作するには、最初に実名認証を完了する必要があります",
  walletTitle: "ウォレット情報",
  currency: "通貨",
  balance: "残高",
  usdtType: "入金するUSDTの種類を選択",
  usdtType2: "出金するUSDTの種類を選択",
  chargeRecord: "入金レコード",
  withdrawRecord: "出金レコード",
  noData: "現在データなし",
  id: "番号",
  amount: "金額",
  fee: "手数料",
  created_at: "提出時間",
  updated_at: "オペレーション時間",
  state: "状態",
  charge_fund_uid: "入金先",
  withdraw_fund_uid: "出金先アドレス",
  cancel_reason: "備考",
  submitting: "受理",
  canceled: "キャンセル",
  cancelled: "キャンセル",
  submitted: "ペンディング",
  rejected: "拒否",
  accepted: "同意",
  checked: "チェック済み",
  warning: "警告",
  suspect: "フォロー",
  processing: "処理",
  faulted: "エラー",
  done: "完了",
  failed: "失敗した",
  refunded: "返金済み",
  error_msg: "ウォレットアドレスの形式が正しいことを確認してください",
  title1: "通貨",
  title2: "残高",
  title3: "以下と等しい",
};
