export default {
  title: "实名认证",
  msg_1: "您还未进行实名认证，点击本条信息前往实名认证页面",
  countryOrArea: "国家或地区",
  addCountryOrAreaCode: "请选择国家或地区",
  cancel: "取消",
  realName: "真实姓名",
  warningRealName: "请输入真实姓名",
  documentType: "证件类型",
  warningDocumentType: "请输入证件类型",
  IDcard: "身份证",
  passport: "护照",
  driversLicense: "驾照",
  certificateNumber: "证件号码",
  warningCertificateNumber: "请输入证件号码",
  front: "证件正面",
  warningFrontImage: "请上传证件正面图片",
  Back: "证件背面",
  warningCoverImage: "请上传证件背面图片",
  handHeld: "手持证件照",
  warningCarryImage: "请上传手持证件图片",
  upload: "上传",
  msg_2: "提示：请确保您的照片里的所有文字清晰可见，否则不能通过实名审核",
  confirm: "确认",
  cancelVerification: "取消认证",
  imageisLt5M: "档案大小不得超过1MB",
  errorOccursWhenUpload: "上传资料时发生问题，请重试",
  uploadSuccess: "上传成功",
  rejectReason: "拒绝理由",
  verifying: "认证中",
  verified: "完成认证",
  mustBeAnImage: "图片格式必须为：JPG, JPEG, PNG",
};
