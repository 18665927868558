export default {
  mtf: {
    p1: "MTF Token是一种人工智能与未来共享技术。基于MTF的虚拟现实社区和沈浸式交互应用生态系统。旨在通过在MTF中构建虚拟现实社区，为沈浸式交互提供一个开放的界面，并与线下实体进行数字孪生，从而实现一户一宇宙的目标。",
    p2: "在MTF社区中，可以构建一个沉浸式互动社区空间，全面映射和再现线下实体的社交社区、娱乐、商业、购物、游戏等属性。通过使用世界上最先进的技术在现实世界中创造虚拟。真实世界的各种场景再现，如城市、街道、地产、商业街等，可视化效果显著提升用户体验，人们可以在这个空间体验不同场景的沉浸式内容。",
    p3: "MTF Token 社区希望创建终极虚拟现实中心，让用户专注于身临其境且引人入胜的环境。因此，这是用户第一次可以在一个地方访问加密信息和沈浸式内容，并访问不同级别的区块链教育资源。附加内容、虚拟业务资源、逼真的游戏体验以及您可以通过虚拟现实体验想像的任何其他内容。 ",
    create: "创建",
    createStr:
      "MTF中的虚拟角色图像，用户可以自定义，例如；将自己购买的虚拟围巾戴在虚拟个人身上，生成个性化搭配的独特角色，而这两款二次创作产品都有NFT防伪证书。",
    interact: "交互",
    interactStr:
      "通过VR和AR进行渲染，让用户看到更细致的真实IP。其中，AR支持虚拟物体依附于真实环境，提供类似电影失控播放器的虚实体验；将虚拟人物投射到现实生活中，完成预设动作并与现实环境中的元素进行交互。",
    share: "分享",
    shareStr:
      "用户可以通过AR将自己购买的虚拟IP投射到现实世界中。用户可以与头像互动，将内容的照片或视频发布到社区，也可以用同样的方式发布自己的二次创作进行分享。",
    trade: "交易",
    tradeStr:
      "合作的虚拟IP持有者，不限于公司或个人，可以在MTF Token上上架和交易商品。未来，MTF Token还将打造更多具有沉浸式体验的SocialFi场景，如虚拟土地、虚拟展览等。入驻企业可以在虚拟土地上销售虚拟商品，企业要想获得销售资格，需要在虚拟土地上租赁土地。",
  },
  vcex: {
    p1: "IONE是一种人工智能与未来共享技术。基于IONE的虚拟现实社区和沈浸式交互应用生态系统。旨在通过在IONE中构建虚拟现实社区，为沈浸式交互提供一个开放的界面，并与线下实体进行数字孪生，从而实现一户一宇宙的目标。",
    p2: "在IONE社区中，可以构建一个沉浸式互动社区空间，全面映射和再现线下实体的社交社区、娱乐、商业、购物、游戏等属性。通过使用世界上最先进的技术在现实世界中创造虚拟。真实世界的各种场景再现，如城市、街道、地产、商业街等，可视化效果显著提升用户体验，人们可以在这个空间体验不同场景的沉浸式内容。",
    p3: "IONE 社区希望创建终极虚拟现实中心，让用户专注于身临其境且引人入胜的环境。因此，这是用户第一次可以在一个地方访问加密信息和沈浸式内容，并访问不同级别的区块链教育资源。附加内容、虚拟业务资源、逼真的游戏体验以及您可以通过虚拟现实体验想像的任何其他内容。 ",
    create: "创建",
    createStr:
      "IONE中的虚拟角色图像，用户可以自定义，例如；将自己购买的虚拟围巾戴在虚拟个人身上，生成个性化搭配的独特角色，而这两款二次创作产品都有NFT防伪证书。",
    interact: "交互",
    interactStr:
      "通过VR和AR进行渲染，让用户看到更细致的真实IP。其中，AR支持虚拟物体依附于真实环境，提供类似电影失控播放器的虚实体验；将虚拟人物投射到现实生活中，完成预设动作并与现实环境中的元素进行交互。",
    share: "分享",
    shareStr:
      "用户可以通过AR将自己购买的虚拟IP投射到现实世界中。用户可以与头像互动，将内容的照片或视频发布到社区，也可以用同样的方式发布自己的二次创作进行分享。",
    trade: "交易",
    tradeStr:
      "合作的虚拟IP持有者，不限于公司或个人，可以在IONE上上架和交易商品。未来，IONE还将打造更多具有沉浸式体验的SocialFi场景，如虚拟土地、虚拟展览等。入驻企业可以在虚拟土地上销售虚拟商品，企业要想获得销售资格，需要在虚拟土地上租赁土地。",
  },
  wdc: {
    p1: "WDCC是一种人工智能与未来共享技术。基于WDCC的虚拟现实社区和沈浸式交互应用生态系统。旨在通过在WDCC中构建虚拟现实社区，为沈浸式交互提供一个开放的界面，并与线下实体进行数字孪生，从而实现一户一宇宙的目标。",
    p2: "在WDCC社区中，可以构建一个沉浸式互动社区空间，全面映射和再现线下实体的社交社区、娱乐、商业、购物、游戏等属性。通过使用世界上最先进的技术在现实世界中创造虚拟。真实世界的各种场景再现，如城市、街道、地产、商业街等，可视化效果显著提升用户体验，人们可以在这个空间体验不同场景的沉浸式内容。",
    p3: "WDCC 社区希望创建终极虚拟现实中心，让用户专注于身临其境且引人入胜的环境。因此，这是用户第一次可以在一个地方访问加密信息和沈浸式内容，并访问不同级别的区块链教育资源。附加内容、虚拟业务资源、逼真的游戏体验以及您可以通过虚拟现实体验想像的任何其他内容。 ",
    create: "创建",
    createStr:
      "WDCC中的虚拟角色图像，用户可以自定义，例如；将自己购买的虚拟围巾戴在虚拟个人身上，生成个性化搭配的独特角色，而这两款二次创作产品都有NFT防伪证书。",
    interact: "交互",
    interactStr:
      "通过VR和AR进行渲染，让用户看到更细致的真实IP。其中，AR支持虚拟物体依附于真实环境，提供类似电影失控播放器的虚实体验；将虚拟人物投射到现实生活中，完成预设动作并与现实环境中的元素进行交互。",
    share: "分享",
    shareStr:
      "用户可以通过AR将自己购买的虚拟IP投射到现实世界中。用户可以与头像互动，将内容的照片或视频发布到社区，也可以用同样的方式发布自己的二次创作进行分享。",
    trade: "交易",
    tradeStr:
      "合作的虚拟IP持有者，不限于公司或个人，可以在WDCC上上架和交易商品。未来，WDCC还将打造更多具有沉浸式体验的SocialFi场景，如虚拟土地、虚拟展览等。入驻企业可以在虚拟土地上销售虚拟商品，企业要想获得销售资格，需要在虚拟土地上租赁土地。",
  },
};
