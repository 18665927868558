<template>
  <div class="WalletView">
    <div class="title_box">
      <h1 class="title">{{ $t("charge.walletTitle") }}</h1>
      <div class="table_title">
        <!-- <div class="left">{{ $t("charge.currency") }}</div>
        <div class="right">{{ $t("charge.balance") }}</div> -->
      </div>
    </div>

    <div class="info_box" v-loading="loading" :key="info_box_key">
      <div
        class="wallet"
        v-for="(item, index) in walletData"
        :key="index"
        @click="clickCoinFun(item.code)"
      >
        <div class="box_left">
          <div class="label">
            <div class="left">{{ $t("charge.title1") }}</div>
            <div class="right">
              <div class="title">
                {{ item.code }}
              </div>
              <div
                class="icon"
                :style="{
                  backgroundImage: `url(${item.icon_url || coinIcon})`,
                }"
              ></div>
            </div>
          </div>
          <div class="label">
            <div class="left">{{ $t("charge.title2") }}</div>
            <div class="right">
              <div class="value">{{ roundDown(item.balance) }}</div>
              <div class="text">{{ item.code }}</div>
            </div>
          </div>

          <div class="label">
            <div class="left">{{ $t("charge.title3") }}</div>
            <!-- <div class="left">≒</div> -->
            <div class="right">
              <div class="value">{{ roundDown(item.tousdt) }}</div>
              <div class="text">USDT</div>
              <!-- <font-awesome-icon class="icon" icon="fa-solid fa-angle-right" /> -->
            </div>
          </div>
        </div>
        <!-- <div class="box_right">
          <font-awesome-icon class="icon" icon="fa-solid fa-angle-right" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getMarkets } from "@/services/Api.js";
// import Cookie from "js-cookie";
// import moment from "moment";

export default {
  inject: ["message"],
  name: "WalletView",

  watch: {
    activeName(value) {
      console.log(value);
    },
    "$store.getters.getApiAccountsData"(value) {
      this.walletData = value;
      this.getMarketsData();
      this.loading = false;
    },
  },

  data() {
    return {
      loading: false,
      walletData: {},
      coinIcon: require("@/assets/images/coin.png"),
      info_box_key: Date.now(),
    };
  },

  mounted() {
    this.loading = true;
    if (this.$store.getters.getApiAccountsData != null) {
      this.walletData = this.$store.getters.getApiAccountsData;
      this.getMarketsData();
      this.loading = false;
    }
    // this.getAccountsFun();
  },

  methods: {
    // getAccountsFun() {
    //   this.loading = true;
    //   getAccounts()
    //     .then((res) => {
    //       console.log(res);
    //       if (res.data.success) {
    //         this.loading = false;
    //         this.walletData = res.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       this.loading = false;
    //       console.log(err);

    //       //登入驗證未通過
    //       if (
    //         err.response.status == 401
    //         // &&
    //         // err.response.statusText == "Unauthorized"
    //       ) {
    //         this.message("common.unauthorizedMsg", "error");
    //         Cookie.remove("sn");
    //         Cookie.remove("_Toastio_session");
    //         this.$router.push("/").catch(() => {});
    //         this.reload();
    //       }
    //     });
    // },
    clickCoinFun(code) {
      console.log(code);
      this.$router.push(`wallet/${code}`);
    },
    getMarketsData() {
      getMarkets()
        .then((res) => {
          // console.log(res);
          const coinList = res.data;

          for (let i = 0; i < this.walletData.length; i++) {
            // console.log(this.walletData[i]["code"]);

            for (let j = 0; j < coinList.length; j++) {
              // console.log(this.coinList[j]["name"].split("/")[0]);
              if (
                this.walletData[i]["code"] == coinList[j]["name"].split("/")[0]
              ) {
                this.walletData[i]["tousdt"] = parseFloat(
                  coinList[j]["ticker"]["last"] * this.walletData[i]["balance"]
                ).toFixed(2);
              } else if (this.walletData[i]["code"] == "USDT") {
                this.walletData[i]["tousdt"] = parseFloat(
                  this.walletData[i]["balance"]
                ).toFixed(2);
              }
            }
          }
          // console.log(this.walletData);
          this.info_box_key = Date.now();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    roundDown(num, decimal = 4) {
      if (num == undefined) {
        return "-";
      }
      num = parseFloat(num);
      return (
        Math.floor((num + Number.EPSILON) * Math.pow(10, decimal)) /
        Math.pow(10, decimal)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.WalletView {
  height: 100%;
  position: relative;
  @include font_color("font_color3");

  .title_box {
    @include titleBox;

    .title {
      @include titleBox_title;
    }
    .table_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 1rem;
      border-bottom: 1px solid;
      @include border_color("border_color1");
      margin-bottom: 10px;
      .left {
        margin-left: 40px;
      }
      .right {
        margin-right: 40px;
      }
    }
  }

  .info_box {
    @include info_box;
    min-height: calc(100vh - 200px);
    font-weight: normal;
    // min-height: calc(100vh - 150px);
    margin-top: 50px;
    height: calc(100vh - 130px);
    overflow: scroll;
    ::v-deep .el-loading-mask {
      @include background_color("background_color10");
    }
    .wallet {
      // height: 40px;
      line-height: 40px;
      border-radius: 12px;
      @include background_color("background_color4");
      margin: 10px 0;
      padding: 5px 10px 5px 20px;
      @include flex-set();
      justify-content: space-between;
      &:hover {
        cursor: pointer;
        // @include font_color("font_color5");
        @include background_color("background_color6");
      }
      .box_left {
        // width: calc(100% - 20px);
        width: 100%;
      }
      .box_right {
        width: 20px;
        text-align: right;
      }
      .label {
        @include flex-set();
        justify-content: space-between;
        height: 30px;
        line-height: 30px;
        .left {
          // @include flex-set();
          // justify-content: left;
          text-align: left;
          width: 100px;
          overflow: hidden;
          @include font_color("font_color10");
          font-size: 14px;
        }
        .right {
          @include flex-set();
          justify-content: left;
          // width: calc(100% - 60px);
          max-width: calc(100% - 80px);
          // overflow: hidden;
          // .icon {
          //   width: 20px;
          //   height: 10px;
          //   margin: 0 10px;
          // }
          .icon {
            background-position: center;
            background-size: contain;
            width: 20px;
            height: 20px;
            margin-left: 10px;
          }
          .text {
            text-align: left;
            width: 50px;
            margin-left: 10px;
            @include font_color("font_color10");
          }
          .value {
            max-width: calc(100% - 60px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
