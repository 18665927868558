<template>
  <div class="BuyAndSellComponent">
    <!-- 
      /markets/ETH/USDT/order_bids.json

      order_bid[ord_type]: market
      order_bid[ord_type]: limit
      order_bid[price]: 1
      order_bid[origin_volume]: 2
      order_bid[total]: 2.00 
    -->

    <!-- 
      /markets/ETH/USDT/order_asks.json

      order_ask[ord_type]: market
      order_ask[ord_type]: limit
      order_ask[price]: 3
      order_ask[origin_volume]: 4
      order_ask[total]: 12.00
    -->

    <div class="mask" @click="switchFun()">
      <div class="popout" @click.stop v-loading="loading">
        <div class="title">{{ $t("kline.tradeTitle1") }}</div>
        <div class="label">
          <!-- <div class="left">{{ $t("kline.sendOrder") }}</div> -->
          <div class="left">{{ $t("kline.accountBalance") }}</div>
          <div class="right" v-if="betForm.type == 'order_bids'">
            <!-- {{ $t("kline.accountBalance") }} -->
            <span class="bold">{{ usdtBalance }}</span>
            <span class="coin_name"> {{ coinData.name.split("/")[1] }}</span>
          </div>
          <div class="right" v-else>
            <!-- {{ $t("kline.accountBalance") }} -->
            <span class="bold">{{ thisAccountsData }}</span>
            <span class="coin_name"> {{ coinData.name.split("/")[0] }}</span>
          </div>
        </div>

        <div class="buy_type">
          <div
            class="btn left"
            :class="[{ green: betForm.type == 'order_bids' }]"
            @click="betForm.type = 'order_bids'"
          >
            {{ $t("kline.buy") }}
          </div>
          <div
            class="btn"
            :class="[{ red: betForm.type == 'order_asks' }]"
            @click="betForm.type = 'order_asks'"
          >
            {{ $t("kline.sell") }}
          </div>
        </div>

        <div class="label ord_type">
          <div
            class="type_label border_bottom_none"
            :class="[
              {
                border_bottom: ord_type == 'limit',
                blue_color: ord_type == 'limit',
              },
            ]"
            @click="ord_type = 'limit'"
          >
            {{ $t("kline.limit") }}
          </div>
          <div
            class="type_label border_bottom_none"
            :class="[
              {
                border_bottom: ord_type == 'market',
                blue_color: ord_type == 'market',
              },
            ]"
            @click="
              (ord_type = 'market'),
                (betForm.price = coinData.ticker.last),
                (totalCount = accMul(betForm.price, betForm.origin_volume))
            "
          >
            {{ $t("kline.market") }}
          </div>
        </div>
        <div class="label border_bottom">
          <div class="left">{{ $t("kline.price") }}</div>
          <input
            class="amount"
            v-model="betForm.price"
            :placeholder="$t('kline.pleaseInputPrice2')"
            type="number"
            v-if="ord_type == 'limit'"
            @keyup="priceKeyup(betForm.price)"
          />
          <div class="market" v-else>{{ coinData.ticker.last }}</div>
          <div class="right coin_name">
            {{ coinData.name.split("/")[1] }}
          </div>
        </div>
        <div class="msg"></div>

        <div
          v-if="betForm.type == 'order_bids'"
          class="label border_bottom"
          :class="[
            {
              red_border_bottom:
                amountTooBigMsg && betForm.type == 'order_bids' && !loading,
            },
          ]"
        >
          <div class="left">{{ $t("kline.totalCount") }}</div>
          <!-- <div class="market bold">
            {{ betForm.price * betForm.origin_volume || 0 }}
          </div> -->
          <input
            class="amount"
            v-model="totalCount"
            :placeholder="$t('kline.pleaseInputPrice')"
            type="number"
            @keyup="totalKeyup(totalCount)"
          />
          <div class="right coin_name">
            {{ coinData.name.split("/")[1] }}
          </div>
        </div>
        <div v-if="betForm.type == 'order_bids'" class="msg">
          {{
            amountTooBigMsg && betForm.type == "order_bids" && !loading
              ? $t("kline.insufficientBalance")
              : ""
          }}
        </div>

        <div
          class="label border_bottom"
          :class="[
            {
              red_border_bottom:
                volumeTooBigMsg && betForm.type == 'order_asks' && !loading,
            },
          ]"
        >
          <div class="left">{{ $t("kline.volume") }}</div>
          <input
            class="amount"
            v-model="betForm.origin_volume"
            :placeholder="$t('kline.pleaseInputVolume')"
            type="number"
            @keyup="volumeKeyup(betForm.origin_volume)"
          />
          <div class="right coin_name">
            <!-- <span class="bold">{{ amountList[formData.amount] || 0 }}</span> -->
            {{ coinData.name.split("/")[0] }}
          </div>
        </div>
        <div class="msg">
          {{
            volumeTooBigMsg && betForm.type == "order_asks" && !loading
              ? $t("kline.notEnough")
              : ""
          }}
        </div>

        <!-- <div class="block">
          <el-slider
            v-model="sliderValue"
            :show-tooltip="false"
            :format-tooltip="formatTooltip"
            @input="sliderInput"
            :marks="marks"
            :step="5"
          ></el-slider>
        </div> -->

        <div class="amount_box">
          <div
            class="amount_btn"
            v-for="(item, index) in amountList"
            :key="index"
            @click="addAmount(item)"
          >
            {{ item }}%
          </div>
        </div>

        <div
          v-if="betForm.type == 'order_asks'"
          class="label border_bottom"
          :class="[
            {
              red_border_bottom:
                amountTooBigMsg && betForm.type == 'order_bids' && !loading,
            },
          ]"
        >
          <div class="left">{{ $t("kline.totalCount") }}</div>
          <!-- <div class="market bold">
            {{ betForm.price * betForm.origin_volume || 0 }}
          </div> -->
          <input
            class="amount"
            v-model="totalCount"
            :placeholder="$t('kline.pleaseInputPrice')"
            type="number"
            @keyup="totalKeyup(totalCount)"
          />
          <div class="right coin_name">
            {{ coinData.name.split("/")[1] }}
          </div>
        </div>
        <div v-if="betForm.type == 'order_asks'" class="msg">
          {{
            amountTooBigMsg && betForm.type == "order_bids" && !loading
              ? $t("kline.insufficientBalance")
              : ""
          }}
        </div>

        <div class="button_box">
          <div class="blue button" @click="doubleCheckFun()">
            {{ $t("kline.sendOrder") }}
          </div>
        </div>
        <div class="button_box">
          <div class="pink button" @click="switchFun()">
            {{ $t("kline.cancel") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  spotTrading,
  getCurrentOrderList,
  getAccounts,
} from "@/services/Api.js";
// import { getPredictions } from "@/services/Api.js";
import Cookie from "js-cookie";
import moment from "moment";

export default {
  inject: ["reload", "message"],
  name: "BuyAndSellComponent",
  props: {
    marketId: { String },
    coinData: { Object },
  },
  watch: {
    usdtBalance() {
      this.amountTooBigMsg =
        (this.betForm.price * this.betForm.origin_volume || 0) >
        this.usdtBalance;
    },
    "betForm.price"() {
      this.amountTooBigMsg =
        (this.betForm.price * this.betForm.origin_volume || 0) >
        this.usdtBalance;

      // this.totalCount = this.betForm.price * this.betForm.origin_volume;
    },
    "betForm.origin_volume"() {
      // console.log(this.betForm.origin_volume, this.thisAccountsData);
      this.amountTooBigMsg =
        (this.betForm.price * this.betForm.origin_volume || 0) >
        this.usdtBalance;

      this.volumeTooBigMsg = this.betForm.origin_volume > this.thisAccountsData;
      // console.log(this.volumeTooBigMsg);
    },
    "$store.getters.getApiAccountsData"() {
      const accountsData = this.$store.getters.getApiAccountsData;
      for (let i = 0; i < accountsData.length; i++) {
        if (accountsData[i].code == this.coinData.name.split("/")[0]) {
          this.thisAccountsData = parseFloat(accountsData[i].balance);
        }
      }
    },
    totalCount() {
      // this.amountTooBigMsg =
      //   (this.betForm.price * this.betForm.origin_volume || 0) >
      //   this.usdtBalance;
      this.amountTooBigMsg = this.totalCount > this.usdtBalance;
      // console.log(this.totalCount);
      // if (this.betForm.price == 0 || this.betForm.price == null) {
      //   this.betForm.price = 0;
      //   this.betForm.origin_volume = 0;
      // } else {
      //   this.betForm.origin_volume = this.totalCount / this.betForm.price;
      // }
    },
    coinData() {
      if (this.ord_type == "market") {
        this.betForm.price = this.coinData.ticker.last;

        this.totalCount = this.accMul(
          this.betForm.price,
          this.betForm.origin_volume
        );
      }
    },
  },
  components: {},
  data() {
    return {
      loading: true,
      amountList: [25, 50, 75, 100],
      betForm: {
        type: "order_bids", //order_bids/order_asks
        ord_type: "limit", //limit/market
        price: null,
        origin_volume: null,
        locale: "en",
      },
      ord_type: "limit",
      time: null,
      showDialog: null,
      usdtBalance: 0,
      thisAccountsData: 0,
      amountTooBigMsg: false,
      volumeTooBigMsg: false,
      sliderValue: 0,
      totalCount: null,
      marks: {
        0: "0%",
        25: "25%",
        50: "50%",
        75: "75%",
        100: "100%",
      },
    };
  },
  mounted() {
    // this.getAccountsFun();
    this.clickBtnFun();

    // console.log(
    //   "this.$store.getters.getCurrentSelectOrderData",
    //   this.$store.getters.getCurrentSelectOrderData
    // );

    if (this.$store.getters.getCurrentSelectOrderData.price != 0) {
      this.betForm.price = this.$store.getters.getCurrentSelectOrderData.price;
      this.betForm.origin_volume =
        this.$store.getters.getCurrentSelectOrderData.volume;

      this.amountTooBigMsg =
        (this.betForm.price * this.betForm.origin_volume || 0) >
        this.usdtBalance;

      this.betForm.type =
        this.$store.getters.getCurrentSelectOrderData.type == "bid"
          ? "order_asks"
          : "order_bids";

      this.totalCount = this.accMul(
        this.betForm.price,
        this.betForm.origin_volume
      );
    }

    if (this.$store.getters.getApiAccountsData != null) {
      const accountsData = this.$store.getters.getApiAccountsData;
      for (let i = 0; i < accountsData.length; i++) {
        if (accountsData[i].code == this.coinData.name.split("/")[0]) {
          this.thisAccountsData = parseFloat(accountsData[i].balance);
        }
      }
    }
  },
  methods: {
    clickBtnFun() {
      if (Cookie.get("sn") ? true : false) {
        this.time = new Date();
        // this.betForm = {
        //   type: "order_bids", //order_bids/order_asks
        //   ord_type: "limit", //limit/market
        //   price: 0,
        //   origin_volume: 0,
        // };
        this.loading = true;
        this.getAccountsFun();
      } else {
        this.message("kline.loginBeforeOrder", "warning");
        this.switchFun();

        // console.log("loginBeforeOrder");
      }
    },
    subnitFun() {
      // alert("模擬下單成功");

      if (this.betForm.price == null || this.betForm.price <= 0) {
        this.message(this.$t("kline.priceWarningMsg"), "warning");
        return;
      }

      if (
        this.betForm.origin_volume == null ||
        this.betForm.origin_volume <= 0
      ) {
        this.message(this.$t("kline.volumeWarningMsg"), "warning");
        return;
      }

      if (this.amountTooBigMsg && this.betForm.type == "order_bids") {
        this.message("kline.insufficientBalance", "warning");
        return;
      }

      if (this.volumeTooBigMsg && this.betForm.type == "order_asks") {
        this.message("kline.notEnough", "warning");
        return;
      }

      this.loading = true;

      // let betForm_obj = {};
      // betForm_obj[this.betForm.type] = this.betForm;

      let locale = window.localStorage.getItem("locale");
      locale = locale == "zht" ? "zh-TW" : locale == "zhs" ? "zh-CN" : "en";

      this.betForm.locale = locale;

      spotTrading(this.betForm, this.coinData.name, this.betForm.type)
        .then((res) => {
          const { data } = res;
          console.log(res);
          if (data.success) {
            this.loading = true;
            setTimeout(() => {
              this.getCurrentOrderListFun();
            }, 500);
          } else {
            this.message(data.errors[0].message, "error");
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.switchFun();

          //登入驗證未通過
          if (
            err.response.status == 401
            // &&
            // err.response.statusText == "Unauthorized"
          ) {
            this.message("common.unauthorizedMsg", "error");
            Cookie.remove("sn");
            Cookie.remove("_Toastio_session");
            this.$router.push("/").catch(() => {});
            this.reload();
          } else {
            this.message("kline.temporaryCanNotOrder", "error");
          }
        });
    },
    getCurrentOrderListFun() {
      // console.log("getPredictionsFun");
      var senddata = { pages: 1, count: 1, order_by: "desc" };
      const queryString = Object.keys(senddata)
        .map((key) => key + "=" + senddata[key])
        .join("&");

      getCurrentOrderList(queryString).then((res) => {
        if (res.data.success) {
          const new_data = res.data.data.orders[0];
          const html = `
            <div class="flex">
              <div>${this.$t("kline.orderID")}</div>
              <div>${new_data.id != null ? new_data.id : ""}</div>
            </div>

            <div class="flex">
              <div>${this.$t("kline.tradingPair")}</div>
              <div>${new_data.market}</div>
            </div>

            <div class="flex">
              <div>${this.$t("kline.orderKind2")}</div>
              <div>${
                new_data.kind == "bid"
                  ? this.$t("kline.orderBid")
                  : this.$t("kline.orderAsk")
              }</div>
            </div>

            <div class="flex">
              <div>${this.$t("kline.price2")}</div>
              <div>${new_data.price}</div>
            </div>

            <div class="flex">
              <div>${this.$t("kline.volume")}</div>
              <div>${new_data.origin_volume}</div>
            </div>

            <div class="flex">
              <div>${this.$t("kline.orderTime")}</div>
              <div>${moment(new_data.created_at).format(
                "YYYY-MM-DD HH:mm:ss"
              )}</div>
            </div>
          `;

          this.$alert(html, this.$t("kline.orderSuccess"), {
            confirmButtonText: "OK",
            customClass: "alert",
            dangerouslyUseHTMLString: true,
          });

          this.switchFun();
          this.reload();
        }
      });
    },
    getAccountsFun() {
      getAccounts()
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.loading = false;
            res.data.data.map((item) =>
              // item.code === "USDT"
              item.code === this.coinData.name.split("/")[1]
                ? (this.usdtBalance =
                    Math.floor(parseFloat(item.balance) * 10000) / 10000)
                : null
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;

          //登入驗證未通過
          if (
            err.response.status == 401
            // &&
            // err.response.statusText == "Unauthorized"
          ) {
            this.message("common.unauthorizedMsg", "error");
            Cookie.remove("sn");
            Cookie.remove("_Toastio_session");
            this.$router.push("/").catch(() => {});
            this.reload();
          }
        });
    },
    // checkBalanceFun() {
    //   this.amountTooBigMsg = this.betForm.volume > this.usdtBalance;
    // },
    addAmount(value) {
      console.log(value);
      if (this.betForm.price == null || this.betForm.price <= 0) {
        // this.message(this.$t("kline.pleaseInputPrice2"), "warning");
        return;
      } else {
        if (this.betForm.type == "order_bids") {
          this.betForm.origin_volume = Math.floor(
            ((this.usdtBalance / 100) * value) / this.betForm.price
          );
        } else {
          this.betForm.origin_volume =
            value == 100
              ? Math.floor(this.thisAccountsData)
              : Math.floor((this.thisAccountsData / 100) * value);
        }

        this.totalCount = this.accMul(
          this.betForm.price,
          this.betForm.origin_volume
        );
      }
    },
    doubleCheckFun() {
      if (this.betForm.price == null || this.betForm.price <= 0) {
        this.message(this.$t("kline.priceWarningMsg"), "warning");
        return;
      }

      if (
        this.betForm.origin_volume == null ||
        this.betForm.origin_volume <= 0
      ) {
        this.message(this.$t("kline.volumeWarningMsg"), "warning");
        return;
      }

      if (this.amountTooBigMsg && this.betForm.type == "order_bids") {
        this.message("kline.insufficientBalance", "warning");
        return;
      }

      if (this.volumeTooBigMsg && this.betForm.type == "order_asks") {
        this.message("kline.notEnough", "warning");
        return;
      }

      this.$confirm("", this.$t("kline.confirmOrder"), {
        confirmButtonText: this.$t("kline.confirmYes"),
        cancelButtonText: this.$t("kline.confirmNo"),
        customClass: "alert",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.subnitFun();
        })
        .catch(() => {});
    },
    switchFun() {
      this.$emit("handleSwitch");
    },
    formatTooltip(val) {
      return val + "%";
    },
    sliderInput(value) {
      // console.log(value);
      if (this.betForm.price == null || this.betForm.price <= 0) {
        this.betForm.origin_volume = 0;
        return;
      } else {
        if (this.betForm.type == "order_bids") {
          this.betForm.origin_volume = Math.floor(
            ((this.usdtBalance / 100) * value) / this.betForm.price
          );
        } else {
          this.betForm.origin_volume =
            value == 100
              ? Math.floor(this.thisAccountsData)
              : Math.floor((this.thisAccountsData / 100) * value);
        }
      }
    },
    priceKeyup(value) {
      console.log(value);
      // if (!this.betForm.price) {
      //   this.betForm.price = 0;
      // }
      // if (!this.betForm.origin_volume) {
      //   this.betForm.origin_volume = 0;
      // }
      if (this.betForm.price && this.betForm.origin_volume) {
        this.totalCount = this.accMul(
          this.betForm.price,
          this.betForm.origin_volume
        );
      }
    },
    volumeKeyup(value) {
      console.log(value);
      // if (!this.betForm.price) {
      //   this.betForm.price = 0;
      // }
      // if (!this.betForm.origin_volume) {
      //   this.betForm.origin_volume = 0;
      // }
      if (this.betForm.price && this.betForm.origin_volume) {
        this.totalCount = this.accMul(
          this.betForm.price,
          this.betForm.origin_volume
        );
      }
    },
    totalKeyup(value) {
      console.log(value);
      // if (!this.betForm.price) {
      //   this.betForm.price = 0;
      // }
      // if (!this.betForm.origin_volume) {
      //   this.betForm.origin_volume = 0;
      // }
      if (this.betForm.price) {
        this.betForm.origin_volume = this.accDiv(
          this.totalCount,
          this.betForm.price
        );
      }
      //  else {
      //   this.betForm.price = 0;
      // }
    },
    //乘法函数，用来得到精确的乘法结果
    accMul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {
        null;
      }
      try {
        m += s2.split(".")[1].length;
      } catch (e) {
        null;
      }
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    //除法函数，用来得到精确的除法结果
    accDiv(arg1, arg2) {
      var m1 = 0,
        m2 = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m1 = s1.split(".")[1].length;
      } catch (e) {
        null;
      }
      try {
        m2 = s2.split(".")[1].length;
      } catch (e) {
        null;
      }
      return (
        (Number(s1.replace(".", "")) /
          Number(s2.replace(".", "")) /
          Math.pow(10, m1)) *
        Math.pow(10, m2)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.BuyAndSellComponent {
  @include flex-set();
  width: 100vw;
  max-width: 660px;
  padding: 10px;
  position: fixed;
  bottom: 0;
  z-index: 2000;
  @include background_color("background_color3");

  .button_box {
    width: 50%;
    padding: 5px;
    margin-bottom: 10px;
  }
  .button {
    height: 38px;
    border-radius: 4px;
    line-height: 38px;
    text-align: center;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
    }
  }
  .green {
    @include background_color("background_color7");
  }
  .red {
    @include background_color("background_color8");
  }
  .yellow {
    @include background_color("background_color12");
  }
  .purple {
    @include background_color("background_color13");
  }
  .blue {
    @include background_color("background_color14");
  }
  .pink {
    @include background_color("background_color15");
  }

  .mask {
    width: 100vw;
    max-width: 660px;
    height: 100vh;
    position: fixed;
    top: 0;
    @include background_color("background_color9");
    .popout {
      @include flex-set();
      width: 100vw;
      max-width: 660px;
      @include background_color("background_color4");
      position: fixed;
      bottom: 0;
      z-index: 110;
      padding: 10px;
      border-top: 1px solid;
      @include border_color("border_color1");
      .title {
        font-size: 20px;
        font-weight: bold;
        @include font_color("font_color3");
        margin: 10px 0;
      }
      .label {
        @include flex-set();
        justify-content: space-between;
        width: 100%;
        margin: 5px 10px;
        padding: 5px;
        @include font_color("font_color10");

        .amount {
          width: calc(100% - 160px);
          border: none;
          background: none;
          @include font_color("font_color3");
          text-align: right;
          font-weight: bold;
          font-size: 16px;
        }
        input {
          outline: none;
        }
        /* Chrome, Safari, Edge, Opera */
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        .bold {
          font-weight: bold;
          @include font_color("font_color3");
        }
        .coin_name {
          width: 50px;
          text-align: right;
        }
        .left {
          width: 80px;
          text-align: left;
        }
        .market {
          width: calc(100% - 160px);
          text-align: right;
          height: 20px;
          line-height: 20px;
          font-weight: bold;
          @include font_color("font_color3");
          margin-right: 4px;
        }
      }
      .ord_type {
        justify-content: left;
        .type_label {
          margin-right: 20px;
          padding: 5px 0;
          cursor: pointer;
        }
      }
      .msg {
        font-size: 12px;
        @include font_color("font_color2");
        text-align: center;
        font-weight: bold;
        text-align: right;
        width: 100%;
        height: 6px;
        margin: 0 10px;
      }
      .buy_type {
        @include flex-set();
        border: 1px solid;
        border-radius: 8px;
        width: 100%;
        margin: 10px;
        overflow: hidden;
        @include border_color("border_color7");
        .btn {
          width: 50%;
          padding: 10px;
          cursor: pointer;
          @include box_shadow("shadow_color1");
          @include text_shadow("shadow_color1");
        }
        .left {
          border-right: 1px solid;
          @include border_color("border_color7");
        }
      }
      .block {
        width: 100%;
        margin: 0 15px 10px;
      }
      .amount_box {
        @include flex-set();
        justify-content: space-between;
        width: 100%;
        margin: 10px;
        // margin-bottom: 50px;
        .amount_btn {
          border: 1px solid;
          border-radius: 3px;
          // width: 13%;
          width: 23%;
          padding: 10px 0;
          cursor: pointer;
          @include border_color("border_color3");

          &:active {
            border: 1px solid;
            @include background_color("background_color5");
            @include border_color("border_color4");
          }
        }
      }
      .border_bottom_none {
        border-bottom: 1px #00000000 solid;
      }
      .border_bottom {
        border-bottom: 1px solid;
        @include border_color("border_color4");
      }
      .red_border_bottom {
        border-bottom: 1px solid;
        @include border_color("border_color6");
      }
      .blue_color {
        @include font_color("font_color5");
      }

      .button_box {
        width: 50%;
        padding: 5px;
        margin-top: 20px;
        margin-bottom: 10px;
        .button {
          height: 38px;
          border-radius: 4px;
          line-height: 38px;
          text-align: center;
          @include box_shadow("shadow_color1");
          @include text_shadow("shadow_color1");

          &:hover {
            cursor: pointer;
            transform: scale(1.05);
            transition-timing-function: ease-in-out;
            transition-duration: 0.2s;
          }
        }
      }
    }
  }
  ::v-deep .el-loading-mask {
    @include background_color("background_color10");
  }
}
</style>
