<template>
  <div class="klinebox">
    <div class="chart-top-bar">
      <span
        class="period"
        :class="[{ select: select == 1 }]"
        @click="changeKlineType(1)"
      >
        1m
      </span>
      <span
        class="period"
        :class="[{ select: select == 2 }]"
        @click="changeKlineType(2)"
      >
        5m
      </span>
      <span
        class="period"
        :class="[{ select: select == 3 }]"
        @click="changeKlineType(3)"
      >
        15m
      </span>
      <span
        class="period"
        :class="[{ select: select == 4 }]"
        @click="changeKlineType(4)"
      >
        30m
      </span>
      <span
        class="period"
        :class="[{ select: select == 5 }]"
        @click="changeKlineType(5)"
      >
        1H
      </span>
      <span
        class="period"
        :class="[{ select: select == 6 }]"
        @click="changeKlineType(6)"
      >
        2H
      </span>
      <span
        class="period"
        :class="[{ select: select == 7 }]"
        @click="changeKlineType(7)"
      >
        4H
      </span>
      <span
        class="period"
        :class="[{ select: select == 8 }]"
        @click="changeKlineType(8)"
      >
        6H
      </span>
      <span
        class="period"
        :class="[{ select: select == 9 }]"
        @click="changeKlineType(9)"
      >
        12H
      </span>
      <span
        class="period"
        :class="[{ select: select == 10 }]"
        @click="changeKlineType(10)"
      >
        1D
      </span>
      <span
        class="period"
        :class="[{ select: select == 11 }]"
        @click="changeKlineType(11)"
      >
        1W
      </span>
    </div>
    <div class="klinearea">
      <div>
        <div id="draw-shape-k-line" class="k-line-chart" />
        <div class="chart_tools_bar">
          <div
            class="icon"
            v-for="{ key, icon } in shapes"
            :key="key"
            v-on:click="setShapeType(key)"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="key"
              placement="right"
              :hide-after="700"
            >
              <img :src="icon" />
            </el-tooltip>
          </div>
          <div class="icon" v-on:click="removeAllShape()">
            <el-tooltip
              class="item"
              effect="dark"
              content="clear"
              placement="right"
            >
              <img class="icon_img" src="@/assets/icons/kline/15.svg" />
            </el-tooltip>
          </div>
        </div>
      </div>
      <div
        id="kline"
        ref="kline"
        v-loading="loading"
        element-loading-background="#00000080"
      ></div>
    </div>
  </div>
</template>

<script>
import { init, dispose } from "klinecharts";
import { checkCoordinateOnSegment } from "klinecharts/lib/shape/shapeHelper";
import moment from "moment";
import { getKline } from "@/services/Api.js";

const rect = {
  name: "rect",
  totalStep: 3,
  checkEventCoordinateOnShape: ({ dataSource, eventCoordinate }) => {
    return checkCoordinateOnSegment(
      dataSource[0],
      dataSource[1],
      eventCoordinate
    );
  },
  createShapeDataSource: ({ coordinates }) => {
    if (coordinates.length === 2) {
      return [
        {
          type: "line",
          isDraw: false,
          isCheck: true,
          dataSource: [
            [
              { ...coordinates[0] },
              { x: coordinates[1].x, y: coordinates[0].y },
            ],
            [
              { x: coordinates[1].x, y: coordinates[0].y },
              { ...coordinates[1] },
            ],
            [
              { ...coordinates[1] },
              { x: coordinates[0].x, y: coordinates[1].y },
            ],
            [
              { x: coordinates[0].x, y: coordinates[1].y },
              { ...coordinates[0] },
            ],
          ],
        },
        {
          type: "polygon",
          isDraw: true,
          isCheck: false,
          styles: { style: "fill" },
          dataSource: [
            [
              { ...coordinates[0] },
              { x: coordinates[1].x, y: coordinates[0].y },
              { ...coordinates[1] },
              { x: coordinates[0].x, y: coordinates[1].y },
            ],
          ],
        },
        {
          type: "polygon",
          isDraw: true,
          isCheck: false,
          dataSource: [
            [
              { ...coordinates[0] },
              { x: coordinates[1].x, y: coordinates[0].y },
              { ...coordinates[1] },
              { x: coordinates[0].x, y: coordinates[1].y },
            ],
          ],
        },
      ];
    }
    return [];
  },
};

export default {
  name: "KLine",
  data() {
    return {
      chart: undefined,
      loading: true,
      latestRecord: null,
      timer: null,
      currentKlineType: 5,
      select: 2,
      klineSettings: {
        // 网格线
        grid: {
          show: false,
          // 网格水平线
          horizontal: {
            show: false,
          },
        },
        candle: {
          margin: {
            top: 0.7,
            bottom: 0.3,
          },
          priceMark: {
            last: {
              text: {
                size: 10,
              },
            },
          },
          tooltip: {
            // showRule: "none",
            // labels: ["T:", "O:", "C:", "H:", "L:"],
            labels: ["O:", "C:", "H:", "L:"],
            text: {
              size: 10,
              marginLeft: 0,
              marginTop: 6,
              marginRight: 5,
              marginBottom: 0,
            },
          },
        },
        // y轴
        yAxis: {
          show: true,
          width: null,
          // 'left' | 'right'
          position: "right",
          // 'normal' | 'percentage' | 'log'
          type: "normal",
          inside: false,
          reverse: false,
          // y轴线
          axisLine: {
            show: true,
            color: "#888888",
            size: 1,
          },
          // y轴分割文字
          tickText: {
            show: true,
            color: "#D9D9D9",
            family: "Helvetica Neue",
            weight: "normal",
            size: 10,
            paddingLeft: 3,
            paddingRight: 20,
          },
          // y轴分割线
          tickLine: {
            show: true,
            size: 1,
            length: 3,
            color: "#888888",
          },
        },
        // 图形
        shape: {
          point: {
            backgroundColor: "#2196F3",
            borderColor: "#2196F3",
            borderSize: 1,
            radius: 4,
            activeBackgroundColor: "#2196F3",
            activeBorderColor: "#2196F3",
            activeBorderSize: 1,
            activeRadius: 6,
          },
          line: {
            // 'solid'|'dash'
            style: "solid",
            color: "#2196F3",
            size: 1,
            dashValue: [2, 2],
          },
          polygon: {
            // 'stroke'|'fill'
            style: "stroke",
            stroke: {
              // 'solid'|'dash'
              style: "solid",
              size: 1,
              color: "#2196F3",
              dashValue: [2, 2],
            },
            fill: {
              color: "rgba(33, 150, 243, 0.1)",
            },
          },
          arc: {
            // 'stroke'|'fill'
            style: "stroke",
            stroke: {
              // 'solid'|'dash'
              style: "solid",
              size: 1,
              color: "#2196F3",
              dashValue: [2, 2],
            },
            fill: {
              color: "#2196F3",
            },
          },
          text: {
            style: "fill",
            color: "#2196F3",
            size: 12,
            family: "Helvetica Neue",
            weight: "normal",
            offset: [0, 10],
          },
        },
        // 技术指标
        technicalIndicator: {
          margin: {
            top: 0.2,
            bottom: 0.1,
          },
          // 提示
          tooltip: {
            // 'always' | 'follow_cross' | 'none'
            showRule: "always",
            // 'standard' | 'rect'
            showType: "standard",
            showName: true,
            showParams: true,
            defaultValue: "n/a",
            text: {
              size: 10,
              family: "Helvetica Neue",
              weight: "normal",
              color: "#D9D9D9",
              marginTop: 6,
              marginRight: 6,
              marginBottom: 0,
              marginLeft: 0,
            },
          },
        },
        crosshair: {
          show: true,
          horizontal: {
            show: true,
            // line: {
            //   show: true,
            //   // 'solid'|'dash'
            //   style: "dash",
            //   dashValue: [4, 2],
            //   size: 1,
            //   color: "#888888",
            // },
            text: {
              show: true,
              color: "#D9D9D9",
              size: 10,
              family: "Helvetica Neue",
              weight: "normal",
              paddingLeft: 2,
              paddingRight: 30,
              paddingTop: 2,
              paddingBottom: 2,
              borderSize: 1,
              borderColor: "#505050",
              borderRadius: 2,
              backgroundColor: "#505050",
            },
          },
        },
      },
      shapes: [
        {
          key: "segment",
          icon: require(`@/assets/icons/kline/9.svg`),
        },
        {
          key: "priceLine",
          icon: require(`@/assets/icons/kline/10.svg`),
        },
        {
          key: "parallelStraightLine",
          icon: require(`@/assets/icons/kline/11.svg`),
        },
        {
          key: "priceChannelLine",
          icon: require(`@/assets/icons/kline/12.svg`),
        },
        {
          key: "fibonacciLine",
          icon: require(`@/assets/icons/kline/13.svg`),
        },
        {
          key: "rect",
          icon: require(`@/assets/icons/kline/14.svg`),
        },
        {
          key: "horizontalStraightLine",
          icon: require(`@/assets/icons/kline/1.svg`),
        },
        {
          key: "horizontalRayLine",
          icon: require(`@/assets/icons/kline/2.svg`),
        },
        {
          key: "horizontalSegment",
          icon: require(`@/assets/icons/kline/3.svg`),
        },
        {
          key: "verticalStraightLine",
          icon: require(`@/assets/icons/kline/4.svg`),
        },
        {
          key: "verticalRayLine",
          icon: require(`@/assets/icons/kline/5.svg`),
        },
        {
          key: "verticalSegment",
          icon: require(`@/assets/icons/kline/6.svg`),
        },
        {
          key: "straightLine",
          icon: require(`@/assets/icons/kline/7.svg`),
        },
        {
          key: "rayLine",
          icon: require(`@/assets/icons/kline/8.svg`),
        },
      ],
      technicalParam: {
        name: "MA",
        shortName: "MA",
        // calcParams: [5, 10, 30, 60],
        calcParams: [5, 10],
        precision: 6,
        plots: [
          { key: "ma1", title: "MA5: ", type: "line" },
          { key: "ma2", title: "MA10: ", type: "line" },
          // { key: "ma3", title: "MA30: ", type: "line" },
          // { key: "ma4", title: "MA60: ", type: "line" },
        ],
        // 当计算参数改变时，希望提示的和参数一样，即title的值需要改变
        regeneratePlots: (params) => {
          return params.map((p, i) => {
            return { key: `ma${i + 1}`, title: `MA${p}: `, type: "line" };
          });
        },
        // 计算结果
        calcTechnicalIndicator: (kLineDataList, { params, plots }) => {
          // 注意：返回数据个数需要和kLineDataList的数据个数一致，如果无值，用{}代替即可。
          // 计算参数最好取回调参数params，如果不是，后续计算参数发生变化的时候，这里计算不能及时响应
          const closeSums = [];
          return kLineDataList.map((kLineData, i) => {
            const ma = {};
            const close = kLineData.close * 100;
            params.forEach((param, j) => {
              closeSums[j] = (closeSums[j] || 0) + close;
              if (i >= param - 1) {
                ma[plots[j].key] = closeSums[j] / param;
                closeSums[j] -= kLineDataList[i - (param - 1)].close * 100;
              }
            });
            // 如果有值的情况下，这里每一项的数据格式应该是 { ma1: xxx, ma2: xxx }
            // 每个key需要和plots中的子项key对应的值一致

            ma["ma1"] ? (ma["ma1"] = ma["ma1"] / 100) : null;
            ma["ma2"] ? (ma["ma2"] = ma["ma2"] / 100) : null;
            // ma["ma3"] ? (ma["ma3"] = ma["ma3"] / 100) : null;
            // ma["ma4"] ? (ma["ma4"] = ma["ma4"] / 100) : null;

            return ma;
          });
        },
      },
      VolumeTechnicalParam: {
        name: "VOL",
        shortName: "VOL",
        series: "volume",
        shouldFormatBigNumber: true,
        plots: [
          {
            key: "volume",
            title: "VOLUME:",
            type: "bar",
            color: function (data, options) {
              const kLineData = data.current.kLineData || {};
              if (kLineData.close * 100 < kLineData.open * 100) {
                return options.bar.downColor;
              }
              return options.bar.upColor;
            },
          },
        ],
        calcTechnicalIndicator: function (dataList) {
          return dataList.map((kLineData) => {
            return {
              volume: kLineData.volume,
            };
          });
        },
      },
      paneId: null,
    };
  },
  watch: {
    latestRecord(newValue) {
      this.chart.updateData(newValue);
    },
  },
  mounted() {
    this.chart = init(this.$refs.kline, this.klineSettings);
    this.chart.addTechnicalIndicatorTemplate(this.technicalParam);
    this.chart.addTechnicalIndicatorTemplate(this.VolumeTechnicalParam);
    this.paneId = this.chart.createTechnicalIndicator("VOL", false);
    this.setCandleTechnicalIndicator("MA");
    this.setSubTechnicalIndicator("VOL", { id: this.paneId });
    this.chart.setPriceVolumePrecision(6, 2);
    this.chart.addShapeTemplate([rect]);
    this.getKlineData(this.currentKlineType, true);
    this.startKlineTimer();
    window.addEventListener("resize", this.resizeKline);
  },
  methods: {
    resizeKline() {
      // this.chart.resize();
    },
    changeKlineType(type) {
      this.select = type;
      switch (type) {
        case 1:
          this.currentKlineType = 1;
          break;

        case 2:
          this.currentKlineType = 5;
          break;

        case 3:
          this.currentKlineType = 15;
          break;

        case 4:
          this.currentKlineType = 30;
          break;

        case 5:
          this.currentKlineType = 60;
          break;

        case 6:
          this.currentKlineType = 60 * 2;
          break;

        case 7:
          this.currentKlineType = 60 * 4;
          break;

        case 8:
          this.currentKlineType = 60 * 6;
          break;

        case 9:
          this.currentKlineType = 60 * 12;
          break;

        case 10:
          this.currentKlineType = 60 * 24;
          break;

        case 11:
          this.currentKlineType = 60 * 24 * 7;
          break;

        default:
          break;
      }
      this.getKlineData(this.currentKlineType, true);
    },
    getKlineData(period, init = false) {
      // console.log(init);
      if (init == true) {
        this.loading = true;
      }
      var senddata = {
        market: this.$route.params.marketName,
        locale: "zh-CN",
        period: period,
        timestamp: moment().subtract(15, "d").format("X"),
        end: moment().format("X"),
      };
      const queryString = Object.keys(senddata)
        .map((key) => key + "=" + senddata[key])
        .join("&");

      getKline(queryString)
        .then((res) => {
          var klinedata = [];
          for (let i = 0; i < res.data.length; i++) {
            // console.log(this.getRandom());
            let temp = {
              open: this.fillZero(res.data[i][1]),
              high: this.fillZero(res.data[i][2]),
              low: this.fillZero(res.data[i][3]),
              close: this.fillZero(res.data[i][4]),
              timestamp: res.data[i][0] * 1000,
              turnover: 0,
              volume: res.data[i][5],
            };
            if (
              temp.open == temp.high &&
              temp.open == temp.low &&
              temp.open == temp.close &&
              temp.high == temp.low &&
              temp.high == temp.close &&
              temp.low == temp.close
            ) {
              temp = {
                open: this.fillZero(res.data[i][1] * this.getRandom()),
                high: this.fillZero(res.data[i][2] * this.getRandom()),
                low: this.fillZero(res.data[i][3] * this.getRandom()),
                close: this.fillZero(res.data[i][4] * this.getRandom()),
                timestamp: res.data[i][0] * 1000,
                turnover: 0,
                volume: res.data[i][5],
              };
            }
            klinedata.push(temp);
          }
          if (init == true) {
            this.chart.applyNewData(klinedata);
          } else {
            this.latestRecord = klinedata[klinedata.length - 1];
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    startKlineTimer() {
      this.timer = setInterval(() => {
        this.getKlineData(this.currentKlineType);
      }, 10000);
    },
    setShapeType: function (shapeName) {
      this.chart.createShape(shapeName);
    },
    removeAllShape() {
      this.chart.removeShape();
    },
    setCandleTechnicalIndicator(type) {
      this.chart.createTechnicalIndicator(type, false, {
        id: "candle_pane",
      });
    },
    setSubTechnicalIndicator(type) {
      this.chart.createTechnicalIndicator(type, false, {
        id: this.paneId,
      });
    },
    getRandom() {
      var value = (Math.floor(Math.random() * 20) + 990) / 1000;
      if (value === 1) {
        value = this.getRandom();
      }
      return value;
    },
    fillZero(num) {
      if (num == 0 || num == "0") {
        return num;
      }
      return Number(num).toFixed(6);
      // return num;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    dispose(this.chart);
    removeEventListener("resize", this.resizeKline);
  },
};
</script>

<style lang="scss" scoped>
.klinebox {
  ::v-deep .el-loading-mask {
    @include background_color("background_color10");
  }
}
.chart-top-bar {
  margin-top: 10px;
  overflow: scroll;
  @include flex-set(unset, start);
  .period {
    padding: 2px;
    transition: all 0.2s;
    border-radius: 2px;
    margin-right: 6px;

    &:hover {
      cursor: pointer;
    }
  }
  .select {
    @include font_color("font_color5");
  }
}

.klinearea {
  width: 100%;
  max-width: 660px;
  @include flex-set();
  .chart_tools_bar {
    width: 40px;
    height: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;

    .icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      margin-top: 2px;
      cursor: pointer;
      border-radius: 4px;

      &:focus {
        @include background_color("background_color6");
      }
      .icon_img {
        height: 32px;
        width: 32px;
      }
    }
  }
}
#kline {
  height: 270px;
  max-width: 620px;
  width: calc(100% - 40px);
  @include flex-set();
  padding: 0 10px;
  margin-top: 5px;
}

@media screen and (min-width: 660px) {
  .klinearea {
    .chart_tools_bar {
      height: 390px;
    }
  }
  #kline {
    height: 390px;
  }
}
</style>
