export default {
  title: "Login",
  email: "Email address or phone number",
  password: "Login password",
  login: "Login",
  register: "Register",
  forgotPassword: "Forgot Password",
  warning: "Please enter account and password",
  success: "Login successful",
};
