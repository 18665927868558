<template>
  <div class="traderecord">
    <div class="top_buttons">
      <div
        class="button"
        :class="[{ select: selected == 2 }]"
        ref="button2"
        @click="clickBtn(2)"
      >
        {{ $t("kline.asksAndBids") }}
      </div>
      <div
        class="button"
        :class="[{ select: selected == 3 }]"
        ref="button3"
        @click="clickBtn(3)"
      >
        {{ $t("kline.bids") }}
      </div>
      <div
        class="button"
        :class="[{ select: selected == 1 }]"
        ref="button1 select"
        @click="clickBtn(1)"
      >
        {{ $t("kline.asks") }}
      </div>
    </div>
    <div class="title" v-if="selected != 2">
      <div class="tab left">{{ $t("kline.price") }}({{ priceCoin }})</div>
      <div class="tab right">{{ $t("kline.volume") }}({{ countCoin }})</div>
      <div class="tab right">{{ $t("kline.total") }}</div>
    </div>
    <div class="title widetitle" v-else>
      <div class="box">
        <div class="tab2 left">{{ $t("kline.price") }}({{ priceCoin }})</div>
        <div class="tab2 right">{{ $t("kline.volume") }}({{ countCoin }})</div>
      </div>
      <div class="box">
        <div class="tab2 left">{{ $t("kline.price") }}({{ priceCoin }})</div>
        <div class="tab2 right">{{ $t("kline.volume") }}({{ countCoin }})</div>
      </div>
    </div>
    <div
      class="tradedata selectStyle"
      v-for="(current, index) in asks"
      :key="selected + '_asks_' + index"
      v-show="selected == 1"
      @click="selectOrderData(current.price, current.volume, 'ask')"
    >
      <div
        class="data2 left"
        :class="[{ red: selected == 1 }, { green: selected == 3 }]"
      >
        {{ fillZero(current.price) }}
      </div>
      <div class="data2 right">{{ fillZero(current.volume) }}</div>
      <div class="data2 right">{{ fillZero2(current.total) }}</div>
    </div>
    <div
      class="tradedata selectStyle"
      v-for="(current, index) in bids"
      :key="selected + '_bids_' + index"
      v-show="selected == 3"
      @click="selectOrderData(current.price, current.volume, 'bid')"
    >
      <div
        class="data2 left"
        :class="[{ red: selected == 1 }, { green: selected == 3 }]"
      >
        {{ fillZero(current.price) }}
      </div>
      <div class="data2 right">{{ roundDown(current.volume) }}</div>
      <div class="data2 right">{{ fillZero2(current.total) }}</div>
    </div>
    <div class="listgroup">
      <div class="box">
        <div
          class="tradedata selectStyle"
          v-for="(current, index) in bids"
          :key="selected + '_bids2_' + index"
          v-show="selected == 2"
          @click="selectOrderData(current.price, current.volume, 'bid')"
        >
          <div class="data2 green price">
            {{ fillZero(current.price) }}
          </div>
          <div class="data2 volume">{{ roundDown(current.volume) }}</div>
        </div>
      </div>
      <div class="box">
        <div
          class="tradedata selectStyle"
          v-for="(current, index) in asks"
          :key="selected + '_asks2_' + current + index"
          v-show="selected == 2"
          @click="selectOrderData(current.price, current.volume, 'ask')"
        >
          <div class="data2 red price">
            {{ fillZero(current.price) }}
          </div>
          <div class="data2 volume">{{ roundDown(current.volume) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getdepthMarket } from "@/services/Api.js";

export default {
  name: "HandiCap",
  data() {
    return {
      // tradeRecordData: [],
      bids: [],
      asks: [],
      countCoin: "",
      priceCoin: "",
      selected: 2,
      timer: null,
    };
  },
  watch: {
    "$route.params.marketName": {
      handler: function (newValue) {
        console.log(newValue);
        let temp = newValue.split("/");
        this.countCoin = temp[0];
        this.priceCoin = temp[1];
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.marketName = this.$route.params.marketName;
    this.getData();
    this.timer = setInterval(this.getData, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    fillZero(num) {
      return num;
      // if (num == 0 || num == "0") {
      //   return num;
      // }
      // return Number(num).toFixed(4);
    },
    fillZero2(num) {
      if (num == 0 || num == "0") {
        return num;
      }
      return Number(num).toFixed(4);
    },
    clickBtn(button) {
      this.selected = button;
      //   console.log(this.$refs[`button${button}`]);
      //   for (let i = 1; i <= 3; i++) {
      //     this.$refs[`button${button}`].classList.remove("select");
      //     if (button == i) {
      //       this.$refs[`button${button}`].classList.add("select");
      //     }
      //   }
    },
    getData() {
      getdepthMarket(this.marketName)
        .then((res) => {
          this.bids = [];
          this.asks = [];
          // console.log(res);
          for (let i = 0; i < res.data.bids.length; i++) {
            var temp = {
              price: res.data.bids[i][0],
              volume: res.data.bids[i][1],
              total: res.data.bids[i][0] * res.data.bids[i][1],
            };
            this.bids.push(temp);
          }
          for (let i = 0; i < res.data.asks.length; i++) {
            temp = {
              price: res.data.asks[i][0],
              volume: res.data.asks[i][1],
              total: res.data.asks[i][0] * res.data.asks[i][1],
            };
            this.asks.push(temp);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectOrderData(price, volume, type) {
      var saveData = {
        price: price,
        volume: volume,
        type: type,
      };
      this.$store.dispatch("setCurrentSelectOrderData", saveData);
    },
    roundDown(num, decimal = 4) {
      num = parseFloat(num);
      return (
        Math.floor((num + Number.EPSILON) * Math.pow(10, decimal)) /
        Math.pow(10, decimal)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.traderecord {
  width: 100%;
  @include flex-set(wrap, center, unset);
  margin-top: 10px;
  .top_buttons {
    width: 100%;
    height: 30px;
    @include flex-set(wrap, space-around);
    .button {
      height: 30px;
      width: 33.3%;
      line-height: 30px;
      &:hover {
        cursor: pointer;
      }
    }
    .select {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .title {
    width: 90%;
    @include flex-set(unset, space-between);
    line-height: 30px;
    font-weight: bold;
    .box {
      width: 45%;
      @include flex-set(wrap, center);
      .tab2 {
        width: 50%;
        @include font_color("font_color3");
        font-size: 14px;
      }
    }
    .tab {
      width: 33.3%;
      font-size: 14px;
    }
    .left {
      text-align: left;
    }
    .right {
      text-align: right;
    }
  }
  .widetitle {
    width: 100%;
  }
  .listgroup {
    width: 100%;
    @include flex-set(wrap, space-between, unset);
    .box {
      width: 45%;
      .tradedata {
        width: 100%;
        @include flex-set(unset, space-between);
        line-height: 28px;
        .data2 {
          width: 90%;
          font-size: 14px;
          overflow: hidden;
        }
        .price {
          text-align: left;
          // margin-left: 8px;
        }
        .volume {
          text-align: right;
        }
        .red {
          color: rgb(250, 100, 100);
        }
        .green {
          color: rgb(42, 165, 157);
        }
      }
    }
  }
  .tradedata {
    width: 90%;
    @include flex-set(unset);
    line-height: 28px;
    .data2 {
      font-size: 14px;
      width: 33.3%;
      overflow: hidden;
    }
    .left {
      text-align: left;
    }
    .right {
      text-align: right;
    }
    .red {
      color: rgb(250, 100, 100);
    }
    .green {
      color: rgb(42, 165, 157);
    }
  }
}
.selectStyle {
  &:hover {
    cursor: pointer;
  }
  &:active {
    background-color: #494e60;
  }
}
</style>
