export default {
  usercenter: "会员中心",
  assetManagement: "资产管理",
  orderCenter: "二元期权订单中心",
  newsCenter: "新闻中心",
  login: "登入",
  register: "注册",
  loginOut: "登出",
  balance: "USDT",
  locked: "冻结金额",
  wallet: "钱包资讯",
  charge: "充值",
  withDraw: "提币",
  customerService: "联系客服",
  customerServiceEmail: "客服信箱",
  OnlineCustomerService: "線上客服",
  zht: "繁体中文",
  zhs: "简体中文",
  en: "英文",
  logoutSuccess: "登出成功",
  inviteCode: "邀请码",
  idDocumentState: "实名状态",
  unverified: "未认证",
  verifying: "认证中",
  verified: "完成认证",
  usercheck: "实名认证",
  reject: "已驳回",
};
