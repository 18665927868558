<template>
  <div class="charge">
    <div class="title_box">
      <h1 class="title">{{ $t("charge.title") }}</h1>
      <el-tabs v-model="activeName" stretch>
        <el-tab-pane label="ETH" name="ETH"> </el-tab-pane>
        <el-tab-pane label="TRX" name="TRX"> </el-tab-pane>
      </el-tabs>
    </div>

    <div class="info_box" v-loading="loading">
      <p>
        {{ $t("charge.chargeUSDTIntoThisWallet") }}
        <br />
        {{ $t("charge.contactUsToHandle") }}
      </p>

      <div class="wallet">
        <div>{{ $t("charge.coinWalletAddress") }}:</div>
        <div class="coinAddress">{{ coinWallet }}</div>

        <div class="copy_box">
          <span class="copy" @click="copy(coinWallet)">
            {{ $t("charge.copyCoinWalletAddress") }}
          </span>
        </div>

        <div class="img_box" v-if="QRcodeUrl">
          <img :src="QRcodeUrl" alt="qrcode" />
          <div class="logo"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { gettVerify } from "@/services/Api.js";

export default {
  inject: ["message"],
  name: "ChargeView",

  watch: {
    activeName(value) {
      console.log(value);
      this.gettVerifyFun();
    },
  },

  data() {
    return {
      loading: false,
      activeName: "ERC20",
      coinWallet: "-",
      QRcodeUrl: null,
    };
  },

  mounted() {
    this.gettVerifyFun();
  },

  methods: {
    copy(val) {
      this.$copyText(val)
        .then(() => this.message("charge.copySuccess", "success"))
        .catch(() => this.message("charge.copyFail", "error"));
    },

    gettVerifyFun() {
      this.loading = true;
      this.coinWallet = "-";

      gettVerify("USDT@" + this.activeName)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            this.coinWallet = data.data.deposit_address;
            this.makeQRcodeFun(data.data.deposit_address);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          // this.message("charge.gettVerifyFail", "error");
          this.$alert(
            this.$t("charge.gettVerifyFail"),
            this.$t("charge.gettVerifyFailTitle"),
            {
              confirmButtonText: "OK",
              customClass: "alert",
              dangerouslyUseHTMLString: true,
              callback: () => {
                this.$router.push("/").catch(() => { });
                this.reload();
              },
            }
          );
        });
    },

    makeQRcodeFun(text) {
      QRCode.toDataURL(text)
        .then((url) => (this.QRcodeUrl = url))
        .catch((err) => console.error(err));
    },

    addLogoFun(qrCanvas, centerImage, factor) {
      const h = qrCanvas.height;
      const cs = h * factor;
      const co = (h - cs) / 2;

      const ctx = qrCanvas.getContext("2d");

      ctx.drawImage(
        centerImage,
        0,
        0,
        centerImage.width,
        centerImage.height,
        co,
        co,
        cs,
        cs
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.charge {
  height: 100%;
  position: relative;

  .title_box {
    @include titleBox;

    .title {
      @include titleBox_title;
    }
  }

  .info_box {
    @include info_box;

    ::v-deep .el-loading-mask {
      @include background_color("background_color10");
    }
  }

  .wallet {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;

    .coinAddress {
      word-break: break-all;
      padding: 0 1rem;
    }

    .copy_box {
      margin-bottom: 20px;
    }

    div {
      margin-bottom: 0.5rem;

      span {
        &.copy {
          border: 1px solid;
          display: inline-flex;
          padding: 0.5rem;
          border-radius: 5px;
          cursor: pointer;
          @include font_color("font_color5");
          @include border_color("border_color4");

          &:hover {
            transform: scale(1.1);
            transition-timing-function: ease-in-out;
            transition-duration: 0.2s;
          }
        }
      }
    }

    .img_box {
      position: relative;
      margin: 0 auto;

      img {
        width: 300px;
        max-width: 100%;
      }

      .logo {
        background: url("../assets/images/small_logo.png");
        background-position: center;
        background-size: contain;
        position: absolute;
        border: 3px solid;
        border-radius: 50%;
        width: 26%;
        height: 26%;
        top: calc(50% - 13%);
        left: calc(50% - 13%);
        @include border_color("border_color3");
      }
    }
  }
}
</style>

<style lang="scss">
.el-tabs__item {
  color: #fff !important;
  font-size: 16px !important;
}

.is-active {
  @include font_color("font_color5");
}
</style>
