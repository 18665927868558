export default {
  title: "提幣",
  enterWalletAddressAndPrice: "請輸入您的幣包地址與提出的USDT顆數,",
  enterWalletAddressAndPrice1: "請輸入您的幣包地址與提出的",
  enterWalletAddressAndPrice2: "顆數,",
  contactForServiceAfterSend: "確認送出後請與客服聯繫",
  coinWalletAddress: "幣包地址",
  enterWalletAddress: "請輸入幣包地址",
  withdrawPrice: "提出顆數",
  withdrawPrice1: "提出的",
  withdrawPrice2: "顆數",
  enterWithdrawPrice: "請輸入提出的USDT顆數",
  enterWithdrawPrice1: "請輸入提出的",
  enterWithdrawPrice2: "顆數",
  send: "送出",
  inputIncorrect: "有欄位尚未填寫",
  contactForService: "已送出，請與客服聯繫",
  confirm: "確認",
  fee: "手續費",
  total: "需要總顆數",
  actualCredit: "實際到賬",
};
