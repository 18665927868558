import common from "./zht/common";
import navbar from "./zht/navbar";
import home from "./zht/home";
import footer from "./zht/footer";
import kline from "./zht/kline";
import charge from "./zht/charge";
import withdraw from "./zht/withdraw";
import order from "./zht/order";
import forgotpassword from "./zht/forgotpassword";
import login from "./zht/login";
import register from "./zht/register";
import usercheck from "./zht/usercheck";
import whitePaper from "./zht/whitePaper";

export default {
  common: {
    ...common,
  },
  navbar: {
    ...navbar,
  },
  home: {
    ...home,
  },
  footer: {
    ...footer,
  },
  kline: {
    ...kline,
  },
  charge: {
    ...charge,
  },
  withdraw: {
    ...withdraw,
  },
  order: {
    ...order,
  },
  forgotpassword: {
    ...forgotpassword,
  },
  login: {
    ...login,
  },
  register: {
    ...register,
  },
  usercheck: {
    ...usercheck,
  },
  whitePaper: {
    ...whitePaper,
  },
};
