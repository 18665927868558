<template>
  <div class="orderView" v-loading="loading">
    <h1 class="title">{{ $t("order.orderCenter") }}</h1>
    <div v-if="tableList.predictions">
      <div class="content">
        <div
          class="list"
          v-for="(item, index) in tableList.predictions"
          :key="index"
        >
          <!-- <h1>{{ $t("order.orderno") }}:{{ item.sn }}</h1> -->

          <div class="row">
            <h1>{{ $t("order.orderno") }}</h1>
            <h1>{{ item.sn }}</h1>
          </div>

          <div class="row">
            <span>{{ $t("order.orderTime") }}</span>
            <span>{{ handleTime(item.created_at) }}</span>
          </div>

          <!-- <div class="row">
            <span>{{ $t("order.predictOpenLotteryTime") }}</span>
            <span>{{ item.closed_at && handleTime(item.closed_at) }}</span>
          </div> -->

          <div class="row">
            <span>{{ $t("order.tradingPair") }}</span>
            <span>{{ getCoinName(item.market_id) }}</span>
          </div>

          <div class="row">
            <span>{{ $t("order.type") }}</span>
            <span>{{ item.lock_price }}</span>
          </div>

          <div class="row">
            <span>{{ $t("order.predictDirection") }}</span>
            <span>{{
              item.position === "long" ? $t("kline.buyUp") : $t("kline.buyDown")
            }}</span>
          </div>

          <div class="row">
            <span>{{ $t("order.odds") }}</span>
            <span>{{ item.payout }}</span>
          </div>

          <div class="row">
            <span>{{ $t("order.settled") }}</span>
            <span>{{ item.closed_at ? $t("order.yes") : $t("order.no") }}</span>
          </div>

          <div class="row">
            <span>{{ $t("order.closeprice") }}</span>
            <span>{{ item.close_price }}</span>
          </div>

          <div class="row">
            <span>{{ $t("order.closeTime") }}</span>
            <span>{{ item.closed_at && handleTime(item.closed_at) }}</span>
          </div>

          <div class="row">
            <span>{{ $t("order.predictPrice") }}</span>
            <span>{{ item.volume }}</span>
          </div>

          <div class="row">
            <span>{{ $t("order.result") }}</span>
            <span>{{
              !item.closed_at
                ? ""
                : item.closed_at && item.win
                ? $t("order.win")
                : $t("order.lose")
            }}</span>
          </div>

          <div :class="['row', item.win ? 'win' : 'lose']">
            <span>{{ $t("order.amount") }}</span>
            <span>{{ item.closed_at && item.settled_amount }}</span>
          </div>
        </div>
      </div>

      <el-pagination
        layout="prev, pager, next"
        :page-count="totalPage"
        @current-change="changePage"
        :pager-count="5"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
    <div
      class="no_data"
      v-if="tableList.predictions && tableList.predictions.length === 0"
    >
      {{ $t("order.noDataMsg") }}
    </div>
  </div>
</template>

<script>
import { getPredictions, getMarkets } from "@/services/Api.js";
import moment from "moment";

export default {
  name: "OrderView",

  mounted() {
    this.getData();
  },

  data() {
    return {
      tableList: [],
      markets: [],
      totalPage: 1,
      currentPage: 1,
      loading: false,
    };
  },

  methods: {
    getData() {
      this.loading = true;
      var senddata = {
        pages: this.currentPage,
        count: 10,
      };
      const queryString = Object.keys(senddata)
        .map((key) => key + "=" + senddata[key])
        .join("&");

      getPredictions(queryString)
        .then((res) => {
          this.loading = false;
          const { success, data } = res.data;

          if (success) {
            this.tableList = data;
            this.totalPage = data.total_pages;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });

      getMarkets()
        .then((res) => {
          const { data } = res;
          this.markets = data;
        })
        .catch((err) => console.log(err));
    },
    changePage(value) {
      this.currentPage = value;
      this.getData();
    },
    handleTime(time) {
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    getCoinName(value) {
      for (let i = 0; i < this.markets.length; i++) {
        if (this.markets[i].id === value) return this.markets[i].name;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.orderView {
  height: 100vh;
  overflow-y: scroll;

  .title {
    @include titleBox_title;
  }
  .content {
    @include record_wrap;
    .list {
      @include record_box;

      &:first-of-type {
        margin-top: 1rem;
      }

      h1 {
        margin-bottom: 1rem;
      }

      .row {
        @include record;

        &.win {
          @include font_color("font_color1");
        }

        &.lose {
          @include font_color("font_color2");
        }
      }
    }
  }

  .no_data {
    margin-top: 50%;
    @include border_color("border_color5");
  }

  ::v-deep .el-loading-mask {
    @include background_color("background_color10");
  }
}
</style>

<style lang="scss">
.btn-prev {
  background-color: unset !important;
  @include font_color("font_color10");
}
.btn-next {
  background-color: unset !important;
  @include font_color("font_color10");
}
.el-pager li {
  background: unset !important;
  @include font_color("font_color10");
}
.el-icon-more {
  @include font_color("font_color10");
}
</style>
