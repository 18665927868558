export default {
  title: "實名認證",
  msg_1: "您還未進行實名認證，點擊本條信息前往實名認證頁面",
  countryOrArea: "國家或地區",
  addCountryOrAreaCode: "請選擇國家或地區",
  cancel: "取消",
  realName: "真實姓名",
  warningRealName: "請輸入真實姓名",
  documentType: "證件類型",
  warningDocumentType: "請輸入證件類型",
  IDcard: "身份證",
  passport: "護照",
  driversLicense: "駕照",
  certificateNumber: "證件號碼",
  warningCertificateNumber: "請輸入證件號碼",
  front: "證件正面",
  warningFrontImage: "請上傳證件正面圖片",
  Back: "證件背面",
  warningCoverImage: "請上傳證件背面圖片",
  handHeld: "手持證件照",
  warningCarryImage: "請上傳手持證件圖片",
  upload: "上傳",
  msg_2: "提示：請確保您的照片裡的所有文字清晰可見，否則不能通過實名審核",
  confirm: "確認",
  cancelVerification: "取消認證",
  imageisLt5M: "檔案大小不得超過1MB",
  pleaseReLogin: "登入狀態失效，請重新登入",
  errorOccursWhenUpload: "上傳資料時發生問題，請重試",
  uploadSuccess: "上傳成功",
  rejectReason: "拒絕理由",
  verifying: "認證中",
  verified: "完成認證",
  mustBeAnImage: "圖片格式必須為：JPG, JPEG, PNG",
};
