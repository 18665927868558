export default {
  title: "Withdrawal",
  enterWalletAddressAndPrice:
    "Please enter your wallet address and The number of USDT proposed,",
  enterWalletAddressAndPrice1:
    "Please enter your wallet address and The number of ",
  enterWalletAddressAndPrice2: " proposed,",
  contactForServiceAfterSend:
    "Please contact customer service after confirming delivery",
  coinWalletAddress: "Address",
  enterWalletAddress: "Enter the wallet address",
  withdrawPrice: "Quantity",
  withdrawPrice1: "The  ",
  withdrawPrice2: "number of withdrawal",
  enterWithdrawPrice: "Enter USDT number",
  enterWithdrawPrice1: "Enter ",
  enterWithdrawPrice2: " number",
  send: "Send",
  inputIncorrect: "There are fields that have not been filled in",
  contactForService: "Request sent, Please contact customer service",
  confirm: "Confirm",
  fee: "Fees",
  total: "Total",
  actualCredit: "Receive",
};
