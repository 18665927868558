export default {
  mtf: {
    p1: "MTF Token是一種人工智能與未來共享技術。基於MTF的虛擬現實社區和沈浸式交互應用生態系統。旨在通過在MTF中構建虛擬現實社區，為沈浸式交互提供一個開放的界面，並與線下實體進行數字孿生，從而實現一戶一宇宙的目標。",
    p2: "在MTF社區中，可以構建一個沉浸式互動社區空間，全面映射和再現線下實體的社交社區、娛樂、商業、購物、遊戲等屬性。通過使用世界上最先進的技術在現實世界中創造虛擬。真實世界的各種場景再現，如城市、街道、地產、商業街等，可視化效果顯著提升用戶體驗，人們可以在這個空間體驗不同場景的沉浸式內容。",
    p3: "MTF Token 社區希望創建終極虛擬現實中心，讓用戶專注於身臨其境且引人入勝的環境。因此，這是用戶第一次可以在一個地方訪問加密信息和沈浸式內容，並訪問不同級別的區塊鏈教育資源。附加內容、虛擬業務資源、逼真的遊戲體驗以及您可以通過虛擬現實體驗想像的任何其他內容。 ",
    create: "創建",
    createStr:
      "MTF中的虛擬角色圖像，用戶可以自定義，例如；將自己購買的虛擬圍巾戴在虛擬個人身上，生成個性化搭配的獨特角色，而這兩款二次創作產品都有NFT防偽證書。",
    interact: "交互",
    interactStr:
      "通過VR和AR進行渲染，讓用戶看到更細緻的真實IP。其中，AR支持虛擬物體依附於真實環境，提供類似電影失控播放器的虛實體驗；將虛擬人物投射到現實生活中，完成預設動作並與現實環境中的元素進行交互。",
    share: "分享",
    shareStr:
      "用戶可以通過AR將自己購買的虛擬IP投射到現實世界中。用戶可以與頭像互動，將內容的照片或視頻發佈到社區，也可以用同樣的方式發布自己的二次創作進行分享。",
    trade: "交易",
    tradeStr:
      "合作的虛擬IP持有者，不限於公司或個人，可以在MTF Token上上架和交易商品。未來，MTF Token還將打造更多具有沉浸式體驗的SocialFi場景，如虛擬土地、虛擬展覽等。入駐企業可以在虛擬土地上銷售虛擬商品，企業要想獲得銷售資格，需要在虛擬土地上租賃土地。",
  },
  vcex: {
    p1: "IONE是一種人工智能與未來共享技術。基於IONE的虛擬現實社區和沈浸式交互應用生態系統。旨在通過在IONE中構建虛擬現實社區，為沈浸式交互提供一個開放的界面，並與線下實體進行數字孿生，從而實現一戶一宇宙的目標。",
    p2: "在IONE社區中，可以構建一個沉浸式互動社區空間，全面映射和再現線下實體的社交社區、娛樂、商業、購物、遊戲等屬性。通過使用世界上最先進的技術在現實世界中創造虛擬。真實世界的各種場景再現，如城市、街道、地產、商業街等，可視化效果顯著提升用戶體驗，人們可以在這個空間體驗不同場景的沉浸式內容。",
    p3: "IONE 社區希望創建終極虛擬現實中心，讓用戶專注於身臨其境且引人入勝的環境。因此，這是用戶第一次可以在一個地方訪問加密信息和沈浸式內容，並訪問不同級別的區塊鏈教育資源。附加內容、虛擬業務資源、逼真的遊戲體驗以及您可以通過虛擬現實體驗想像的任何其他內容。 ",
    create: "創建",
    createStr:
      "IONE中的虛擬角色圖像，用戶可以自定義，例如；將自己購買的虛擬圍巾戴在虛擬個人身上，生成個性化搭配的獨特角色，而這兩款二次創作產品都有NFT防偽證書。",
    interact: "交互",
    interactStr:
      "通過VR和AR進行渲染，讓用戶看到更細緻的真實IP。其中，AR支持虛擬物體依附於真實環境，提供類似電影失控播放器的虛實體驗；將虛擬人物投射到現實生活中，完成預設動作並與現實環境中的元素進行交互。",
    share: "分享",
    shareStr:
      "用戶可以通過AR將自己購買的虛擬IP投射到現實世界中。用戶可以與頭像互動，將內容的照片或視頻發佈到社區，也可以用同樣的方式發布自己的二次創作進行分享。",
    trade: "交易",
    tradeStr:
      "合作的虛擬IP持有者，不限於公司或個人，可以在IONE上上架和交易商品。未來，IONE還將打造更多具有沉浸式體驗的SocialFi場景，如虛擬土地、虛擬展覽等。入駐企業可以在虛擬土地上銷售虛擬商品，企業要想獲得銷售資格，需要在虛擬土地上租賃土地。",
  },
  wdc: {
    p1: "WDCC是一種人工智能與未來共享技術。基於WDCC的虛擬現實社區和沈浸式交互應用生態系統。旨在通過在WDCC中構建虛擬現實社區，為沈浸式交互提供一個開放的界面，並與線下實體進行數字孿生，從而實現一戶一宇宙的目標。",
    p2: "在WDCC社區中，可以構建一個沉浸式互動社區空間，全面映射和再現線下實體的社交社區、娛樂、商業、購物、遊戲等屬性。通過使用世界上最先進的技術在現實世界中創造虛擬。真實世界的各種場景再現，如城市、街道、地產、商業街等，可視化效果顯著提升用戶體驗，人們可以在這個空間體驗不同場景的沉浸式內容。",
    p3: "WDCC 社區希望創建終極虛擬現實中心，讓用戶專注於身臨其境且引人入勝的環境。因此，這是用戶第一次可以在一個地方訪問加密信息和沈浸式內容，並訪問不同級別的區塊鏈教育資源。附加內容、虛擬業務資源、逼真的遊戲體驗以及您可以通過虛擬現實體驗想像的任何其他內容。 ",
    create: "創建",
    createStr:
      "WDCC中的虛擬角色圖像，用戶可以自定義，例如；將自己購買的虛擬圍巾戴在虛擬個人身上，生成個性化搭配的獨特角色，而這兩款二次創作產品都有NFT防偽證書。",
    interact: "交互",
    interactStr:
      "通過VR和AR進行渲染，讓用戶看到更細緻的真實IP。其中，AR支持虛擬物體依附於真實環境，提供類似電影失控播放器的虛實體驗；將虛擬人物投射到現實生活中，完成預設動作並與現實環境中的元素進行交互。",
    share: "分享",
    shareStr:
      "用戶可以通過AR將自己購買的虛擬IP投射到現實世界中。用戶可以與頭像互動，將內容的照片或視頻發佈到社區，也可以用同樣的方式發布自己的二次創作進行分享。",
    trade: "交易",
    tradeStr:
      "合作的虛擬IP持有者，不限於公司或個人，可以在WDCC上上架和交易商品。未來，WDCC還將打造更多具有沉浸式體驗的SocialFi場景，如虛擬土地、虛擬展覽等。入駐企業可以在虛擬土地上銷售虛擬商品，企業要想獲得銷售資格，需要在虛擬土地上租賃土地。",
  },
};
