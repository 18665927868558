export default {
  time: "時間",
  price: "價格",
  volume: "數量",
  tradeRecord: "市場交易紀錄",
  pleaseSelect: "請選擇",
  upsAndDowns: "漲跌",
  highPrice: "最高價",
  lowPrice: "最低價",
  volume2: "成交量",
  buyUp: "買漲",
  buyDown: "買跌",
  sendOrder: "下單",
  accountBalance: "帳戶餘額：",
  orderTime: "下單時間",
  openTime: "預測開獎時間",
  type: "倉位",
  price2: "金額",
  pleaseInputPrice: "請輸入金額",
  insufficientBalance: "餘額不足",
  notEnough: "賣出的數量過多",
  reset: "重置",
  confirmOrder: "確認下單",
  cancel: "取消",
  loginBeforeOrder: "下單前需先登入",
  orderPriceMustGreaterThan0: "下單金額必須大於0",
  temporaryCanNotOrder: "暫時無法下單 請稍後再試",
  tradingPair: "交易對",
  predictDirection: "預測方向",
  odds: "賠率",
  predictPrice: "預測金額",
  orderSuccess: "下單成功",
  confirm: "確認",
  orderID: "訂單號",
  asks: "賣單",
  bids: "買單",
  asksAndBids: "買/賣單",
  total: "總價",
  kline: "圖表",
  order: "盤口",
  openOrders: "委託中訂單",
  orderHistory: "已完成訂單",
  orderPrice: "金額：",
  orderTime2: "訂單時間：",
  orderId: "訂單ID：",
  orderVolume: "數量：",
  orderKind: "掛單類別：",
  orderKind2: "掛單類別",
  orderBid: "買入",
  orderAsk: "賣出",
  noDataText: "無資料",
  tradeTitle1: "現貨交易",
  tradeTitle2: "指數預測",
  buy: "買入",
  sell: "賣出",
  limit: "限價",
  market: "市價",
  totalCount: "總計",
  priceWarningMsg: "下單金額必須大於0",
  volumeWarningMsg: "下單數量必須大於0",
  confirmCancelOrder: "確認取消此筆掛單？",
  confirmYes: "是",
  confirmNo: "否",
  cancelOrderSuccess: "取消掛單成功",
  cancelOrderError: "取消掛單失敗",
  pleaseInputPrice2: "請輸入價格",
  pleaseInputVolume: "請輸入數量",
  marketName: "交易對：",
  fundsReceived: "總計：",
};
