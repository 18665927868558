export default {
  title: "充值",
  copySuccess: "复制成功",
  copyFail: "复制失败",
  chargeUSDTIntoThisWallet: "请将您要充值的USDT打到以下地址,",
  chargeIntoThisWallet1: "请将您要充值的",
  chargeIntoThisWallet2: "打到以下地址,",
  contactUsToHandle: "请联系客服将会为您尽速处理。",
  coinWalletAddress: "币包地址",
  copyCoinWalletAddress: "复制币包地址",
  gettVerifyFailTitle: "提示",
  gettVerifyFail: "充值功能暂时无法使用 请稍后再试",
  showQRcode: "查看二维码",
  charge: "充值",
  withdraw: "提币",
  msg1: "此功能暂时不支持",
  msg2: "操作此功能必须先完成实名认证",
  walletTitle: "钱包资讯",
  currency: "币种",
  balance: "余额",
  usdtType: "选择充值的 USDT 类型",
  usdtType2: "选择提币的 USDT 类型",
  chargeRecord: "充值記錄",
  withdrawRecord: "提幣記錄",
  noData: "目前沒有資料",
  id: "編號",
  amount: "數量",
  fee: "手續費",
  created_at: "提交時間",
  updated_at: "操作時間",
  state: "狀態",
  charge_fund_uid: "充值地址",
  withdraw_fund_uid: "提現地址",
  cancel_reason: "備註",
  submitting: "受理中",
  canceled: "已撤銷",
  cancelled: "已撤銷",
  submitted: "待處理",
  rejected: "已駁回",
  accepted: "已同意",
  checked: "已檢查",
  warning: "警告",
  suspect: "關注",
  processing: "處理中",
  faulted: "出錯",
  done: "完成",
  failed: "失敗",
  refunded: "已退款",
  error_msg: "请确认币包地址格式是否正确",
  title1: "币种",
  title2: "余额",
  title3: "约等于",
};
