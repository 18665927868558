export default {
  title: "充值",
  copySuccess: "複製成功",
  copyFail: "複製失敗",
  chargeUSDTIntoThisWallet: "請將您要充值的USDT打到以下地址,",
  chargeIntoThisWallet1: "請將您要充值的",
  chargeIntoThisWallet2: "打到以下地址,",
  contactUsToHandle: "請聯繫客服將會為您盡速處理。",
  coinWalletAddress: "幣包地址",
  copyCoinWalletAddress: "複製幣包地址",
  gettVerifyFailTitle: "提示",
  gettVerifyFail: "充值功能暫時無法使用 請稍後再試",
  showQRcode: "查看二維碼",
  charge: "充值",
  withdraw: "提幣",
  msg1: "此功能暫時不支持",
  msg2: "操作此功能必須先完成實名認證",
  walletTitle: "錢包資訊",
  currency: "幣種",
  balance: "餘額",
  usdtType: "選擇充值的 USDT 類型",
  usdtType2: "選擇提幣的 USDT 類型",
  chargeRecord: "充值記錄",
  withdrawRecord: "提幣記錄",
  noData: "目前沒有資料",
  id: "編號",
  amount: "數量",
  fee: "手續費",
  created_at: "提交時間",
  updated_at: "操作時間",
  state: "狀態",
  charge_fund_uid: "充值地址",
  withdraw_fund_uid: "提現地址",
  cancel_reason: "備註",
  submitting: "受理中",
  canceled: "已撤銷",
  cancelled: "已撤銷",
  submitted: "待處理",
  rejected: "已駁回",
  accepted: "已同意",
  checked: "已檢查",
  warning: "警告",
  suspect: "關注",
  processing: "處理中",
  faulted: "出錯",
  done: "完成",
  failed: "失敗",
  refunded: "已退款",
  error_msg: "請確認幣包地址格式是否正確",
  title1: "幣種",
  title2: "餘額",
  title3: "約等於",
};
