export default {
  time: "time",
  price: "price",
  volume: "Quantity",
  tradeRecord: "Trade Record",
  pleaseSelect: "Please select",
  upsAndDowns: "Ups and Downs",
  highPrice: "Highest Price",
  lowPrice: "Lowest Price",
  volume2: "Volume",
  buyUp: "Buy Up",
  buyDown: "Buy Down",
  sendOrder: "Order",
  accountBalance: "Account Balance:",
  orderTime: "Order time",
  openTime: "Predict draw time",
  type: "Position",
  price2: "Amount",
  pleaseInputPrice: "Please enter the amount",
  insufficientBalance: "Insufficient balance",
  notEnough: "Too many sold",
  reset: "Reset",
  confirmOrder: "Confirm order",
  cancel: "Cancel",
  loginBeforeOrder: "Login is required before placing an order",
  orderPriceMustGreaterThan0: "The order amount must be greater than 0",
  temporaryCanNotOrder:
    "Temporarily unable to place an order, please try again later",
  tradingPair: "Trading Pair",
  predictDirection: "Prediction Direction",
  odds: "Odds",
  predictPrice: "Predicted Amount",
  orderSuccess: "Order placed successfully",
  confirm: "Confirm",
  orderID: "Order ID",
  asks: "Asks",
  bids: "Bids",
  asksAndBids: "Bids/Asks",
  total: "Total",
  kline: "KLine",
  order: "Order",
  openOrders: "Open Orders",
  orderHistory: "Orders History",
  orderPrice: "Price: ",
  orderTime2: "Order time: ",
  orderId: "Order ID: ",
  orderVolume: "Volume: ",
  orderKind: "Order kind: ",
  orderKind2: "Order kind",
  orderBid: "Bid",
  orderAsk: "Ask",
  noDataText: "No data",
  tradeTitle1: "Spot Trading",
  tradeTitle2: "Index Forecast",
  buy: "Buy",
  sell: "Sell",
  limit: "Limit Price",
  market: "Market Price",
  totalCount: "Total",
  priceWarningMsg: "Order amount must be greater than 0",
  volumeWarningMsg: "The order quantity must be greater than 0",
  confirmCancelOrder: "Are you sure you want to cancel this order?",
  confirmYes: "Yes",
  confirmNo: "No",
  cancelOrderSuccess: "Order cancelled successfully",
  cancelOrderError: "Order cancellation failed",
  pleaseInputPrice2: "Please enter a price",
  pleaseInputVolume: "Please input the volume",
  marketName: "Trading pair:",
  fundsReceived: "Total:",
};
