<template>
  <div class="whitePaper">
    <img
      class="logo"
      v-if="domainType == 'mtf'"
      src="../../../assets/images/mtf/Logo.png"
      alt="logo"
    />
    <img
      class="logo"
      v-if="domainType == 'vcex'"
      src="../../../assets/images/vcex/Logo.png"
      alt="logo"
    />
    <img
      class="logo"
      v-if="domainType == 'wdc'"
      src="../../../assets/images/wdc/Logo.svg"
      alt="logo"
    />

    <p
      class="paragraph"
      v-for="(item, index) in paragraph"
      :key="`paragraph${index}`"
    >
      {{ $t(item) }}
    </p>

    <div
      class="list"
      v-for="({ img, title, content }, index) in list"
      :key="`list${index}`"
    >
      <img :src="img" :alt="title" />
      <h1>{{ $t(title) }}</h1>
      <p>
        {{ $t(content) }}
      </p>
    </div>

    <div class="button" @click="downloadWhitePaper" v-if="domainType == 'mtf'">
      {{ $t("home.mtfTokenWhitePaper") }}
    </div>
  </div>
</template>

<script>
export default {
  inject: ["downloadWhitePaper"],
  data() {
    /* global glob */
    return {
      paragraph: [
        `whitePaper.${glob.domainType}.p1`,
        `whitePaper.${glob.domainType}.p2`,
        `whitePaper.${glob.domainType}.p3`,
      ],
      list: [
        {
          img: require("@/components/Home/WhitePaper/1.svg"),
          title: `whitePaper.${glob.domainType}.create`,
          // content: "whitePaper.mtf.createStr",
        },
        {
          img: require("@/components/Home/WhitePaper/2.svg"),
          title: `whitePaper.${glob.domainType}.interact`,
          // content: "whitePaper.mtf.interactStr",
        },
        {
          img: require("@/components/Home/WhitePaper/3.svg"),
          title: `whitePaper.${glob.domainType}.share`,
          // content: "whitePaper.mtf.shareStr",
        },
        {
          img: require("@/components/Home/WhitePaper/4.svg"),
          title: `whitePaper.${glob.domainType}.trade`,
          // content: "whitePaper.mtf.tradeStr",
        },
      ],
      // domainType: "vcex", // mtf/vcex
      domainType: glob.domainType, // mtf/vcex
    };
  },
};
</script>

<style lang="scss" scoped>
.whitePaper {
  width: calc(100% - 2rem);
  margin: 0 auto;

  @include pc() {
    display: none;
  }

  .logo {
    margin-bottom: 1rem;
    width: 200px;
  }

  .paragraph {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    h1 {
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-size: 21px;
      @include font_color("font_color5");
      font-weight: 500;
    }

    img {
      width: 300px;
    }

    p {
      font-size: 14px;
      line-height: 21px;
      text-align: left;
    }
  }

  .button {
    @include whitePaperBtn;
    margin-bottom: 64px;
  }
}
</style>
