<template>
  <div class="content">
    <div class="from" v-loading="loading">
      <button class="title" data-type="email">
        {{ $t("forgotpassword.title") }}
      </button>

      <input
        class="input"
        type="password"
        :placeholder="$t('forgotpassword.password')"
        v-model="form.password"
        @keyup.enter="login"
      />
      <input
        class="input"
        type="password"
        :placeholder="$t('forgotpassword.checkPassword')"
        v-model="form.checkPassword"
        @keyup.enter="login"
      />

      <button class="btn" @click="slbmit">
        {{ $t("forgotpassword.slbmit") }}
      </button>

      <!-- <div class="footer">
        <router-link to="/register">
          {{ $t("login.register") }}
        </router-link>
        <router-link to="/forgotPassword">
          {{ $t("login.forgotPassword") }}
        </router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
import { postResetPassword } from "@/services/Api.js";
export default {
  inject: ["reload", "message"],
  name: "ResetPasswordView",
  data() {
    return {
      checked: false,
      form: {
        password: "",
        checkPassword: "",
      },
      loading: false,
    };
  },
  mounted() {
    // console.log(this.$route.query.token);
    if (!this.$route.query.token) {
      this.message("forgotpassword.message1", "warning");
      this.$router.push("/").catch(() => {});
      this.reload();
    }
  },
  methods: {
    slbmit() {
      if (this.form.password == "" || this.form.checkPassword == "") {
        this.message("forgotpassword.message2", "warning");
        return;
      }
      if (this.form.password != this.form.checkPassword) {
        this.message("forgotpassword.message3", "warning");
        return;
      }
      this.loading = true;

      const formPost = new FormData();
      formPost.append("password", this.form.password);
      formPost.append("locale", this.$route.params.lang.split(".")[0]);

      postResetPassword(this.$route.query.token, formPost)
        .then((res) => {
          const { data } = res;

          if (data.success) {
            this.message("forgotpassword.success", "success");
            this.$router.push("/").catch(() => {});
            this.reload();
          } else {
            this.message(data.errors[0].message, "warning");
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;

          this.message("forgotpassword.message1", "warning");
          this.$router.push("/").catch(() => {});
          this.reload();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  @include formContent;

  .from {
    @include form;

    .title {
      @include formPageTitle;
    }

    .input {
      @include formInput;
      margin-bottom: 24px;
    }

    label {
      color: #000;
      display: flex;
      font-size: 16px;
      text-align: left;
    }

    .btn {
      @include formBtn;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      a {
        @include font_color("font_color5");

        &:last-of-type {
          @include font_color("font_color8");
        }
      }
    }
  }

  ::v-deep .el-loading-mask {
    @include background_color("background_color11");
  }
}
</style>
