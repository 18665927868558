<template>
  <div class="KlineComponent">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">{{ $t("kline.kline") }}</el-menu-item>
      <el-menu-item index="2">{{ $t("kline.order") }}</el-menu-item>
      <el-menu-item index="3">{{ $t("kline.tradeRecord") }}</el-menu-item>
    </el-menu>
    <KLine v-if="currentTab == 1" />
    <!-- <el-divider></el-divider> -->
    <HandiCap v-if="currentTab == 2" />
    <!-- <el-divider></el-divider> -->
    <!-- <div class="title">{{ $t("kline.tradeRecord") }}</div> -->
    <TradeRecord v-if="currentTab == 3" />
    <Orderlist />
  </div>
</template>

<script>
import KLine from "@/components/MarketKline/kline/KLine.vue";
import TradeRecord from "@/components/MarketKline/kline/TradeRecord.vue";
import HandiCap from "@/components/MarketKline/kline/HandiCap.vue";
import Orderlist from "@/components/MarketKline/kline/Orderlist.vue";

export default {
  name: "KlineComponent",
  props: {
    // coinList: { Object }
  },
  components: {
    KLine,
    TradeRecord,
    HandiCap,
    Orderlist,
  },
  data() {
    return {
      activeIndex: "1",
      currentTab: 1,
    };
  },
  methods: {
    handleSelect(key) {
      console.log(key);
      this.currentTab = key;
    },
  },
};
</script>

<style lang="scss" scoped>
.KlineComponent {
  @include background_color("background_color3");
  // background: #2a2e39;
  padding: 10px;
}
</style>

<style lang="scss">
.el-menu.el-menu--horizontal {
  border-bottom: unset !important;
}
.el-menu {
  background-color: unset !important;
  .is-active {
    color: #409eff !important;
  }
  .el-menu-item {
    height: 30px !important;
    line-height: 30px !important;
    &:hover {
      background-color: unset !important;
      color: #409eff !important;
    }
    &:focus {
      background-color: unset !important;
      color: #409eff !important;
    }
  }
}
</style>
