<template>
  <div class="homeinfo_area">
    <div class="info_box">
      <div class="data">
        <img src="@/assets/images/homeInfo1.svg" class="image" />
      </div>
      <div class="data">
        <h1>{{ $t("home.homeInfoTitle1") }}</h1>
      </div>
      <div class="data">
        <div class="text">
          {{ $t("home.homeInfoText1") }}
        </div>
      </div>
    </div>
    <div class="info_box">
      <div class="data">
        <img src="@/assets/images/homeInfo2.svg" class="image" />
      </div>
      <div class="data">
        <h1>{{ $t("home.homeInfoTitle2") }}</h1>
      </div>
      <div class="data">
        <div class="text">
          {{ $t("home.homeInfoText2") }}
        </div>
      </div>
    </div>
    <div class="info_box">
      <div class="data">
        <img src="@/assets/images/homeInfo3.svg" class="image" />
      </div>
      <div class="data">
        <h1>{{ $t("home.homeInfoTitle3") }}</h1>
      </div>
      <div class="data">
        <div class="text">
          {{ $t("home.homeInfoText3") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeInfoComponent",
};
</script>

<style lang="scss" scoped>
.homeinfo_area {
  @include flex-set();
  width: 100%;
  display: none;

  @include pc() {
    display: flex;
  }

  .info_box {
    width: 100%;
    margin: 20px;
    .data {
      width: 100%;
      h1 {
        margin: 1rem 0;
        font-size: 18px;
      }
      .text {
        @include font_color("font_color9");
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
</style>
