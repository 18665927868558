<template>
  <div class="forgotPassword">
    <form class="from" @submit="(e) => e.preventDefault()">
      <button class="title" data-type="email">
        {{ $t("forgotpassword.useEmailToReset") }}
      </button>

      <input
        class="input"
        type="email"
        :placeholder="$t('forgotpassword.emailAddress')"
        v-model="email"
      />

      <div class="row">
        <input
          class="input"
          type="text"
          :placeholder="$t('forgotpassword.enterCaptcha')"
          v-model="verify"
        />
        <div class="verify" @click="refreshCode">
          <SIdentify :identifyCode="identifyCode"></SIdentify>
        </div>
      </div>

      <button class="btn" @click="submit">
        {{ $t("forgotpassword.sendResetMail") }}
      </button>
    </form>
  </div>
</template>

<script>
import SIdentify from "@/components/Sldentify/Sldentify.vue";
import { postResetEmail } from "@/services/Api.js";

export default {
  inject: ["message"],
  name: "ForgotPassword",
  components: { SIdentify },

  data() {
    return {
      email: "",
      verify: "",
      identifyCode: "1234",
      identifyCodes: "0123456789abcdwerwshdjeJKDHRJHKOOPLMKQ",
    };
  },

  mounted() {
    this.refreshCode();
  },

  methods: {
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },

    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },

    // 隨機生成驗證碼字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)];
      }
    },

    submit() {
      if (this.email !== "") {
        if (this.verify !== this.identifyCode) {
          this.message("forgotpassword.captchaDoesNotMatch", "error");
          return;
        }

        postResetEmail({ email: this.email })
          .then(() => this.message("forgotpassword.mailHasBeenSent", "success"))
          .catch(() =>
            this.message("forgotpassword.errorOccursWhenSendingMail", "error")
          );
      } else {
        this.message("register.warningEmail", "error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forgotPassword {
  @include formContent;

  .from {
    @include form;

    .title {
      @include formPageTitle;
    }

    .row {
      display: flex;
      margin-bottom: 24px;

      input {
        width: 60%;
        margin-bottom: 0;
        margin-right: 8px;
      }

      .verify {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .input {
      @include formInput;
      margin-bottom: 24px;
    }

    .btn {
      @include formBtn;
    }
  }
}
</style>
