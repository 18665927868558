<template>
  <el-carousel
    class="secondSwiperComponent"
    indicator-position="none"
    type="card"
  >
    <el-carousel-item v-for="item in banner_data" :key="item">
      <img class="img" :src="item" />
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  name: "secondSwiperComponent",
  props: {},
  components: {},
  data() {
    return {
      // banner_data: [
      //   require(`@/assets/images/swiper/${this.$i18n.locale}/1.png`),
      //   require(`@/assets/images/swiper/${this.$i18n.locale}/2.png`),
      //   require(`@/assets/images/swiper/${this.$i18n.locale}/3.png`),
      //   require(`@/assets/images/swiper/${this.$i18n.locale}/4.png`),
      // ],
    };
  },
  computed: {
    banner_data() {
      return [
        require(`@/assets/images/swiper/${this.$i18n.locale}/1.png`),
        require(`@/assets/images/swiper/${this.$i18n.locale}/2.png`),
        require(`@/assets/images/swiper/${this.$i18n.locale}/3.png`),
        require(`@/assets/images/swiper/${this.$i18n.locale}/4.png`),
      ];
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
//
.secondSwiperComponent {
  margin: 1rem;
  border-radius: 5px;
  height: 23vw;
  max-height: 151px;

  ::v-deep .el-carousel__container {
    overflow: hidden;
    height: 100%;
  }

  ::v-deep .el-carousel__mask {
    @include background_color("background_color1");
  }

  .img {
    width: 100%;
    border-radius: 5px;
  }
}
</style>
