export default {
  orderCenter: "二元期權訂單中心",
  orderno: "訂單號",
  orderTime: "訂單時間",
  predictOpenLotteryTime: "預測開獎時間",
  tradingPair: "交易對",
  type: "倉位",
  predictDirection: "預測方向",
  predictPrice: "預測金額",
  noDataMsg: "暫時沒有資料",
  odds: "賠率",
  settled: "是否結算",
  closeprice: "結算倉位",
  closeTime: "結算時間",
  yes: "已結算",
  no: "未結算",
  result: "開獎結果",
  amount: "輸贏金額",
  win: "贏",
  lose: "輸",
};
