export default {
  useEmailToReset: "使用郵箱重置密碼",
  emailAddress: "郵箱地址",
  enterCaptcha: "請輸入驗證碼",
  sendResetMail: "發送重置郵件",
  captchaDoesNotMatch: "驗證碼錯誤",
  mailHasBeenSent: "修改密碼電子郵件已發送",
  errorOccursWhenSendingMail: "發生錯誤，請聯繫客服",
  title: "重置登入密碼",
  password: "密碼",
  checkPassword: "確認密碼",
  slbmit: "提交新密碼",
  message1: "連結錯誤或已失效，請重新操作",
  message2: "請輸入密碼與確認密碼",
  message3: "兩次輸入密碼不一致",
  success: "重置密碼成功",
};
