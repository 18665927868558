export default {
  useEmailToReset: "Reset password using email",
  emailAddress: "Email Address",
  enterCaptcha: "Please Enter The Verification Code",
  sendResetMail: "Send Reset Mail",
  captchaDoesNotMatch: "CaptchaDoesNotMatch",
  mailHasBeenSent: "Password Change Email Has Been Sent",
  errorOccursWhenSendingMail:
    "An error occurred, please contact customer service",
  title: "Reset Login Password",
  password: "Password",
  checkPassword: "Confirm Password",
  slbmit: "Submit new password",
  message1: "The link is wrong or has expired, please try again",
  message2: "Please enter password and confirm password",
  message3: "The passwords entered twice are inconsistent",
  success: "Reset password successful",
};
