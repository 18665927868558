export default {
  usercenter: "Member Center",
  assetManagement: "Asset Management",
  orderCenter: "Binary Options Order Center",
  newsCenter: "News Center",
  login: "Login",
  register: "Register",
  loginOut: "Logout",
  balance: "USDT",
  locked: "Freeze Amount",
  wallet: "Wallet Information",
  charge: "Deposit",
  withDraw: "Withdraw",
  customerService: "Contact Customer Service",
  customerServiceEmail: "Customer Email",
  OnlineCustomerService: "Online Chat",
  zht: "Traditional Chinese",
  zhs: "Simplified Chinese",
  en: "English",
  logoutSuccess: "Logout successful",
  inviteCode: "Invitation Code",
  idDocumentState: "Real name status",
  unverified: "Unverified",
  verifying: "Verifying",
  verified: "Verified",
  usercheck: "Verify User",
  reject: "Rejected",
};
