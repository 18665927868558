<template>
  <div class="FourBtnComponent" v-if="coinList" v-loading="loading">
    <router-link
      class="btn"
      :to="{
        name: 'marketkline',
        params: {
          marketName: item.name,
        },
      }"
      v-for="(item, index) in coinList"
      :key="index"
      v-show="index < 4"
    >
      <div class="row">
        <span class="value">{{ item.name }}</span>
        <span
          class="value"
          :class="[{ red: gainFun(item) < 0, green: gainFun(item) > 0 }]"
        >
          <font-awesome-icon
            icon="fa-solid fa-arrow-down"
            v-if="gainFun(item) < 0"
          />
          <font-awesome-icon
            icon="fa-solid fa-arrow-up"
            v-if="gainFun(item) > 0"
          />
          {{ gainFun(item).toFixed(2) + "%" }}
        </span>
      </div>
      <div class="row">
        <span
          class="value"
          :class="[{ red: gainFun(item) < 0, green: gainFun(item) > 0 }]"
        >
          <!-- {{ fillZero(item.ticker.last) }} -->
          {{ roundDown(item.ticker.last) }}
        </span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "FourBtnComponent",
  inject: ["fillZero"],
  props: { coinList: { Object } },
  components: {},
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  methods: {
    gainFun(item) {
      return item.ticker.open == 0
        ? 0
        : parseFloat(
            ((item.ticker.last - item.ticker.open) / item.ticker.open).toFixed(
              4
            )
          );
    },
    roundDown(num, decimal = 4) {
      num = parseFloat(num);
      return (
        Math.floor((num + Number.EPSILON) * Math.pow(10, decimal)) /
        Math.pow(10, decimal)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.FourBtnComponent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem 1rem 0;
  min-height: 170px;

  .btn {
    display: flex;
    border: 1px solid;
    border-radius: 3px;
    margin-bottom: 1rem;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 8px;
    width: calc(50% - 0.5rem);
    @include font_color("font_color3");
    @include border_color("border_color1");

    &:hover {
      @include background_color("background_color6");
    }

    .row {
      display: flex;
      align-items: center;

      &:last-of-type {
        margin-top: 5px;
        justify-content: flex-end;

        span {
          font-size: 16px;
        }
      }

      span {
        font-size: 12px;
        font-weight: 600;

        &:first-of-type {
          margin-right: 4px;
        }
      }
    }

    .green {
      @include font_color("font_color1");
    }

    .red {
      @include font_color("font_color2");
    }
  }
  ::v-deep .el-loading-mask {
    @include background_color("background_color10");
  }
}
</style>
