export default {
  orderCenter: "二元期权订单中心",
  orderno: "订单号",
  orderTime: "订单时间",
  predictOpenLotteryTime: "预测开奖时间",
  tradingPair: "交易对",
  type: "仓位",
  predictDirection: "预测方向",
  predictPrice: "预测金额",
  noDataMsg: "暂时没有资料",
  odds: "赔率",
  settled: "是否结算",
  closeprice: "结算仓位",
  closeTime: "结算时间",
  yes: "已结算",
  no: "未结算",
  result: "开奖结果",
  amount: "输赢金额",
  win: "赢",
  lose: "输",
};
