<template>
  <div class="TwoFactorView">
    二次驗證
    <!-- <TestComponent /> -->
  </div>
</template>

<script>
// import TestComponent from "@/components/TestComponent.vue";

import { twoFactors, twoFactorSettings } from "@/services/Api.js";

export default {
  name: "TwoFactorView",
  components: {
    // TestComponent,
  },
  data() {
    return {};
  },
  mounted() {
    this.twoFactorsFun();
    this.twoFactorSettingsFun();
  },
  methods: {
    twoFactorsFun() {
      twoFactors()
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    twoFactorSettingsFun() {
      // :verify_closed, :single_verify, :mfa_verify
      // 表示关闭二步验证、只需一个二步验证和必须全部二步验证
      // const postdata = "verify_closed";
      twoFactorSettings()
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
