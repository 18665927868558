<template>
  <div class="content">
    <div class="from" v-loading="loading">
      <button class="title" data-type="email">{{ $t("login.title") }}</button>

      <input
        class="input"
        type="email"
        :placeholder="$t('login.email')"
        v-model="form.email"
        @keyup.enter="login"
      />
      <input
        class="input"
        type="password"
        :placeholder="$t('login.password')"
        v-model="form.password"
        @keyup.enter="login"
      />

      <button class="btn" @click="login">{{ $t("login.login") }}</button>

      <div class="footer">
        <router-link to="/register">
          {{ $t("login.register") }}
        </router-link>
        <router-link to="/forgotPassword">
          {{ $t("login.forgotPassword") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { postLogin } from "@/services/Api.js";
export default {
  inject: ["reload", "message"],
  name: "LoginView",
  data() {
    return {
      checked: false,
      form: {
        email: "",
        password: "",
        locale: "en",
      },
      loading: false,
    };
  },
  methods: {
    login() {
      if (this.form.email == "" || this.form.password == "") {
        this.message("login.warning", "warning");
        return;
      }
      this.loading = true;

      let locale = window.localStorage.getItem("locale");
      locale = locale == "zht" ? "zh-TW" : locale == "zhs" ? "zh-CN" : "en";

      this.form.locale = locale;

      postLogin(this.form)
        .then((res) => {
          const { data } = res;

          if (data.success) {
            this.message("login.success", "success");
            this.$router.push("/").catch(() => {});
            this.reload();
          } else {
            this.message(data.errors[0].message, "warning");
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  @include formContent;

  .from {
    @include form;

    .title {
      @include formPageTitle;
    }

    .input {
      @include formInput;
      margin-bottom: 24px;
    }

    label {
      color: #000;
      display: flex;
      font-size: 16px;
      text-align: left;
    }

    .btn {
      @include formBtn;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      a {
        @include font_color("font_color5");

        &:last-of-type {
          @include font_color("font_color8");
        }
      }
    }
  }

  ::v-deep .el-loading-mask {
    @include background_color("background_color11");
  }
}
</style>
