import Vue from "vue";
import VueI18n from "vue-i18n";

import eng from "./lang/eng";
import zhs from "./lang/zhs";
import zht from "./lang/zht";
import ja from "./lang/ja";

Vue.use(VueI18n);

/* global glob */

// 取得預設語系
if (!window.localStorage.getItem("locale")) {
  window.localStorage.setItem(
    "locale",
    glob.domainType == "vcex" ? "ja" : "eng"
  );
}

const locale = window.localStorage.getItem("locale");

export const i18n = new VueI18n({
  locale,
  fallbackLocale: glob.domainType == "vcex" ? "ja" : "eng",
  messages: { eng, zht, zhs, ja },
  silentTranslationWarn: true,
});

export default i18n;
