<template>
  <div class="footer_area">
    <div class="contact_box" v-if="domainType == 'mtf'">
      <div class="title">
        {{ $t("footer.contact") }}:
        <span class="gray">
          <div class="small_title">{{ $t("footer.contactMail") }}:</div>
          <a
            class="link"
            href="mailto:mtftoken@gmail.com?subject = Feedback&body = Message"
          >
            mtftoken@gmail.com
          </a>
        </span>
        <span class="gray">
          <div class="small_title">Telegram:</div>
          <a
            style="color: #409eff; text-decoration: underline"
            href="https://t.me/mtfcustomerservice"
            target="_blank"
            >@mtfcustomerservice</a
          >
        </span>
        <span class="gray">
          <div class="small_title">
            {{ $t("navbar.OnlineCustomerService") }}:
          </div>
          <div class="link" @click="OpenLiveChat()">LiveChat</div>
        </span>
      </div>
    </div>

    <div class="contact_box" v-if="domainType == 'vcex'">
      <div class="title">
        {{ $t("footer.contact") }}:
        <span class="gray">
          <div class="small_title">{{ $t("footer.contactMail") }}:</div>
          <a
            class="link"
            href="mailto:customer@vcexbank.com?subject = Feedback&body = Message"
          >
            customer@vcexbank.com
          </a>
        </span>
        <!-- <span class="gray">
          <div class="small_title">Telegram:</div>
          <a
            style="color: #409eff; text-decoration: underline"
            href="https://t.me/mtfcustomerservice"
            target="_blank"
            >@mtfcustomerservice</a
          >
        </span> -->
      </div>
    </div>

    <div class="contact_box" v-if="domainType == 'wdc'">
      <div class="title">
        {{ $t("footer.contact") }}:
        <span class="gray">
          <div class="small_title">{{ $t("footer.contactMail") }}:</div>
          <a
            class="link"
            href="mailto:customer@wdcbanker.com?subject = Feedback&body = Message"
          >
            customer@wdcbanker.com
          </a>
        </span>
      </div>
    </div>

    <div class="footer_text" v-if="domainType == 'mtf'">2022 MTF Token</div>
    <!-- <div class="footer_text" v-if="domainType == 'vcex'">2022 VCEX Bank</div> -->
    <div class="footer_text" v-if="domainType == 'vcex'">
      2017，VCEXBANK CO., LTD. Registered in SAMOA
    </div>
    <div class="footer_text" v-if="domainType == 'wdc'">
      2022，WDCBANKER CO., LTD. Registered in SAMOA
    </div>
  </div>
</template>

<script>
export default {
  inject: ["OpenLiveChat"],
  name: "FooterComponent",
  data() {
    return {
      activeNames: 1,
      /* global glob */
      // domainType: "vcex", // mtf/vcex
      domainType: glob.domainType, // mtf/vcex
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer_area {
  width: 100%;
  background-image: url("~@/assets/images/Rectangle.png");
  background-size: 1920px 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;

  @include flex-set();
  .collapse-box {
    width: 95%;
  }
  .contact_box {
    width: 95%;

    .title {
      width: 100%;
      font-size: 14px;
      margin: 20px 0 10px;
      display: flex;
      flex-direction: column;

      span {
        margin-top: 8px;
      }
    }

    .gray {
      @include font_color("font_color6");
      width: 100%;
      @include flex-set();
      justify-content: left;
      margin: 0 auto;
      .small_title {
        width: 45%;
        text-align: right;
        margin-right: 5px;
      }
      .link {
        cursor: pointer;
      }
    }
  }
  .footer_text {
    font-size: 12px;
    margin: 10px 0 20px;
  }
}
</style>
