export default {
  usercenter: "會員中心",
  assetManagement: "資產管理",
  orderCenter: "二元期權訂單中心",
  newsCenter: "新聞中心",
  login: "登入",
  register: "註冊",
  loginOut: "登出",
  balance: "USDT",
  locked: "凍結金額",
  wallet: "錢包資訊",
  charge: "充值",
  withDraw: "提幣",
  customerService: "聯繫客服",
  customerServiceEmail: "客服信箱",
  OnlineCustomerService: "線上客服",
  zht: "繁體中文",
  zhs: "简体中文",
  en: "英文",
  logoutSuccess: "登出成功",
  inviteCode: "邀請碼",
  idDocumentState: "實名狀態",
  unverified: "未認證",
  verifying: "認證中",
  verified: "完成認證",
  usercheck: "實名認證",
  reject: "已駁回",
};
