<template>
  <div
    class="marquee_box"
    @mouseover="paused == true"
    @mouseleave="paused == false"
  >
    <font-awesome-icon class="btn" icon="fa-solid fa-bullhorn" />
    <marquee-text class="marquee" :duration="20" :repeat="10" :paused="paused">
      <div>{{ $t("home.marqueeText1") }}</div>
    </marquee-text>
  </div>
</template>

<script>
export default {
  name: "NewsCrawlComponent",
  props: {},
  components: {},
  data() {
    return {
      paused: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.marquee_box {
  @include background_color("background_color4");
  border-radius: 100px;
  margin: 0 1rem 1rem;
  padding: 10px;
  display: flex;
  font-size: 12px;

  .marquee {
    margin-left: 5px;
    div {
      padding-right: 50px;
    }
  }
}
</style>
