import common from "./zhs/common";
import navbar from "./zhs/navbar";
import home from "./zhs/home";
import footer from "./zhs/footer";
import kline from "./zhs/kline";
import charge from "./zhs/charge";
import withdraw from "./zhs/withdraw";
import order from "./zhs/order";
import forgotpassword from "./zhs/forgotpassword";
import login from "./zhs/login";
import register from "./zhs/register";
import usercheck from "./zhs/usercheck";
import whitePaper from "./zhs/whitePaper";

export default {
  common: {
    ...common,
  },
  navbar: {
    ...navbar,
  },
  home: {
    ...home,
  },
  footer: {
    ...footer,
  },
  kline: {
    ...kline,
  },
  charge: {
    ...charge,
  },
  withdraw: {
    ...withdraw,
  },
  order: {
    ...order,
  },
  forgotpassword: {
    ...forgotpassword,
  },
  login: {
    ...login,
  },
  register: {
    ...register,
  },
  usercheck: {
    ...usercheck,
  },
  whitePaper: {
    ...whitePaper,
  },
};
