export default {
  title: "登入",
  email: "郵箱地址或手機號碼(含區號)",
  password: "登入密碼",
  login: "登錄",
  register: "註冊",
  forgotPassword: "忘記密碼",
  warning: "請輸入賬號與密碼",
  success: "登入成功",
};
