export default {
  title: "Register",
  emailTitle: "Email",
  email: "Email Address",
  checkEmail: "Please Input The Correct Email Address",
  pass: "Password",
  password: "Login Password",
  warninPassword: "Please Enter Password",
  checkPassword: "Confirm Password",
  againCheckPassword: "Please Enter the password again",
  checked:
    "I agree to the User Agreement and Privacy Policy. I am also not a US citizen or US taxpayer.",
  register: "Register",
  login: "Login",
  success: "Registration Succeeded",
  warning: "Please Agree To The Service Agreement And Privacy Policy",
  verify: "Verification Code",
  invitationCode: "Invitation Code",
  invitation: "Invitation code(optional)",
  warningInvitationCode: "Please Enter The Invitation Code",
  warningEmail: "Please Input Your Email",
  phone: "Your Phone Number",
  warningPhone: "Please Enter The Phone Number",
  sendVerify:
    "Successful Sending Verification Code, Please Pay Attention To Check",
  warningPassword: "The passwords Enter Twice Do Not Match!",
  warninEmailVerify: "Please Eter Email Verification Code",
  emailVerify: "Email Verification Code",
  sendEmailVerify: "Send Code",
  phoneAreaCode: "Phone Country Code",
  addPhoneAreaCode: "Please Enter The Phone Country Code",
  areaCode: "Country Code:",
  cancel: "Cancel",
};
