export default {
  title: "使用郵箱註冊",
  emailTitle: "郵箱",
  email: "郵箱地址",
  checkEmail: "請輸入正確的郵箱地址",
  pass: "密碼",
  password: "登入密碼",
  warninPassword: "請輸入密碼",
  checkPassword: "確認密碼",
  againCheckPassword: "請再次輸入密碼",
  checked: "我同意用戶協議和隱私政策。我也不是美國公民或者美國納稅人。",
  register: "註冊",
  login: "登入",
  success: "註冊成功",
  warning: "請同意服務協議和隱私政策",
  verify: "驗證碼",
  invitationCode: "邀請碼",
  invitation: "邀請碼(可選)",
  warningInvitationCode: "請輸入邀請碼",
  warningEmail: "請輸入郵箱",
  phone: "手機號碼",
  warningPhone: "請輸入手機號碼",
  sendVerify: "發送驗證碼成功,請注意查收",
  warningPassword: "兩次輸入密碼不一致!",
  emailVerify: "郵箱驗證碼",
  warninEmailVerify: "請輸入郵箱認證碼",
  sendEmailVerify: "發送郵箱驗證碼",
  phoneAreaCode: "手機區號",
  addPhoneAreaCode: "請輸入手機區號",
  areaCode: "區號:",
  cancel: "取消",
};
