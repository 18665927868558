export default {
  useEmailToReset: "使用邮箱重置密码",
  emailAddress: "邮箱地址",
  enterCaptcha: "请输入验证码",
  sendResetMail: "发送重置邮件",
  captchaDoesNotMatch: "验证码错误",
  mailHasBeenSent: "修改密码电子邮件已发送",
  errorOccursWhenSendingMail: "发生错误，请联系客服",
  title: "重置登入密码",
  password: "密码",
  checkPassword: "确认密码",
  slbmit: "提交新密码",
  message1: "连结错误或已失效，请重新操作",
  message2: "请输入密码与确认密码",
  message3: "两次输入密码不一致",
  success: "重置密码成功",
};
