var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"BuyAndSellComponent"},[_c('div',{staticClass:"mask",on:{"click":function($event){return _vm.switchFun()}}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"popout",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("kline.tradeTitle1")))]),_c('div',{staticClass:"label"},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(_vm.$t("kline.accountBalance")))]),(_vm.betForm.type == 'order_bids')?_c('div',{staticClass:"right"},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.usdtBalance))]),_c('span',{staticClass:"coin_name"},[_vm._v(" "+_vm._s(_vm.coinData.name.split("/")[1]))])]):_c('div',{staticClass:"right"},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.thisAccountsData))]),_c('span',{staticClass:"coin_name"},[_vm._v(" "+_vm._s(_vm.coinData.name.split("/")[0]))])])]),_c('div',{staticClass:"buy_type"},[_c('div',{staticClass:"btn left",class:[{ green: _vm.betForm.type == 'order_bids' }],on:{"click":function($event){_vm.betForm.type = 'order_bids'}}},[_vm._v(" "+_vm._s(_vm.$t("kline.buy"))+" ")]),_c('div',{staticClass:"btn",class:[{ red: _vm.betForm.type == 'order_asks' }],on:{"click":function($event){_vm.betForm.type = 'order_asks'}}},[_vm._v(" "+_vm._s(_vm.$t("kline.sell"))+" ")])]),_c('div',{staticClass:"label ord_type"},[_c('div',{staticClass:"type_label border_bottom_none",class:[
            {
              border_bottom: _vm.ord_type == 'limit',
              blue_color: _vm.ord_type == 'limit',
            } ],on:{"click":function($event){_vm.ord_type = 'limit'}}},[_vm._v(" "+_vm._s(_vm.$t("kline.limit"))+" ")]),_c('div',{staticClass:"type_label border_bottom_none",class:[
            {
              border_bottom: _vm.ord_type == 'market',
              blue_color: _vm.ord_type == 'market',
            } ],on:{"click":function($event){(_vm.ord_type = 'market'),
              (_vm.betForm.price = _vm.coinData.ticker.last),
              (_vm.totalCount = _vm.accMul(_vm.betForm.price, _vm.betForm.origin_volume))}}},[_vm._v(" "+_vm._s(_vm.$t("kline.market"))+" ")])]),_c('div',{staticClass:"label border_bottom"},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(_vm.$t("kline.price")))]),(_vm.ord_type == 'limit')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.betForm.price),expression:"betForm.price"}],staticClass:"amount",attrs:{"placeholder":_vm.$t('kline.pleaseInputPrice2'),"type":"number"},domProps:{"value":(_vm.betForm.price)},on:{"keyup":function($event){return _vm.priceKeyup(_vm.betForm.price)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.betForm, "price", $event.target.value)}}}):_c('div',{staticClass:"market"},[_vm._v(_vm._s(_vm.coinData.ticker.last))]),_c('div',{staticClass:"right coin_name"},[_vm._v(" "+_vm._s(_vm.coinData.name.split("/")[1])+" ")])]),_c('div',{staticClass:"msg"}),(_vm.betForm.type == 'order_bids')?_c('div',{staticClass:"label border_bottom",class:[
          {
            red_border_bottom:
              _vm.amountTooBigMsg && _vm.betForm.type == 'order_bids' && !_vm.loading,
          } ]},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(_vm.$t("kline.totalCount")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.totalCount),expression:"totalCount"}],staticClass:"amount",attrs:{"placeholder":_vm.$t('kline.pleaseInputPrice'),"type":"number"},domProps:{"value":(_vm.totalCount)},on:{"keyup":function($event){return _vm.totalKeyup(_vm.totalCount)},"input":function($event){if($event.target.composing){ return; }_vm.totalCount=$event.target.value}}}),_c('div',{staticClass:"right coin_name"},[_vm._v(" "+_vm._s(_vm.coinData.name.split("/")[1])+" ")])]):_vm._e(),(_vm.betForm.type == 'order_bids')?_c('div',{staticClass:"msg"},[_vm._v(" "+_vm._s(_vm.amountTooBigMsg && _vm.betForm.type == "order_bids" && !_vm.loading ? _vm.$t("kline.insufficientBalance") : "")+" ")]):_vm._e(),_c('div',{staticClass:"label border_bottom",class:[
          {
            red_border_bottom:
              _vm.volumeTooBigMsg && _vm.betForm.type == 'order_asks' && !_vm.loading,
          } ]},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(_vm.$t("kline.volume")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.betForm.origin_volume),expression:"betForm.origin_volume"}],staticClass:"amount",attrs:{"placeholder":_vm.$t('kline.pleaseInputVolume'),"type":"number"},domProps:{"value":(_vm.betForm.origin_volume)},on:{"keyup":function($event){return _vm.volumeKeyup(_vm.betForm.origin_volume)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.betForm, "origin_volume", $event.target.value)}}}),_c('div',{staticClass:"right coin_name"},[_vm._v(" "+_vm._s(_vm.coinData.name.split("/")[0])+" ")])]),_c('div',{staticClass:"msg"},[_vm._v(" "+_vm._s(_vm.volumeTooBigMsg && _vm.betForm.type == "order_asks" && !_vm.loading ? _vm.$t("kline.notEnough") : "")+" ")]),_c('div',{staticClass:"amount_box"},_vm._l((_vm.amountList),function(item,index){return _c('div',{key:index,staticClass:"amount_btn",on:{"click":function($event){return _vm.addAmount(item)}}},[_vm._v(" "+_vm._s(item)+"% ")])}),0),(_vm.betForm.type == 'order_asks')?_c('div',{staticClass:"label border_bottom",class:[
          {
            red_border_bottom:
              _vm.amountTooBigMsg && _vm.betForm.type == 'order_bids' && !_vm.loading,
          } ]},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(_vm.$t("kline.totalCount")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.totalCount),expression:"totalCount"}],staticClass:"amount",attrs:{"placeholder":_vm.$t('kline.pleaseInputPrice'),"type":"number"},domProps:{"value":(_vm.totalCount)},on:{"keyup":function($event){return _vm.totalKeyup(_vm.totalCount)},"input":function($event){if($event.target.composing){ return; }_vm.totalCount=$event.target.value}}}),_c('div',{staticClass:"right coin_name"},[_vm._v(" "+_vm._s(_vm.coinData.name.split("/")[1])+" ")])]):_vm._e(),(_vm.betForm.type == 'order_asks')?_c('div',{staticClass:"msg"},[_vm._v(" "+_vm._s(_vm.amountTooBigMsg && _vm.betForm.type == "order_bids" && !_vm.loading ? _vm.$t("kline.insufficientBalance") : "")+" ")]):_vm._e(),_c('div',{staticClass:"button_box"},[_c('div',{staticClass:"blue button",on:{"click":function($event){return _vm.doubleCheckFun()}}},[_vm._v(" "+_vm._s(_vm.$t("kline.sendOrder"))+" ")])]),_c('div',{staticClass:"button_box"},[_c('div',{staticClass:"pink button",on:{"click":function($event){return _vm.switchFun()}}},[_vm._v(" "+_vm._s(_vm.$t("kline.cancel"))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }