<template>
  <div class="PlaceOrderComponent">
    <!-- <div class="switch_btn" @click="switchFun"></div> -->
    <!-- 買入賣出 -->
    <div
      class="button_box outside_btn"
      :style="{ width: domainType == 'mtf' ? '50%' : '100%' }"
    >
      <div class="background_image_1 button yellow" @click="placeOrderType = 1">
        <img src="@/assets/icons/place_order1.svg" class="image" />
        <div class="text">{{ $t("kline.tradeTitle1") }}</div>
      </div>
    </div>
    <!-- 買漲買跌 -->
    <div class="button_box outside_btn" v-if="domainType == 'mtf'">
      <div class="background_image_2 button purple" @click="placeOrderType = 2">
        <img src="@/assets/icons/place_order2.svg" class="image" />
        <div class="text">{{ $t("kline.tradeTitle2") }}</div>
      </div>
    </div>
    <BuyAndSellComponent
      @handleSwitch="handleSwitch"
      v-if="marketId && placeOrderType == 1"
      :marketId="marketId"
      :coinData="coinData"
    />
    <LongAndShortComponent
      @handleSwitch="handleSwitch"
      v-if="marketId && placeOrderType == 2 && domainType == 'mtf'"
      :marketId="marketId"
      :coinData="coinData"
    />
  </div>
</template>

<script>
import BuyAndSellComponent from "@/components/MarketKline/BuyAndSellComponent.vue";
import LongAndShortComponent from "@/components/MarketKline/LongAndShortComponent.vue";

export default {
  inject: ["message"],
  name: "PlaceOrderComponent",
  components: {
    BuyAndSellComponent,
    LongAndShortComponent,
  },
  props: {
    marketId: { String },
    coinData: { Object },
  },
  watch: {
    "$store.getters.getCurrentSelectOrderData"() {
      this.placeOrderType = 1;
    },
    // placeOrderType(value) {
    //   console.log(value, this.marketId);
    // },
  },
  data() {
    return {
      placeOrderType: 0, // 0為全部關閉
      /* global glob */
      // domainType: "vcex", // mtf/vcex
      domainType: glob.domainType, // mtf/vcex
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   var test = this.$store.getters.getGlob.domainType;
    //   console.log("this.$store.getters.getGlob.domainType", test);
    // });
  },
  methods: {
    handleSwitch() {
      this.placeOrderType = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.PlaceOrderComponent {
  @include flex-set();
  width: 100vw;
  max-width: 660px;
  padding: 10px;
  position: fixed;
  bottom: 0;
  z-index: 2000;
  @include background_color("background_color3");
  border-top: 1px solid;
  @include border_color("border_color1");

  .button_box {
    width: 50%;
    padding: 5px;
    margin-bottom: 10px;
  }
  .button {
    @include flex-set();
    height: 38px;
    border-radius: 4px;
    line-height: 38px;
    text-align: center;
    @include box_shadow("shadow_color1");
    @include text_shadow("shadow_color1");
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
    }
    .image {
      width: 25px;
      margin-left: 10px;
    }
    .text {
      margin: 0 10px;
    }

    @media screen and (max-width: 370px) {
      .image {
        display: none;
      }
    }
  }
  // .background_image_1 {
  //   // @include background_image("background_color12", "background_color13");
  //   @include background_color("background_color12");
  // }
  // .background_image_2 {
  //   // @include background_image("background_color7", "background_color8");
  //   @include background_color("background_color7");
  // }
  .green {
    @include background_color("background_color7");
  }
  .red {
    @include background_color("background_color8");
  }
  .yellow {
    @include background_color("background_color12");
  }
  .purple {
    @include background_color("background_color13");
  }
  .blue {
    @include background_color("background_color14");
  }
  .pink {
    @include background_color("background_color15");
  }
}
</style>
