export default {
  title: "Deposit",
  copySuccess: "Copy succeeded",
  copyFail: "Copy failed",
  chargeUSDTIntoThisWallet:
    "Please send the USDT you want to deposit to the following address,",
  chargeIntoThisWallet1: "Please send the ",
  chargeIntoThisWallet2: " you want to deposit to the following address,",
  contactUsToHandle:
    "Please contact customer service and we will handle it as soon as possible.",
  coinWalletAddress: "Coin Wallet Address",
  copyCoinWalletAddress: "Copy Address",
  gettVerifyFailTitle: "mesaage",
  gettVerifyFail:
    "The deposit function is temporarily unavailable, please try again later",
  showQRcode: "View QR code",
  charge: "Deposit",
  withdraw: "Withdraw",
  msg1: "This function is temporarily not supported",
  msg2: "To operate this function, you must complete real-name authentication first",
  walletTitle: "Wallet Information",
  currency: "Currency",
  balance: "Balance",
  usdtType: "Select USDT type for deposit",
  usdtType2: "Select USDT type for withdrawal",
  chargeRecord: "Charge Record",
  withdrawRecord: "Withdrawal record",
  noData: "Currently no data",
  id: "Number",
  amount: "Amount",
  fee: "Fees",
  created_at: "Submit time",
  updated_at: "Operation time",
  state: "State",
  charge_fund_uid: "Deposit Address",
  withdraw_fund_uid: "Withdrawal address",
  cancel_reason: "Remarks",
  submitting: "Accepting",
  canceled: "Cancelled",
  cancelled: "Cancelled",
  submitted: "Pending",
  rejected: "rejected",
  accepted: "Agreed",
  checked: "Checked",
  warning: "Warning",
  suspect: "Follow",
  processing: "Processing",
  faulted: "Error",
  done: "Done",
  failed: "Failed",
  refunded: "Refunded",
  error_msg: "Please confirm the format of the wallet address is correct",
  title1: "Currency",
  title2: "Balance",
  title3: "equal to",
};
