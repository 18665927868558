<template>
  <div class="content">
    <el-form
      :model="ruleForm"
      status-icon
      :rules="userFormRules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm form"
      v-loading="loading"
    >
      <div id="useEmail" class="title">
        {{ $t("usercheck.title") }}
      </div>

      <el-form-item prop="country" :label="$t('usercheck.countryOrArea') + ':'">
        <el-input
          @focus="canEdit ? (open = !open) : null"
          type="text"
          :placeholder="$t('usercheck.addCountryOrAreaCode')"
          v-model="checkCountry"
          :disabled="canEdit ? false : true"
          :class="[ruleForm.country && 'country_text']"
        />
      </el-form-item>

      <VueCountryIntl
        class="country"
        schema="modal"
        type="country"
        v-model="ruleForm.country"
        :searchInputPlaceholder="$t('usercheck.addCountryOrAreaCode')"
        :visible.sync="open"
        :cancelText="$t('usercheck.cancel')"
      >
      </VueCountryIntl>

      <el-form-item prop="name" :label="$t('usercheck.realName') + ':'">
        <el-input
          type="text"
          :placeholder="$t('usercheck.realName')"
          v-model="ruleForm.name"
          autocomplete="autocomplete"
          :disabled="canEdit ? false : true"
        />
      </el-form-item>

      <el-form-item
        prop="documentType"
        :label="$t('usercheck.documentType') + ':'"
      >
        <el-select
          v-model="ruleForm.documentType"
          :placeholder="$t('usercheck.documentType')"
          :disabled="canEdit ? false : true"
        >
          <el-option :label="$t('usercheck.IDcard')" value="id_card">
          </el-option>
          <el-option :label="$t('usercheck.passport')" value="passport">
          </el-option>
          <el-option
            :label="$t('usercheck.driversLicense')"
            value="driver_license"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        prop="certificateNumber"
        :label="$t('usercheck.certificateNumber') + ':'"
      >
        <el-input
          type="text"
          :placeholder="$t('usercheck.certificateNumber')"
          v-model="ruleForm.certificateNumber"
          autocomplete="autocomplete"
          :disabled="canEdit ? false : true"
        />
      </el-form-item>

      <el-form-item prop="frontImageUrl" :label="$t('usercheck.front') + ':'">
        <div class="imagebox">
          <el-checkbox-group
            v-show="false"
            v-model="ruleForm.frontImageUrl"
          ></el-checkbox-group>
          <el-upload
            class="upload"
            action="#"
            accept="image/jpeg, image/png, image/jpg"
            :multiple="false"
            :auto-upload="false"
            :on-change="onFrontImageChange"
            :file-list="frontImageFileList"
            :show-file-list="false"
            v-if="canEdit"
          >
            <el-button
              v-if="ruleForm.frontImageUrl == null"
              size="small"
              type="primary"
            >
              upload
            </el-button>
          </el-upload>
          <img
            :src="
              ruleForm.frontImageUrl != null
                ? ruleForm.frontImageUrl
                : frontImage
            "
            class="image"
          />
          <font-awesome-icon
            class="icon"
            v-if="ruleForm.frontImageUrl != null && canEdit"
            icon="fa-solid fa-xmark"
            @click="clearImage(1)"
          />
        </div>
      </el-form-item>
      <el-form-item prop="coverImageUrl" :label="$t('usercheck.Back') + ':'">
        <div class="imagebox">
          <el-checkbox-group
            v-show="false"
            v-model="ruleForm.coverImageUrl"
          ></el-checkbox-group>
          <el-upload
            class="upload"
            action="#"
            accept="image/jpeg, image/png, image/jpg"
            :multiple="false"
            :auto-upload="false"
            :on-change="onCoverImageChange"
            :file-list="coverImageFileList"
            :show-file-list="false"
            v-if="canEdit"
          >
            <el-button
              v-if="ruleForm.coverImageUrl == null"
              size="small"
              type="primary"
            >
              upload
            </el-button>
          </el-upload>
          <img
            :src="
              ruleForm.coverImageUrl != null
                ? ruleForm.coverImageUrl
                : coverImage
            "
            class="image"
          />
          <font-awesome-icon
            class="icon"
            v-if="ruleForm.coverImageUrl != null && canEdit"
            icon="fa-solid fa-xmark"
            @click="clearImage(2)"
          />
        </div>
      </el-form-item>
      <el-form-item
        prop="carryImageUrl"
        :label="$t('usercheck.handHeld') + ':'"
      >
        <div class="imagebox">
          <el-checkbox-group
            v-show="false"
            v-model="ruleForm.carryImageUrl"
          ></el-checkbox-group>
          <el-upload
            class="upload"
            action="#"
            accept="image/jpeg, image/png, image/jpg"
            :multiple="false"
            :auto-upload="false"
            :on-change="onCarryImageChange"
            :file-list="carryImageFileList"
            :show-file-list="false"
            v-if="canEdit"
          >
            <el-button
              v-if="ruleForm.carryImageUrl == null"
              size="small"
              type="primary"
            >
              upload
            </el-button>
          </el-upload>
          <img
            :src="
              ruleForm.carryImageUrl != null
                ? ruleForm.carryImageUrl
                : carryImage
            "
            class="image"
          />
          <font-awesome-icon
            class="icon"
            v-if="ruleForm.carryImageUrl != null && canEdit"
            icon="fa-solid fa-xmark"
            @click="clearImage(3)"
          />
        </div>
      </el-form-item>

      <div class="bottomText">
        {{ $t("usercheck.msg_2") }}
      </div>

      <div
        class="rejectText"
        v-if="
          canEdit &&
          $store.getters.getApiSettingsData &&
          $store.getters.getApiSettingsData.id_document_reason != null
        "
      >
        {{ $t("usercheck.rejectReason") }}:&nbsp;{{
          $store.getters.getApiSettingsData.id_document_reason
        }}
      </div>

      <div class="verifying" v-if="!canEdit && verification == 'verifying'">
        {{ verificationStatus }}
      </div>

      <div class="verified" v-if="!canEdit && verification == 'verified'">
        {{ verificationStatus }}
      </div>

      <el-button class="btn" @click="validateForm" v-if="canEdit">
        {{ $t("usercheck.confirm") }}
      </el-button>
    </el-form>
  </div>
</template>

<script>
import { identityVerifyImage } from "@/services/Api.js";
import { identityVerify } from "@/services/Api.js";
import { getIdentityVerifyData } from "@/services/Api.js";

export default {
  name: "UserCheckView",
  inject: ["reload", "message"],
  data() {
    return {
      checked: false,
      validation: Date.now(), //刷新驗證碼圖片
      open: false, //開關手機區碼組件
      loading: true,
      canEdit: false,
      frontImage: require("@/assets/images/UserCheck/front.png"),
      coverImage: require("@/assets/images/UserCheck/cover.png"),
      carryImage: require("@/assets/images/UserCheck/carry.png"),
      frontImageFileList: [],
      coverImageFileList: [],
      carryImageFileList: [],

      ruleForm: {
        country: "", //國家
        name: "", //真實姓名
        documentType: "", //證件類型
        certificateNumber: "", //證件號碼
        frontImageUrl: null,
        coverImageUrl: null,
        carryImageUrl: null,
      },
      timer: null,
      verification: null,
      firstTimeCheck: false,
      imageCheck: 0,
    };
  },
  computed: {
    checkCountry() {
      return this.ruleForm.country
        ? this.ruleForm.country
        : this.$t("usercheck.addCountryOrAreaCode");
    },

    /** 驗證表單 */
    userFormRules() {
      const rules = {
        country: [
          {
            required: true,
            message: this.$t("usercheck.addCountryOrAreaCode"),
            trigger: ["blur", "change"],
          },
        ],
        name: [
          {
            required: true,
            message: this.$t("usercheck.warningRealName"),
            trigger: ["blur", "change"],
          },
        ],
        documentType: [
          {
            required: true,
            message: this.$t("usercheck.warningDocumentType"),
            trigger: ["blur", "change"],
          },
        ],
        certificateNumber: [
          {
            required: true,
            message: this.$t("usercheck.warningCertificateNumber"),
            trigger: ["blur", "change"],
          },
        ],
        frontImageUrl: [
          {
            required: true,
            message: this.$t("usercheck.warningFrontImage"),
            trigger: ["blur", "change"],
          },
        ],
        coverImageUrl: [
          {
            required: true,
            message: this.$t("usercheck.warningCoverImage"),
            trigger: ["blur", "change"],
          },
        ],
        carryImageUrl: [
          {
            required: true,
            message: this.$t("usercheck.warningCarryImage"),
            trigger: ["blur", "change"],
          },
        ],
      };
      return rules;
    },
    verificationStatus() {
      if (this.verification == "verifying") {
        return this.$t("usercheck.verifying");
      }
      return this.$t("usercheck.verified");
    },
  },
  watch: {
    "$store.getters.getApiSettingsData"() {
      if (!this.firstTimeCheck) {
        clearTimeout(this.timer);
        this.checkVerificationState();
        this.firstTimeCheck = true;
      }
    },
  },
  mounted() {
    if (this.$store.getters.getApiSettingsData == null) {
      this.timer = setTimeout(() => {
        this.message("usercheck.pleaseReLogin", "error");
        this.$router.push({ name: "home" }).catch(() => {});
        return;
      }, 10000);
    } else {
      this.checkVerificationState();
    }
  },
  beforeDestroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    checkVerificationState() {
      if (this.$store.getters.getApiSettingsData == null) {
        this.message("usercheck.pleaseReLogin", "error");
        this.$router.push({ name: "home" }).catch(() => {});
        return;
      }
      this.verification =
        this.$store.getters.getApiSettingsData.id_document_state;
      if (
        this.$store.getters.getApiSettingsData.id_document_state == "unverified"
      ) {
        this.canEdit = true;
      }
      this.loading = false;
      this.getVerificationData();
    },
    getVerificationData() {
      getIdentityVerifyData(this.$store.getters.getApiSettingsData.member_id)
        .then((res) => {
          //   console.log(res);
          if (res.data.success == true) {
            this.ruleForm = {
              country: res.data.data.country, //國家
              name: res.data.data.name, //真實姓名
              documentType: res.data.data.id_document_type, //證件類型
              certificateNumber: res.data.data.id_document_number, //證件號碼
              frontImageUrl: res.data.data.info,
              coverImageUrl: res.data.data.cover,
              carryImageUrl: res.data.data.holding,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submitForm();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitForm() {
      var member_id = this.$store.getters.getApiSettingsData.member_id;
      if (this.$store.getters.getApiSettingsData == null) {
        this.message("usercheck.pleaseReLogin", "error");
        this.$router.push({ name: "home" }).catch(() => {});
        return;
      }
      this.imageCheck = 0;
      var postdata;
      if (this.frontImageFileList.length != 0) {
        this.imageCheck += 1;
        postdata = new FormData();
        postdata.append("field", "info");
        postdata.append("file", this.frontImageFileList[0].raw);
        var result = this.uploadImage(member_id, postdata);
        if (result == false) {
          return;
        }
      }

      if (this.coverImageFileList.length != 0) {
        this.imageCheck += 1;
        postdata = new FormData();
        postdata.append("field", "cover");
        postdata.append("file", this.coverImageFileList[0].raw);
        result = this.uploadImage(member_id, postdata);
        if (result == false) {
          return;
        }
      }

      if (this.carryImageFileList.length != 0) {
        this.imageCheck += 1;
        postdata = new FormData();
        postdata.append("field", "holding");
        postdata.append("file", this.carryImageFileList[0].raw);
        result = this.uploadImage(member_id, postdata);
        if (result == false) {
          return;
        }
      }

      if (
        this.frontImageFileList.length == 0 &&
        this.coverImageFileList.length == 0 &&
        this.carryImageFileList.length == 0
      ) {
        this.submit();
      }

      // postdata = {
      //   name: this.ruleForm.name,
      //   country: this.ruleForm.country,
      //   id_document_type: this.ruleForm.documentType,
      //   id_document_number: this.ruleForm.certificateNumber,
      // };
      // identityVerify(member_id, postdata)
      //   .then((res) => {
      //     console.log(res);
      //     if (res.data.success != true) {
      //       this.message("usercheck.errorOccursWhenUpload", "error");
      //       return;
      //     }
      //     this.message("usercheck.uploadSuccess", "success");
      //     this.$router.push({ name: "home" }).catch(() => {});
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.message("usercheck.errorOccursWhenUpload", "error");
      //     return;
      //   });
    },
    submit() {
      if (this.imageCheck != 0) {
        return;
      }

      let locale = window.localStorage.getItem("locale");
      locale = locale == "zht" ? "zh-TW" : locale == "zhs" ? "zh-CN" : "en";

      var member_id = this.$store.getters.getApiSettingsData.member_id;
      var postdata = new FormData();
      postdata = {
        name: this.ruleForm.name,
        country: this.ruleForm.country,
        id_document_type: this.ruleForm.documentType,
        id_document_number: this.ruleForm.certificateNumber,
        locale: locale,
      };
      identityVerify(member_id, postdata)
        .then((res) => {
          console.log(res);
          if (res.data.success != true) {
            // this.message("usercheck.errorOccursWhenUpload", "error");
            this.message(res.data.errors[0].message, "warning");
            return;
          }
          this.message("usercheck.uploadSuccess", "success");
          this.$router.push({ name: "home" }).catch(() => {});
        })
        .catch((err) => {
          console.log(err);
          this.message("usercheck.errorOccursWhenUpload", "error");
          return;
        });
    },
    uploadImage(member_id, postdata) {
      identityVerifyImage(member_id, postdata)
        .then((res) => {
          console.log(res);
          if (res.data.success != true) {
            this.message("usercheck.errorOccursWhenUpload", "error");
            return false;
          }
          this.imageCheck -= 1;
          this.submit();
          return true;
        })
        .catch((err) => {
          console.log(err);
          this.message("usercheck.errorOccursWhenUpload", "error");
          return false;
        });
    },
    clearImage(image) {
      switch (image) {
        case 1:
          this.ruleForm.frontImageUrl = null;
          this.frontImageFileList = [];
          break;

        case 2:
          this.ruleForm.coverImageUrl = null;
          this.coverImageFileList = [];
          break;

        case 3:
          this.ruleForm.carryImageUrl = null;
          this.carryImageFileList = [];
          break;
      }
    },
    onFrontImageChange(file, fileList) {
      if (fileList.length > 1) {
        this.frontImageFileList = [fileList[1]];
      } else {
        this.frontImageFileList = [fileList[0]];
      }

      const isIMAGE =
        this.frontImageFileList[0].raw.type === "image/jpeg" ||
        this.frontImageFileList[0].raw.type === "image/png" ||
        this.frontImageFileList[0].raw.type === "image/jpg";
      if (!isIMAGE) {
        this.message("usercheck.mustBeAnImage", "error");
        this.frontImageFileList = [];
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 < 1;
      if (!isLt5M) {
        this.message("usercheck.imageisLt5M", "error");
        this.frontImageFileList = [];
        return false;
      }

      //定義一個文件閱讀器
      var reader = new FileReader();
      //文件裝載後將其顯示在圖片預覽裏
      reader.onload = (e) => {
        //將bade64位圖片保存至數組裏供上面圖片顯示
        this.ruleForm.frontImageUrl = e.target.result;
      };
      reader.readAsDataURL(file.raw);
    },
    onCoverImageChange(file, fileList) {
      if (fileList.length > 1) {
        this.coverImageFileList = [fileList[1]];
      } else {
        this.coverImageFileList = [fileList[0]];
      }

      const isIMAGE =
        this.coverImageFileList[0].raw.type === "image/jpeg" ||
        this.coverImageFileList[0].raw.type === "image/png" ||
        this.coverImageFileList[0].raw.type === "image/jpg";
      if (!isIMAGE) {
        this.message("usercheck.mustBeAnImage", "error");
        this.coverImageFileList = [];
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.message("usercheck.imageisLt5M", "error");
        this.coverImageFileList = [];
        return false;
      }

      //定義一個文件閱讀器
      var reader = new FileReader();
      //文件裝載後將其顯示在圖片預覽裏
      reader.onload = (e) => {
        //將bade64位圖片保存至數組裏供上面圖片顯示
        this.ruleForm.coverImageUrl = e.target.result;
      };
      reader.readAsDataURL(file.raw);
    },
    onCarryImageChange(file, fileList) {
      if (fileList.length > 1) {
        this.carryImageFileList = [fileList[1]];
      } else {
        this.carryImageFileList = [fileList[0]];
      }

      const isIMAGE =
        this.carryImageFileList[0].raw.type === "image/jpeg" ||
        this.carryImageFileList[0].raw.type === "image/png" ||
        this.carryImageFileList[0].raw.type === "image/jpg";
      if (!isIMAGE) {
        this.message("usercheck.mustBeAnImage", "error");
        this.carryImageFileList = [];
        return false;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.message("usercheck.imageisLt5M", "error");
        this.carryImageFileList = [];
        return false;
      }

      //定義一個文件閱讀器
      var reader = new FileReader();
      //文件裝載後將其顯示在圖片預覽裏
      reader.onload = (e) => {
        //將bade64位圖片保存至數組裏供上面圖片顯示
        this.ruleForm.carryImageUrl = e.target.result;
      };
      reader.readAsDataURL(file.raw);
    },
  },
};
</script>

<style lang="scss" scoped>
.country {
  padding-top: 64px;

  ::v-deep .vue-country-areaCode {
    display: none;
  }
}

.content {
  @include formContent;

  ::v-deep .el-loading-mask {
    @include background_color("background_color11");
  }

  .form {
    @include form;

    .country_text {
      ::v-deep .el-input__inner {
        text-transform: uppercase;
      }
    }

    ::v-deep .el-form-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;

      .el-form-item__label {
        text-align: left;
        width: 100% !important;
        font-weight: bold;
      }

      .el-form-item__content {
        margin-left: 0 !important;
        display: flex;
        justify-content: center;
        .imagebox {
          background-size: cover;
          background-repeat: no-repeat;
          max-width: 380px;
          width: 100%;
          height: 100%;
          @include flex-set();
          position: relative;
          .upload {
            position: absolute;
            z-index: 10;
            .el-button {
              span {
                @include font_color("font_color3");
              }
              @include background_color("background_color5");
            }
          }
          .image {
            max-width: 380px;
            width: 100%;
          }
          .icon {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 24px;
            height: 24px;
            @include background_color("background_color3");
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }
    }

    .title {
      border: none;
      font-size: 20px;
      margin: 0 auto;
      border-bottom: 2px solid transparent;
      padding: 6px;
      background: transparent;
      margin-bottom: 24px;
      @include border_color("border_color4");
      @include font_color("font_color4");
      width: fit-content;
    }

    ::v-deep .el-select {
      width: 100%;
    }

    .input {
      ::v-deep .el-input__inner {
        width: 100%;
        border: 1px solid;
        @include border_color("border_color5");
        padding: 12px;
        outline: none;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        font-size: 16px;
      }
    }

    label {
      @include font_color("font_color8");
      display: flex;
      font-size: 14px;
      text-align: left;

      span {
        padding-left: 4px;
        line-height: 1.2;
      }
    }

    .btn {
      @include formBtn;
    }

    .row {
      display: flex;
      margin-top: 24px;

      .verify {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .bottomText {
      @include font_color("font_color4");
      font-size: 14px;
      line-height: 18px;
      margin: 10px 0;
    }

    .rejectText {
      @include font_color("font_color2");
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      margin: 20px 0 10px;
    }

    .verifying {
      @include font_color("font_color11");
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      margin: 20px 0 10px;
    }

    .verified {
      @include font_color("font_color1");
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      margin: 20px 0 10px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      @include font_color("font_color4");
      margin-top: 2rem;

      a {
        @include font_color("font_color5");
      }
    }
  }
}
</style>
