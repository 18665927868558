export default {
  time: "时间",
  price: "价格",
  volume: "数量",
  tradeRecord: "市场交易纪录",
  pleaseSelect: "请选择",
  upsAndDowns: "涨跌",
  highPrice: "最高价",
  lowPrice: "最低价",
  volume2: "成交量",
  buyUp: "买涨",
  buyDown: "买跌",
  sendOrder: "下单",
  accountBalance: "帐户余额：",
  orderTime: "下单时间",
  openTime: "预测开奖时间",
  type: "仓位",
  price2: "金额",
  pleaseInputPrice: "请输入金额",
  insufficientBalance: "余额不足",
  notEnough: "卖出的数量过多",
  reset: "重置",
  confirmOrder: "确认下单",
  cancel: "取消",
  loginBeforeOrder: "下单前需先登入",
  orderPriceMustGreaterThan0: "下单金额必须大于0",
  temporaryCanNotOrder: "暂时无法下单 请稍后再试",
  tradingPair: "交易对",
  predictDirection: "预测方向",
  odds: "赔率",
  predictPrice: "预测金额",
  orderSuccess: "下单成功",
  confirm: "确认",
  orderID: "订单号",
  asks: "卖单",
  bids: "买单",
  asksAndBids: "买/卖单",
  total: "总价",
  kline: "图表",
  order: "盘口",
  openOrders: "委托中订单",
  orderHistory: "已完成订单",
  orderPrice: "金额：",
  orderTime2: "订单时间：",
  orderId: "订单ID：",
  orderVolume: "数量：",
  orderKind: "挂单类别：",
  orderKind2: "挂单类别",
  orderBid: "买入",
  orderAsk: "卖出",
  noDataText: "无资料",
  tradeTitle1: "现货交易",
  tradeTitle2: "指数预测",
  buy: "买入",
  sell: "卖出",
  limit: "限价",
  market: "市价",
  totalCount: "总计",
  priceWarningMsg: "下单金额必须大于0",
  volumeWarningMsg: "下单数量必须大于0",
  confirmCancelOrder: "确认取消此笔挂单？",
  confirmYes: "是",
  confirmNo: "否",
  cancelOrderSuccess: "取消挂单成功",
  cancelOrderError: "取消挂单失败",
  pleaseInputPrice2: "请输入价格",
  pleaseInputVolume: "请输入数量",
  marketName: "交易对：",
  fundsReceived: "总计：",
};
