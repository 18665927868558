export default {
  title: "使用邮箱注册",
  emailTitle: "邮箱",
  email: "邮箱地址",
  checkEmail: "请输入正确的邮箱地址",
  pass: "密码",
  password: "登入密码",
  warninPassword: "请输入密码",
  checkPassword: "确认密码",
  againCheckPassword: "请再次输入密码",
  checked: "我同意用户协议和隐私政策。我也不是美国公民或者美国纳税人。",
  register: "注册",
  login: "登入",
  success: "注册成功",
  warning: "请同意服务协议和隐私政策",
  verify: "验证码",
  invitationCode: "邀请码",
  invitation: "邀请码(可选)",
  warningInvitationCode: "请输入邀请码",
  warningEmail: "请输入邮箱",
  phone: "手机号码",
  warningPhone: "请输入手机号码",
  sendVerify: "发送验证码成功,请注意查收",
  warningPassword: "两次输入密码不一致!",
  warninEmailVerify: "请输入邮箱认证码",
  emailVerify: "邮箱验证码",
  sendEmailVerify: "发送邮箱验证码",
  phoneAreaCode: "手机区号",
  addPhoneAreaCode: "请输入手机区号",
  areaCode: "区号:",
  cancel: "取消",
};
