<template>
  <div class="LongAndShortComponent">
    <div class="mask" @click="switchFun()">
      <div class="popout" @click.stop v-loading="loading">
        <div class="title">{{ $t("kline.tradeTitle2") }}</div>
        <div class="label">
          <!-- <div class="left">{{ $t("kline.sendOrder") }}</div> -->
          <div class="left">{{ $t("kline.accountBalance") }}</div>
          <div class="right">
            <!-- {{ $t("kline.accountBalance") }} -->
            <span class="bold">{{ usdtBalance }}</span>
            USDT
          </div>
        </div>

        <div class="buy_type">
          <div
            class="btn left"
            :class="[{ green: betForm.position == 'long' }]"
            @click="betForm.position = 'long'"
          >
            {{ $t("kline.buyUp") }}
          </div>
          <div
            class="btn"
            :class="[{ red: betForm.position == 'short' }]"
            @click="betForm.position = 'short'"
          >
            {{ $t("kline.buyDown") }}
          </div>
        </div>

        <div class="label border_bottom">
          <div class="left">{{ $t("kline.orderTime") }}</div>
          <div class="right bold" v-if="time">{{ time.toLocaleString() }}</div>
        </div>
        <div class="msg"></div>

        <div class="label border_bottom">
          <div class="left">{{ $t("kline.type") }}</div>
          <div class="right bold">{{ coinData.ticker.last }}</div>
        </div>
        <div class="msg"></div>

        <div
          class="label border_bottom"
          :class="[{ red_border_bottom: amountTooBigMsg }]"
        >
          <div class="left">{{ $t("kline.price2") }}</div>
          <input
            class="amount"
            v-model="betForm.volume"
            :placeholder="$t('kline.pleaseInputPrice')"
            type="number"
          />
          <div class="right">
            <!-- <span class="bold">{{ amountList[formData.amount] || 0 }}</span> -->
            USDT
          </div>
        </div>
        <div class="msg">
          {{ amountTooBigMsg ? $t("kline.insufficientBalance") : "" }}
        </div>

        <div class="amount_box">
          <div
            class="amount_btn"
            v-for="(item, index) in amountList"
            :key="index"
            @click="addAmount(item)"
          >
            {{ item }}
          </div>
          <div class="amount_btn" @click="betForm.volume = null">
            <!-- {{ $t("kline.reset") }} -->
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </div>
        </div>

        <div class="button_box">
          <div class="blue button" @click="doubleCheckFun()">
            {{ $t("kline.sendOrder") }}
          </div>
        </div>
        <div class="button_box">
          <div class="pink button" @click="switchFun()">
            {{ $t("kline.cancel") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  postPredictions,
  getPredictions,
  getAccounts,
} from "@/services/Api.js";
// import { getPredictions } from "@/services/Api.js";
import Cookie from "js-cookie";
import moment from "moment";

export default {
  inject: ["message"],
  name: "LongAndShortComponent",
  props: {
    marketId: { String },
    coinData: { Object },
  },
  watch: {
    "betForm.volume"(value) {
      console.log(value);
      this.amountTooBigMsg = value > this.usdtBalance;
    },
  },
  components: {},
  data() {
    return {
      loading: true,
      amountList: [1, 10, 50, 100, 500, 1000],
      betForm: {
        market_id: 1,
        position: null, //预测方向涨(long)或跌(short)
        volume: null,
        lock_price: 0,
        locale: "en",
      },
      time: null,
      showDialog: null,
      usdtBalance: 0,
      amountTooBigMsg: false,
    };
  },
  mounted() {
    // this.getAccountsFun();
    this.clickBtnFun(0);
  },
  methods: {
    clickBtnFun(type) {
      if (Cookie.get("sn") ? true : false) {
        this.time = new Date();
        this.betForm = {
          market_id: this.marketId,
          position: type == 0 ? "long" : "short", //预测方向涨(long)或跌(short)
          volume: null,
          lock_price: this.coinData.ticker.last,
        };
        this.loading = true;
        this.getAccountsFun();
      } else {
        this.message("kline.loginBeforeOrder", "warning");
        this.switchFun();
      }
    },
    subnitFun() {
      // alert("模擬下單成功");

      if (this.betForm.volume <= 0) {
        this.message("kline.orderPriceMustGreaterThan0", "warning");
        return;
      }

      if (this.amountTooBigMsg) {
        this.message("kline.insufficientBalance", "warning");
        return;
      }

      this.loading = true;

      let locale = window.localStorage.getItem("locale");
      locale = locale == "zht" ? "zh-TW" : locale == "zhs" ? "zh-CN" : "en";

      this.betForm.locale = locale;

      postPredictions(this.betForm)
        .then((res) => {
          const { data } = res;
          console.log(res);
          if (data.success) {
            this.loading = true;
            setTimeout(() => {
              this.getPredictionsFun();
            }, 500);
          } else {
            this.message(data.errors[0].message, "error");
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.switchFun();

          //登入驗證未通過
          if (
            err.response.status == 401
            // &&
            // err.response.statusText == "Unauthorized"
          ) {
            this.message("common.unauthorizedMsg", "error");
            Cookie.remove("sn");
            Cookie.remove("_Toastio_session");
            this.$router.push("/").catch(() => {});
            this.reload();
          } else {
            this.message("kline.temporaryCanNotOrder", "error");
          }
        });
    },
    getPredictionsFun() {
      // console.log("getPredictionsFun");
      var senddata = { pages: 1, count: 1, order_by: "desc" };
      const queryString = Object.keys(senddata)
        .map((key) => key + "=" + senddata[key])
        .join("&");

      getPredictions(queryString).then((res) => {
        if (res.data.success) {
          const new_data = res.data.data.predictions[0];
          const html = `
                  <div class="flex"><div>${this.$t("kline.orderID")}</div><div>
                  ${new_data.sn != null ? new_data.sn : ""}
                  </div></div>
                  <div class="flex"><div>${this.$t(
                    "kline.tradingPair"
                  )}</div><div>
                  ${this.coinData.name}
                  </div></div>
                  <div class="flex"><div>
                  ${this.$t("kline.predictDirection")}
                  </div><div>
                  ${
                    new_data.position == "long"
                      ? this.$t("kline.buyUp")
                      : this.$t("kline.buyDown")
                  }
                  </div></div>
                  <div class="flex"><div>
                  ${this.$t("kline.odds")}
                  </div><div>
                  ${new_data.payout}
                  </div></div>
                  <div class="flex"><div>${this.$t(
                    "kline.predictPrice"
                  )}</div><div>
                  ${new_data.volume}
                  </div></div>
                  <div class="flex"><div>${this.$t("kline.type")}</div><div>
                  ${new_data.lock_price}
                  </div></div>
                  <div class="flex"><div>${this.$t(
                    "kline.orderTime"
                  )}</div><div>
                  ${moment(new_data.created_at).format("YYYY-MM-DD HH:mm:ss")}
                  </div></div>
                  <div class="flex"><div>${this.$t("kline.openTime")}</div><div>
                  ${
                    new_data.closed_at
                      ? moment(new_data.closed_at).format("YYYY-MM-DD HH:mm:ss")
                      : ""
                  }
                  </div></div>
                  `;

          this.$alert(html, this.$t("kline.orderSuccess"), {
            confirmButtonText: "OK",
            customClass: "alert",
            dangerouslyUseHTMLString: true,
          });

          this.switchFun();
        }
      });
    },
    getAccountsFun() {
      getAccounts()
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.loading = false;
            res.data.data.map((item) =>
              item.code === "USDT"
                ? (this.usdtBalance =
                    Math.floor(parseFloat(item.balance) * 10000) / 10000)
                : null
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;

          //登入驗證未通過
          if (
            err.response.status == 401
            // &&
            // err.response.statusText == "Unauthorized"
          ) {
            this.message("common.unauthorizedMsg", "error");
            Cookie.remove("sn");
            Cookie.remove("_Toastio_session");
            this.$router.push("/").catch(() => {});
            this.reload();
          }
        });
    },
    // checkBalanceFun() {
    //   this.amountTooBigMsg = this.betForm.volume > this.usdtBalance;
    // },
    addAmount(value) {
      if (!this.betForm.volume) {
        this.betForm.volume = 0;
      }
      this.betForm.volume = parseFloat(this.betForm.volume) + value;
    },
    doubleCheckFun() {
      if (this.betForm.volume <= 0) {
        this.message("kline.orderPriceMustGreaterThan0", "warning");
        return;
      }

      if (this.amountTooBigMsg) {
        this.message("kline.insufficientBalance", "warning");
        return;
      }

      this.$confirm("", this.$t("kline.confirmOrder"), {
        confirmButtonText: this.$t("kline.confirmYes"),
        cancelButtonText: this.$t("kline.confirmNo"),
        customClass: "alert",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.subnitFun();
        })
        .catch(() => {});
    },
    switchFun() {
      this.$emit("handleSwitch");
    },
  },
};
</script>

<style lang="scss" scoped>
.LongAndShortComponent {
  @include flex-set();
  width: 100vw;
  max-width: 660px;
  padding: 10px;
  position: fixed;
  bottom: 0;
  z-index: 2000;
  @include background_color("background_color3");

  .button_box {
    width: 50%;
    padding: 5px;
    margin-bottom: 10px;
  }
  .button {
    height: 38px;
    border-radius: 4px;
    line-height: 38px;
    text-align: center;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
    }
  }
  .green {
    @include background_color("background_color7");
  }
  .red {
    @include background_color("background_color8");
  }
  .yellow {
    @include background_color("background_color12");
  }
  .purple {
    @include background_color("background_color13");
  }
  .blue {
    @include background_color("background_color14");
  }
  .pink {
    @include background_color("background_color15");
  }

  .mask {
    width: 100vw;
    max-width: 660px;
    height: 100vh;
    position: fixed;
    top: 0;
    @include background_color("background_color9");
    .popout {
      @include flex-set();
      width: 100vw;
      max-width: 660px;
      @include background_color("background_color4");
      position: fixed;
      bottom: 0;
      z-index: 110;
      padding: 10px;
      border-top: 1px solid;
      @include border_color("border_color1");
      .title {
        font-size: 20px;
        font-weight: bold;
        @include font_color("font_color3");
        margin: 10px 0;
      }
      .label {
        @include flex-set();
        justify-content: space-between;
        width: 100%;
        margin: 5px 10px;
        padding: 5px;
        @include font_color("font_color10");

        .amount {
          width: calc(100% - 160px);
          border: none;
          background: none;
          @include font_color("font_color3");
          text-align: right;
          font-weight: bold;
          font-size: 16px;
        }
        input {
          outline: none;
        }
        /* Chrome, Safari, Edge, Opera */
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
        .bold {
          font-weight: bold;
          @include font_color("font_color3");
        }
        .left {
          width: 80px;
          text-align: left;
        }
      }
      .msg {
        font-size: 12px;
        @include font_color("font_color2");
        text-align: center;
        font-weight: bold;
        text-align: right;
        width: 100%;
        height: 6px;
        margin: 0 10px;
      }
      .buy_type {
        @include flex-set();
        border: 1px solid;
        border-radius: 8px;
        width: 100%;
        margin: 10px;
        overflow: hidden;
        @include border_color("border_color7");
        .btn {
          width: 50%;
          padding: 10px;
          cursor: pointer;
          @include box_shadow("shadow_color1");
          @include text_shadow("shadow_color1");
        }
        .left {
          border-right: 1px solid;
          @include border_color("border_color7");
        }
      }
      .amount_box {
        @include flex-set();
        justify-content: space-between;
        width: 100%;
        margin: 10px;
        // margin-bottom: 50px;
        .amount_btn {
          border: 1px solid;
          border-radius: 3px;
          width: 13%;
          // width: 23%;
          padding: 10px 0;
          cursor: pointer;
          @include border_color("border_color3");

          &:active {
            border: 1px solid;
            @include background_color("background_color5");
            @include border_color("border_color4");
          }
        }
      }
      .border_bottom {
        border-bottom: 1px solid;
        @include border_color("border_color4");
      }
      .red_border_bottom {
        border-bottom: 1px solid;
        @include border_color("border_color6");
      }

      .button_box {
        width: 50%;
        padding: 5px;
        margin-top: 20px;
        margin-bottom: 10px;
        .button {
          height: 38px;
          border-radius: 4px;
          line-height: 38px;
          text-align: center;
          @include box_shadow("shadow_color1");
          @include text_shadow("shadow_color1");

          &:hover {
            cursor: pointer;
            transform: scale(1.05);
            transition-timing-function: ease-in-out;
            transition-duration: 0.2s;
          }
        }
      }
    }
  }
  ::v-deep .el-loading-mask {
    @include background_color("background_color10");
  }
}
</style>
