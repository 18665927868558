export default {
  useEmailToReset: "メールを使用してパスワードをリセットする",
  emailAddress: "メールアドレス",
  enterCaptcha: "確認コードを入力してください",
  sendResetMail: "リセットメールを送る",
  captchaDoesNotMatch: "キャプチャが一致しません",
  mailHasBeenSent: "パスワード変更メールを送信しました",
  errorOccursWhenSendingMail:
    "エラーが発生しました。カスタマー サービスにお問い合わせください",
  title: "ログインパスワードのリセット",
  password: "パスワード",
  checkPassword: "パスワード確認",
  slbmit: "新しいパスワードを送信",
  message1: "リンクが間違っているか、有効期限が切れています。もう一度お試しください",
  message2: "パスワードを入力してパスワードを確認してください",
  message3: "2 回入力したパスワードが一致しません",
  success: "パスワードをリセットしました",
};
