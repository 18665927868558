export default {
  title: "Real Name Authentication",
  msg_1:
    "You have not performed real-name authentication, click this information to go to the real-name authentication page",
  countryOrArea: "Country or Area",
  addCountryOrAreaCode: "Select Your Country/Region",
  cancel: "cancel",
  realName: "real name",
  warningRealName: "Please enter your real name",
  documentType: "Document Type",
  warningDocumentType: "Please enter document type",
  IDcard: "ID card",
  passport: "passport",
  driversLicense: "Driver's License",
  certificateNumber: "Certificate number",
  warningCertificateNumber: "Please enter the ID number",
  front: "document front",
  warningFrontImage: "Please upload a picture of the front of the certificate",
  Back: "Back of ID",
  warningCoverImage: "Please upload a picture of the back of the certificate",
  handHeld: "handheld ID photo",
  warningCarryImage: "Please upload a picture of the passport",
  upload: "Upload",
  msg_2:
    "Tip: Please make sure all the text in your photo is clearly visible, otherwise it will not pass the real-name review",
  confirm: "Confirm",
  cancelVerification: "Cancel authentication",
  imageisLt5M: "File size must not exceed 1MB",
  errorOccursWhenUpload: "Error occurs when uploading data, please try again",
  uploadSuccess: "Uploaded successfully",
  rejectReason: "Reason for rejection",
  verifying: "Verifying",
  verified: "Verified",
  mustBeAnImage: "The image format must be: JPG, JPEG, PNG",
};
