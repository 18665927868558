<template>
  <div id="app">
    <NavbarComponent class="NavbarComponent" v-if="isRouterAlive" />
    <div class="bg" v-if="showSideImage">
      <div class="leftbox">
        <!-- <img src="@/assets/images/Img_Left.png" class="image" /> -->
        <div
          class="list"
          v-for="({ img, title }, index) in list"
          :key="`list${index}`"
        >
          <img :src="img" :alt="title" />
          <h1>{{ $t(title) }}</h1>
          <!-- <p>
            {{ $t(content) }}
          </p> -->
        </div>
      </div>
    </div>
    <router-view ref="router_view" class="router_view" v-if="isRouterAlive" />
    <div class="bg right" v-if="showSideImage">
      <div class="rightbox">
        <img
          v-if="domainType == 'mtf'"
          src="@/assets/images/mtf/small_logo.png"
          class="small_logo"
        />
        <img
          v-if="domainType == 'vcex'"
          src="@/assets/images/vcex/small_logo.png"
          class="small_logo"
        />
        <img
          v-if="domainType == 'wdc'"
          src="@/assets/images/wdc/small_logo.svg"
          class="small_logo"
        />
        <div class="info_box">
          <p
            class="paragraph"
            v-for="(item, index) in paragraph"
            :key="`paragraph${index}`"
          >
            {{ $t(item) }}
          </p>
        </div>
        <div
          class="button"
          @click="downloadWhitePaper"
          v-if="domainType == 'mtf'"
        >
          {{ $t("home.mtfTokenWhitePaper") }}
        </div>
      </div>
    </div>

    <div class="customer_service_mask" v-if="showCS" ref="customerServiceMask">
      <div class="message_box alert">
        <div class="title">
          {{ $t("navbar.customerService") }}
        </div>
        <div class="lable">
          <div class="span">
            {{ this.$t("navbar.customerServiceEmail") + ":" }}
          </div>
          <a
            class="link"
            href="mailto:mtftoken@gmail.com?subject=Feedback&body=Message"
          >
            @mtfcustomerservice
          </a>
        </div>

        <div class="lable">
          <div class="span">Telegram:</div>
          <a
            class="link"
            href="https://t.me/mtfcustomerservice"
            target="_blank"
          >
            mtftoken@gmail.com
          </a>
        </div>

        <!-- <div class="lable">
          <div class="span">
            {{ this.$t("navbar.OnlineCustomerService") + ":" }}
          </div>
          <span class="link" @click="OpenLiveChat()"> LiveChat </span>
        </div> -->

        <div class="btn" @click="closeCsMsg()">OK</div>
      </div>
    </div>

    <!-- <LiveChatWidget
      :license="licenseKey"
      :visibility="liveChatVisibility"
      v-on:new-event="handleNewEvent"
      v-on:visibility-changed="handleVisibilityChanged"
    /> -->
  </div>
</template>

<script>
import NavbarComponent from "@/components/Navbar/NavbarComponent.vue";
import "@/assets/style/reset.scss";
// import { LiveChatWidget } from "@livechat/widget-vue/v2";

export default {
  name: "App",
  components: {
    NavbarComponent,
    // LiveChatWidget,
  },
  provide() {
    return {
      reload: this.reload,
      scrollTop: this.scrollTop,
      fillZero: this.fillZero,
      downloadWhitePaper: this.downloadWhitePaper,
      alertCsMsg: this.alertCsMsg,
      message: this.message,
      returnApiSettings: this.returnApiSettings,
      apiSettingsData: this.apiSettingsData,
      OpenLiveChat: this.OpenLiveChat,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      showSideImage: true,
      eventListener: null,
      currentWidth: 0,
      apiSettingsData: null,
      // licenseKey: glob.liveChatLicense,
      liveChatVisibility: "hidden", //maximized
      showCS: 0,
      /* global glob */
      // domainType: "vcex", // mtf/vcex
      domainType: glob.domainType, // mtf/vcex
      paragraph: [
        `whitePaper.${glob.domainType}.p1`,
        `whitePaper.${glob.domainType}.p2`,
        `whitePaper.${glob.domainType}.p3`,
      ],
      list: [
        {
          img: require("@/components/Home/WhitePaper/1.svg"),
          title: `whitePaper.${glob.domainType}.create`,
          // content: "whitePaper.mtf.createStr",
        },
        {
          img: require("@/components/Home/WhitePaper/2.svg"),
          title: `whitePaper.${glob.domainType}.interact`,
          // content: "whitePaper.mtf.interactStr",
        },
        {
          img: require("@/components/Home/WhitePaper/3.svg"),
          title: `whitePaper.${glob.domainType}.share`,
          // content: "whitePaper.mtf.shareStr",
        },
        {
          img: require("@/components/Home/WhitePaper/4.svg"),
          title: `whitePaper.${glob.domainType}.trade`,
          // content: "whitePaper.mtf.tradeStr",
        },
      ],
    };
  },
  watch: {
    currentWidth(newValue) {
      if (newValue < 1200) {
        this.showSideImage = false;
      } else {
        this.showSideImage = true;
      }
    },
    $route() {
      window.scrollTo(0, 0);
    },
    apiSettingsData(newValue) {
      console.log(newValue);
    },
  },
  mounted() {
    this.mobileInit();
    this.getCurrentWidth();
    this.eventListener = window.addEventListener(
      "resize",
      this.getCurrentWidth
    );

    this.theme();

    this.activateFun();

    this.liveChatVisibility = "hidden";

    // this.CustomIdentityProvider();

    // /* global glob */
    // this.$store.dispatch("setGlob", glob);
  },

  methods: {
    /** 主題 */
    // theme() {
    //   let type = null;
    //   if (localStorage.getItem("darkMode") != null) {
    //     type = localStorage.getItem("darkMode") ? "dark" : "light";
    //   } else {
    //     localStorage.setItem("darkMode", 0);
    //     type = "light";
    //   }
    //   window.document.documentElement.setAttribute("data-theme", type);
    // },

    theme(type = "dark") {
      window.document.documentElement.setAttribute("data-theme", type);
    },

    reload() {
      console.log("reload");
      this.isRouterAlive = false;
      this.$nextTick(() => (this.isRouterAlive = true));
    },

    returnApiSettings(data) {
      this.apiSettingsData = data;
    },

    getCurrentWidth() {
      this.currentWidth = window.innerWidth;
    },

    downloadWhitePaper() {
      window.open("/MTFWhitePaper.pdf");
    },

    fillZero(num) {
      if (num == 0 || num == "0") {
        return num;
      }
      return Number(num).toFixed(8);
    },

    /** 提示客服資訊 */
    alertCsMsg() {
      this.showCS = 1;
      setTimeout(() => {
        this.$refs.customerServiceMask.style.opacity = "1";
        this.$refs.customerServiceMask.style.marginTop = "20px";
      }, 0);
      // const tg =
      //   '<a class="link" href="https://t.me/mtfcustomerservice" target="_blank">@mtfcustomerservice</a>';
      // // const LiveChat = `<span class="link" onclick="${this.OpenLiveChat}" target="_blank">@mtfcustomerservice</span>`;
      // const email = `<a class="link"
      //               href="mailto:mtftoken@gmail.com?subject=Feedback&body=Message">
      //               mtftoken@gmail.com
      //               </a>`;
      // this.$alert(
      //   `${this.$t("navbar.customerServiceEmail")}: ${email}
      //           </br>
      //           Telegram: ${tg}`,
      //   this.$t("footer.contact"),
      //   {
      //     confirmButtonText: "OK",
      //     customClass: "alert",
      //     dangerouslyUseHTMLString: true,
      //   }
      // );

      // this.$confirm(
      //   `${this.$t("navbar.customerServiceEmail")}: ${email}
      //     </br>
      //     Telegram: ${tg}
      //     </br>
      //     LiveChat:`,
      //   "提示",
      //   {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "LiveChat",
      //     customClass: "alert",
      //     dangerouslyUseHTMLString: true,
      //   }
      // )
      //   .then(() => {})
      //   .catch(() => {
      //     this.OpenLiveChat();
      //   });
    },

    closeCsMsg() {
      this.$refs.customerServiceMask.style.opacity = "0";
      this.$refs.customerServiceMask.style.marginTop = "0";
      setTimeout(() => {
        this.showCS = 0;
      }, 300);
    },

    /** 提示訊息 */
    message(msg, type) {
      this.$message({
        message: this.$t(msg),
        type: type,
        offset: 70,
      });
    },

    mobileInit() {
      window.lastTouchEnd = 0;
      document.addEventListener(
        "touchend",
        (event) => {
          const now = new Date().getTime();
          if (now - window.lastTouchEnd <= 300) {
            event.preventDefault();
            // console.log('禁止連續點擊')
          }
          window.lastTouchEnd = now;
          if (event.touches.length > 1) {
            event.preventDefault();
          }
        },
        false
      );
      document.documentElement.addEventListener(
        "gesturestart",
        function (event) {
          event.preventDefault();
        }
      );
    },

    activateFun() {
      self.addEventListener("activate", function (event) {
        event.waitUntil(
          caches.keys().then(function (cacheNames) {
            return Promise.all(
              cacheNames
                .filter(function (cacheName) {
                  console.log("cache", cacheName);
                  // return true;
                  // Return true if you want to remove this cache,
                  // but remember that caches are shared across
                  // the whole origin
                })
                .map(function (cacheName) {
                  return caches.delete(cacheName);
                })
            );
          })
        );
      });
    },

    handleNewEvent(event) {
      console.log("LiveChatWidget.onNewEvent", event);
    },

    handleVisibilityChanged(data) {
      switch (data.visibility) {
        case "maximized":
          // console.log("maximized");
          this.liveChatVisibility = "maximized";
          break;
        case "minimized":
          // console.log("minimized");
          this.liveChatVisibility = "minimized";
          break;
        case "hidden":
          // console.log("hidden");
          this.liveChatVisibility = "minimized";
          break;
      }
    },

    OpenLiveChat() {
      this.liveChatVisibility = "maximized";
    },

    CustomIdentityProvider() {
      // window.__lc.custom_identity_provider = () => {
      //   const fetchLiveChatToken = () => {
      //     // fetch a token from LiveChat Accounts API here
      //     return "000000000";
      //   };
      //   let tokenPromise = null;
      //   const fetchToken = () => {
      //     tokenPromise = fetchLiveChatToken().then((response) => {
      //       tokenPromise = null;
      //       return response;
      //     });
      //     return tokenPromise;
      //   };
      //   console.log(123, {
      //     getToken: () => tokenPromise || fetchToken(),
      //     getFreshToken: () => tokenPromise || fetchToken(),
      //     hasToken: () => Promise.resolve(false),
      //     invalidate: () => Promise.resolve(),
      //   });
      //   return {
      //     getToken: () => tokenPromise || fetchToken(),
      //     getFreshToken: () => tokenPromise || fetchToken(),
      //     hasToken: () => Promise.resolve(false),
      //     invalidate: () => Promise.resolve(),
      //   };
      // };
    },
  },

  beforeDestory() {
    window.removeEventListener(this.eventListener);
  },
};
</script>

<style lang="scss">
.link {
  @include font_color("font_color5");
  text-decoration: underline;
}

.el-message-box {
  @include background_color("background_color2");
  @include border_color("border_color3");

  .el-message-box__content,
  .el-message-box__title {
    @include font_color("font_color4");
  }
}

.el-loading-mask {
  @include background_color("background_color1");
}
</style>

<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  flex: 1;
  overflow: hidden;
  @include background_color("background_color1");
  @include font_color("font_color3");

  .bg {
    height: calc(100vh - 64px);
    margin-top: 64px;
    background-image: url("~@/assets/images/mtftokenbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex: 1;
    justify-content: end;
    .leftbox {
      width: 200px;
      height: calc(100% - 64px);
      margin-right: 20px;
      margin-top: 10px;
      .image {
        height: 100%;
        max-height: 900px;
      }
      .list {
        margin: 0 auto;
        height: 25%;
        padding-top: 10px;
        img {
          max-height: 70%;
          max-width: 100%;
        }
        h1 {
          font-size: 20px;
          margin-top: 20px;
        }
      }
    }
    .rightbox {
      width: 240px;
      height: calc(100% - 64px);
      margin-left: 20px;
      margin-top: 10px;
      .whitebookimg {
        max-height: 745px;
        height: 100%;
      }
      .small_logo {
        margin: 5% 0;
        height: 150px;
        max-height: 18%;
        max-width: 100%;
      }
      .button {
        @include whitePaperBtn;
      }
      .info_box {
        height: 70%;
        overflow-y: scroll;
        .paragraph {
          text-align: left;
          margin-bottom: 20px;
          line-height: 24px;
        }
      }
    }
  }
  .right {
    justify-content: start;
  }
}
.router_view {
  padding-top: 64px;
  @include background_color("background_color3");
  max-width: 660px;
  margin: 0 auto;
  min-height: 100vh;
  width: 100%;
  overflow-y: scroll;
  height: 100vh;
  position: sticky;
}

.customer_service_mask {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  @include flex-set();
  // display: none;
  @include background_color("shadow_color1");
  opacity: 0;
  margin-top: 0;
  transition: all 300ms ease;
  .message_box {
    // margin: 40vh 0;
    max-width: 90vw;
    border-radius: 8px;

    border: 1px solid;
    @include border_color("border_color3");
    @include background_color("background_color16");
    width: 420px;
    max-width: 90%;
    padding: 10px;
    font-size: 14px;

    .title {
      padding: 10px 15px 15px;
      font-size: 18px;
      // font-weight: bold;
    }
    .lable {
      width: 100%;
      padding: 5px;
      // width: 200px;
      @include flex-set();
      justify-content: left;
      .span {
        width: 40%;
        text-align: right;
        margin-right: 5px;
      }
      .link {
        // width: 60%;
        text-align: left;
        cursor: pointer;
      }
    }
    .btn {
      border-radius: 4px;
      margin: 10px auto;
      width: 80px;
      padding: 10px 0;
      @include background_color("background_color17");
      @include border_color("border_color4");
      cursor: pointer;
      &:hover {
        filter: brightness(120%);
      }
      &:active {
        filter: brightness(80%);
      }
    }
  }
}
</style>

<style lang="scss">
.alert {
  margin: 40vh 0;
  max-width: 90vw;
  border-radius: 8px !important;

  @include background_color("background_color16");
  @include border_color("border_color3");
  @include font_color("font_color3");
  * {
    @include font_color("font_color3");
  }
  .link {
    @include font_color("font_color5");
  }

  .el-message-box__title {
    text-align: center !important;
  }
  .el-message-box__message {
    text-align: center !important;
  }
  .el-message-box__btns {
    text-align: center !important;
    margin-bottom: 10px;
  }

  .el-button {
    @include background_color("background_color16");
    @include border_color("border_color7");
    min-width: 80px;
    &:hover {
      filter: brightness(120%);
    }
    &:active {
      filter: brightness(80%);
    }
  }
  .el-button--primary {
    @include background_color("background_color17");
    @include border_color("border_color4");
  }

  .el-message-box__close {
    display: none;
  }

  .status {
    font-weight: bold;
    font-size: 16px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid;
    @include border_color("border_color1");
  }
  img {
    border-radius: 20px;
  }
}
</style>
