export default {
  orderCenter: "Binary Options Order Center",
  orderno: "Order number",
  orderTime: "Order time",
  predictOpenLotteryTime: "Predict the lottery opening time",
  tradingPair: "Trading Pair",
  type: "Position",
  predictDirection: "Prediction Direction",
  predictPrice: "Predicted Amount",
  noDataMsg: "No data for now",
  odds: "Odds",
  settled: "whether Settled",
  closeprice: "Close position",
  closeTime: "Close Time",
  yes: "Settled",
  no: "Not yet Settled",
  result: "Lottery Result",
  amount: "Amount won or lost",
  win: "Win",
  lose: "Lose",
};
