export default {
  title: "提币",
  enterWalletAddressAndPrice: "请输入您的币包地址与提出的USDT颗数,",
  enterWalletAddressAndPrice1: "请输入您的币包地址与提出的",
  enterWalletAddressAndPrice2: "颗数,",
  contactForServiceAfterSend: "确认送出后请与客服联系",
  coinWalletAddress: "币包地址",
  enterWalletAddress: "请输入币包地址",
  withdrawPrice: "提出颗数",
  withdrawPrice1: "提出的",
  withdrawPrice2: "颗数",
  enterWithdrawPrice: "请输入提出的USDT颗数",
  enterWithdrawPrice1: "请输入提出的",
  enterWithdrawPrice2: "颗数",
  send: "送出",
  inputIncorrect: "有栏位尚未填写",
  contactForService: "已送出，请与客服联系",
  confirm: "确认",
  fee: "手续费",
  total: "需要总颗数",
  actualCredit: "实际到账",
};
