<template>
  <div class="ChargeAndWithdrawView" v-loading="loading">
    <div class="title_box" v-if="walletData">
      <div class="title">
        <h1 class="left">{{ $t("charge.title1") }}</h1>
        <div class="right">
          <!-- <h1 class="name">{{ walletName }}</h1> -->

          <select
            class="select"
            v-model="selectCode"
            @change="clickOptionFun(selectCode)"
          >
            <option
              v-for="(item, index) in allWalletData"
              :key="index"
              :label="item.code"
              :value="item.code"
            >
              {{ item.code }}
            </option>
          </select>
          <div
            class="icon"
            :style="{
              backgroundImage: `url(${walletData.icon_url || coinIcon})`,
            }"
          ></div>
        </div>
      </div>
      <div class="title">
        <h1 class="left">{{ $t("charge.title2") }}</h1>
        <div class="right">
          <div class="value">{{ walletData.balance }}</div>
          <div class="text">{{ walletData.code }}</div>
        </div>
      </div>
      <div class="title">
        <h1 class="left">{{ $t("charge.title3") }}</h1>
        <div class="right" :key="tousdt_key">
          <div class="value">{{ walletData.tousdt }}</div>
          <div class="text">USDT</div>
        </div>
      </div>
      <el-tabs v-model="activeName" stretch class="tabs">
        <el-tab-pane :label="$t('charge.charge')" name="charge"></el-tab-pane>
        <el-tab-pane
          :label="$t('charge.withdraw')"
          name="withdraw"
        ></el-tab-pane>
      </el-tabs>
    </div>

    <div class="info_box" v-loading="loading" v-if="walletData">
      <!-- 充值 -->
      <div v-if="activeName == 'charge'">
        <!-- <div
          class="msg"
          v-if="userData && userData.id_document_state != 'verified'"
        >
          {{ $t("charge.msg2") }}
        </div> -->
        <ChargeComponent v-if="walletData.depositable" />
        <div class="msg" v-else>{{ $t("charge.msg1") }}</div>
      </div>
      <!-- 提現 -->
      <div v-if="activeName == 'withdraw'">
        <!-- <div
          class="msg"
          v-if="userData && userData.id_document_state != 'verified'"
        >
          {{ $t("charge.msg2") }}
        </div> -->
        <WithdrawComponent
          v-if="walletData.withdrawable"
          :balance="walletData.balance"
        />
        <div class="msg" v-else>{{ $t("charge.msg1") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import QRCode from "qrcode";
import { getAccounts, getMarkets } from "@/services/Api.js";
import Cookie from "js-cookie";
// import moment from "moment";

import ChargeComponent from "@/components/ChargeAndWithdraw/ChargeComponent.vue";
import WithdrawComponent from "@/components/ChargeAndWithdraw/WithdrawComponent.vue";

export default {
  inject: ["reload", "message"],
  name: "ChargeAndWithdrawView",
  components: {
    ChargeComponent,
    WithdrawComponent,
  },
  watch: {
    activeName(value) {
      console.log(value);
    },
    "$store.getters.getApiAccountsData"(value) {
      this.loading = false;
      this.allWalletData = value;

      for (let i = 0; i < value.length; i++) {
        if (value[i].code === this.walletName) {
          this.walletData = value[i];
          this.getMarketsData();
          break;
        }
      }
    },
    "$store.getters.getApiSettingsData"(value) {
      this.userData = value;
    },
  },

  data() {
    return {
      loading: false,
      activeName: "charge",
      walletName: this.$route.params.walletName,
      walletData: null,
      allWalletData: [],
      coinIcon: require("@/assets/images/coin.png"),
      selectCode: this.$route.params.walletName,
      userData: null,
      tousdt_key: Date.now(),
    };
  },

  mounted() {
    this.loading = true;
    if (this.$store.getters.getApiAccountsData != null) {
      this.loading = false;
      this.allWalletData = this.$store.getters.getApiAccountsData;

      for (let i = 0; i < this.$store.getters.getApiAccountsData.length; i++) {
        if (
          this.$store.getters.getApiAccountsData[i].code === this.walletName
        ) {
          this.walletData = this.$store.getters.getApiAccountsData[i];
          // this.getMarketsData();
          break;
        }
      }
    }
    if (this.$store.getters.getApiSettingsData != null) {
      this.userData = this.$store.getters.getApiSettingsData;
    }
    // this.getAccountsFun();
  },

  methods: {
    getAccountsFun() {
      getAccounts()
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.loading = false;
            this.allWalletData = res.data.data;

            for (let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].code === this.walletName) {
                this.walletData = res.data.data[i];
                break;
              }
            }
            console.log("walletData", this.walletData);
            if (this.walletData == null) {
              this.$router.push("/").catch(() => {});
              this.reload();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;

          //登入驗證未通過
          if (
            err.response.status == 401
            // &&
            // err.response.statusText == "Unauthorized"
          ) {
            this.message("common.unauthorizedMsg", "error");
            Cookie.remove("sn");
            Cookie.remove("_Toastio_session");
            this.$router.push("/").catch(() => {});
            this.reload();
          }
        });
    },
    clickOptionFun(code) {
      console.log(code);
      this.$router.push(`/wallet/${code}`);
      this.reload();
    },
    getMarketsData() {
      getMarkets()
        .then((res) => {
          // console.log(res);
          const coinList = res.data;

          for (let j = 0; j < coinList.length; j++) {
            // console.log(this.coinList[j]["name"].split("/")[0]);
            if (this.walletData["code"] == coinList[j]["name"].split("/")[0]) {
              this.walletData["tousdt"] = parseFloat(
                coinList[j]["ticker"]["last"] * this.walletData["balance"]
              ).toFixed(2);
            } else if (this.walletData["code"] == "USDT") {
              this.walletData["tousdt"] = parseFloat(
                this.walletData["balance"]
              ).toFixed(2);
            }
          }
          // console.log(this.walletData);
          this.tousdt_key = Date.now();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ChargeAndWithdrawView {
  height: 100%;
  position: relative;

  .title_box {
    @include titleBox;
    padding-top: 1rem;

    .title {
      display: flex;
      justify-content: space-between;
      @include titleBox_title;
      padding: 0 1rem;
      line-height: 25px;
      font-size: 18px;
      margin: 5px !important;
      .left {
        @include flex-set();
        justify-content: left;
        // width: 80px;
        font-size: 14px;
        @include font_color("font_color10");
      }
      .right {
        font-weight: normal;
        font-size: 18px;
        @include flex-set();
        justify-content: left;
        max-width: calc(100% - 80px);
        .text {
          text-align: left;
          width: 50px;
          margin-left: 10px;
          @include font_color("font_color10");
        }
        .value {
          max-width: calc(100% - 60px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .icon {
        background-position: center;
        background-size: contain;
        width: 25px;
        height: 25px;
        margin-left: 10px;
      }
      .select {
        @include font_color("font_color3");
        font-weight: 400;
        @include background_color("background_color3");
        border: unset;
        font-size: 18px;
      }
      select {
        outline: none;
      }
    }
    .tabs {
      margin-top: 20px;
    }
  }
  .info_box {
    margin-top: 170px;
    max-height: calc(100vh - 234px);
    .msg {
      padding: 40px;
      @include font_color("font_color8");
    }
  }
  ::v-deep .el-loading-mask {
    @include background_color("background_color10");
  }
}
</style>
