<template>
  <div class="CoinListComponent" v-if="coinList">
    <router-link
      class="coin_box"
      :to="{
        name: 'marketkline',
        params: {
          marketName: coin.name,
        },
      }"
      v-for="(coin, index) in coinList"
      :key="`coin${index}`"
    >
      <h1>{{ coin.name }}</h1>

      <div class="row">
        <span class="span">{{ $t("home.latestPrice") }}</span>
        <span class="span">{{ roundDown(coin.ticker.last) }}</span>
      </div>
      <div class="row">
        <span class="span">{{ `24h ${$t("home.upsAndDowns")}` }}</span>
        <span
          class="span"
          :class="[{ red: gainFun(coin) < 0, green: gainFun(coin) > 0 }]"
        >
          <font-awesome-icon
            icon="fa-solid fa-arrow-down"
            v-if="gainFun(coin) < 0"
          />
          <font-awesome-icon
            icon="fa-solid fa-arrow-up"
            v-if="gainFun(coin) > 0"
          />
          {{ roundDown(gainFun(coin)) }}
        </span>
      </div>
      <div class="row">
        <span class="span">{{ `24h ${$t("home.lowPrice")}` }}</span>
        <span class="span">{{ roundDown(coin.ticker.low) }}</span>
      </div>
      <div class="row">
        <span class="span">{{ `24h ${$t("home.volume2")}` }}</span>
        <span class="span">{{ roundDown(coin.ticker.volume) }}</span>
      </div>
      <div class="row">
        <span class="span">{{ `24h ${$t("home.orderPrice")}` }}</span>
        <span class="span">{{ roundDown(coin.ticker.turnover) }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CoinListComponent",
  inject: ["fillZero"],
  props: { coinList: { Object } },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    gainFun(item) {
      const {
        ticker: { last, open },
      } = item;

      const val = ((last - open) / open).toFixed(4);

      return open == 0 ? 0 : parseFloat(val);
    },
    roundDown(num, decimal = 4) {
      num = parseFloat(num);
      return (
        Math.floor((num + Number.EPSILON) * Math.pow(10, decimal)) /
        Math.pow(10, decimal)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.CoinListComponent {
  @include record_wrap;

  .coin_box {
    @include record_box;

    h1 {
      font-weight: 700;
      margin-bottom: 1rem;
    }
    .row {
      @include record;
    }

    .green {
      @include font_color("font_color1");
    }
    .red {
      @include font_color("font_color2");
    }
  }
}
</style>
