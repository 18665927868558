export default {
  title: "登入",
  email: "郵箱地址或手機號碼(含區號)",
  password: "登入密码",
  login: "登录",
  register: "注册",
  forgotPassword: "忘记密码",
  warning: "请输入账号与密码",
  success: "登入成功",
};
