import common from "./ja/common";
import navbar from "./ja/navbar";
import home from "./ja/home";
import footer from "./ja/footer";
import kline from "./ja/kline";
import charge from "./ja/charge";
import withdraw from "./ja/withdraw";
import order from "./ja/order";
import forgotpassword from "./ja/forgotpassword";
import login from "./ja/login";
import register from "./ja/register";
import usercheck from "./ja/usercheck";
import whitePaper from "./ja/whitePaper";

export default {
  common: {
    ...common,
  },
  navbar: {
    ...navbar,
  },
  home: {
    ...home,
  },
  footer: {
    ...footer,
  },
  kline: {
    ...kline,
  },
  charge: {
    ...charge,
  },
  withdraw: {
    ...withdraw,
  },
  order: {
    ...order,
  },
  forgotpassword: {
    ...forgotpassword,
  },
  login: {
    ...login,
  },
  register: {
    ...register,
  },
  usercheck: {
    ...usercheck,
  },
  whitePaper: {
    ...whitePaper,
  },
};
