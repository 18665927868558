export default {
  homeInfoTitle1: "優質項目資產",
  homeInfoText1:
    "詳細而嚴謹的項目在線審核流程，每個項目背後都有實體行業，確保推薦給用戶的項目具有可行性和價值增長潛力",
  homeInfoTitle2: "規範化運營管理",
  homeInfoText2:
    "具有 AUSTRAC 許可證的受監管數字股票交易平台，與第三方機構提供全程輔導和督導",
  homeInfoTitle3: "雄厚的資本實力",
  homeInfoText3:
    "詳細而嚴謹的項目在線審核流程，每個項目背後都有實體行業，確保推薦給用戶的項目具有可行性和價值增長潛力",
  mtfTokenWhitePaper: "MTF Token 白皮書",
  latestPrice: "最新價",
  upsAndDowns: "漲跌",
  lowPrice: "最低價",
  volume2: "成交量",
  orderPrice: "成交額",
  charge: "充值",
  withdraw: "提幣",
  orderlist: "訂單",
  contactService: "客服",
  wallet: "錢包",
  loginFirst: "請先登入",
  marqueeText1: "邀請活動火爆上線，驚喜福利享不停",
};
