import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiSettingsData: null,
    apiAccountsData: null,
    currentSelectOrderData: {
      price: 0,
      volume: 0,
      type: "",
    },
    // glob: null,
  },
  getters: {
    getApiSettingsData(state) {
      return state.apiSettingsData;
    },
    getApiAccountsData(state) {
      return state.apiAccountsData;
    },
    getCurrentSelectOrderData(state) {
      return state.currentSelectOrderData;
    },
    // getGlob(state) {
    //   return state.glob;
    // },
  },
  mutations: {
    SET_API_SETTINGS_DATA(state, payload) {
      state.apiSettingsData = payload;
    },
    SET_API_ACCOUNTS_DATA(state, payload) {
      state.apiAccountsData = payload;
    },
    SET_CURRENT_SELECT_ORDER_DATA(state, payload) {
      state.currentSelectOrderData = payload;
    },
    // SET_GLOD(state, payload) {
    //   state.glob = payload;
    // },
  },
  actions: {
    setApiSettingsData(context, data) {
      context.commit("SET_API_SETTINGS_DATA", data);
    },
    setApiAccountsData(context, data) {
      context.commit("SET_API_ACCOUNTS_DATA", data);
    },
    setCurrentSelectOrderData(context, data) {
      context.commit("SET_CURRENT_SELECT_ORDER_DATA", data);
    },
    // setGlob(context, data) {
    //   context.commit("SET_GLOD", data);
    // },
  },
  modules: {},
});
