import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import MarketKlineView from "@/views/MarketKlineView.vue";
import Chargeview from "@/views/Chargeview.vue";
import WithdrawView from "@/views/WithdrawView.vue";
import OrderView from "@/views/OrderView.vue";
import UserCheckView from "@/views/UserCheckView.vue";
import WalletView from "@/views/WalletView.vue";
import ChargeAndWithdrawView from "@/views/ChargeAndWithdrawView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import TwoFactorView from "@/views/TwoFactorView.vue";

import Cookie from "js-cookie";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      loginStatus: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      loginStatus: false,
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      loginStatus: false,
    },
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword,
    meta: {
      loginStatus: false,
    },
  },
  {
    path: "/charge",
    name: "charge",
    component: Chargeview,
    meta: {
      loginStatus: true,
    },
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: WithdrawView,
    meta: {
      loginStatus: true,
    },
  },
  {
    path: "/order",
    name: "order",
    component: OrderView,
    meta: {
      loginStatus: true,
    },
  },
  {
    path: "/marketkline/:marketName",
    name: "marketkline",
    component: MarketKlineView,
    meta: {
      loginStatus: false,
    },
  },
  {
    path: "/usercheck",
    name: "usercheck",
    component: UserCheckView,
    meta: {
      loginStatus: true,
    },
  },
  {
    path: "/wallet",
    name: "wallet",
    component: WalletView,
    meta: {
      loginStatus: true,
    },
  },
  {
    path: "/wallet/:walletName",
    name: "chargeandwithdraw",
    component: ChargeAndWithdrawView,
    meta: {
      loginStatus: true,
    },
  },
  {
    path: "/reset_pass/:lang",
    name: "resetpassword",
    component: ResetPasswordView,
    meta: {
      loginStatus: false,
    },
  },
  {
    path: "/twofactor",
    name: "twofactor",
    component: TwoFactorView,
    meta: {
      loginStatus: true,
    },
  },
  {
    path: "*",
    redirect: {
      name: "home",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  // console.log(to.meta.loginStatus, next);

  if (window.location.host.indexOf(".com.") != -1) {
    /* global glob */
    // window.location.href = glob.domainName;
    window.location.replace(glob.domainName);
  } else {
    const login = Cookie.get("sn") != null;
    // console.log(login);
    if (to.meta.loginStatus && !login) {
      next({ name: "home" });
    } else {
      next();
    }
  }
});

export default router;
